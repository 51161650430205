import PropTypes from "prop-types";
import useOutsideClick from "../../../lib/hooks/outsideClick";
import { useRef } from "react";

export const Modal = ({ isOpen, content, setIsOpen }) => {
  const modalRef = useRef(null);
  useOutsideClick(
    modalRef,
    ["pac-item", "pac-container", "pac-logo", "pac-icon", "pac-item-query"], // these are the class of the google places provider
    () => setIsOpen(false)
  );
  return (
    <div
      className={`absolute ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      } flex justify-center duration-150 px-5 items-center bg-black/30 top-0 left-0 w-[100vw] h-[100vh] overflow-auto z-50 p-2`}
    >
      <div
        ref={modalRef}
        className={`bg-white w-full lg:w-3/5 md:2/3 duration-150 p-3 sm:p-5 md:p-10 z-[2000] md:max-h-[90vh] max-h-[95vh] overflow-auto`}
      >
        {content}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  content: PropTypes.element,
};
