import { useState } from "react";
import { ensureNonNegative } from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faPlus,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa6";
import ImagePreview from "../../products/ProductForm/comp/imagePreview";
import { toast } from "react-toastify";
import ProductPreview from "../../products/ProductForm/comp/ProductPreview";
import { useNavigate, useOutletContext } from "react-router-dom";
import NextBar from "./NextBar";
import { useAddProductMutation } from "../../../lib/api/productApi";
import {
  accesstokenSelector,
  currentSlug,
  setOverviewPhase,
} from "../../../lib/user/userSlice";
import { useAppSelector } from "../../../lib/hooks";
import OverLoader from "../../../components/OverLoader";
import { useDispatch } from "react-redux";
import ProgressBar from "../../../components/progressBar";

const AddProductsOverview = () => {
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [price, setPrice] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [imgErrMessage, setImgErrMessage] = useState("");
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [addProducts, { isLoading: isCreating }] = useAddProductMutation();

  const [phase] = useOutletContext();

  const [imageSizeProgress, setImageSizeProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);

  const handleImageDelete = (index, image) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    const newTotalSize = updatedImages.reduce((acc, img) => acc + img.size, 0);
    setSelectedImages(updatedImages);
    setTotalSize(newTotalSize);
    setImageSizeProgress((newTotalSize / (8 * 1024 * 1024)) * 100);
    if (paramsId !== "new" && image.id) {
      setImgListToDel((prev) => [...prev, image.id]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length > 0) {
      const selectedFiles = Array.from(files).slice(0, 5);

      if (selectedImages.length + selectedFiles.length > 5) {
        const remainingSpace = 5 - selectedImages.length;
        selectedFiles.splice(remainingSpace);
        toast.error("You can only select up to 5 images.");
        return;
      }

      const newImagePreviews = selectedFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve({
              src: reader.result,
              alt: file.name,
            });
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(newImagePreviews).then((images) => {
        setSelectedImages([...selectedImages, ...images]);
        setImgErrMessage("");
      });
    }

    setIsDraggingOver(false);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const selectedFiles = Array.from(files).slice(0, 5); // Limit to 5 files

      // Check if adding these files exceeds the 5-image limit
      if (selectedImages.length + selectedFiles.length > 5) {
        const remainingSpace = 5 - selectedImages.length;
        selectedFiles.splice(remainingSpace); // Keep only the allowed number of files
        toast.error("You can only select up to 5 images.");
        return;
      }

      // Calculate the total size of existing and new images
      const totalSize =
        selectedImages.reduce((acc, img) => acc + img.size, 0) +
        selectedFiles.reduce((acc, file) => acc + file.size, 0);

      setTotalSize(totalSize);
      setImageSizeProgress((totalSize / (8 * 1024 * 1024)) * 100);
      if (totalSize > 8 * 1024 * 1024) {
        // 8MB in bytes
        toast.error("The total size of the images exceeds 8MB.");
        return;
      }

      // Create image previews and update state
      const newImagePreviews = selectedFiles.map((file) => ({
        src: URL.createObjectURL(file), // Temporary preview URL
        alt: file.name, // File name
        file, // File object
        size: file.size, // File size in bytes
      }));

      setSelectedImages([...selectedImages, ...newImagePreviews]); // Update state
      setImgErrMessage("");
    }
  };

  const handleAddProduct = async () => {
    try {
      const formdata = new FormData();

      formdata.append("name", productName);
      formdata.append("description", productDesc);
      formdata.append("price", price);

      for (let i = 0; i < selectedImages.length; i++) {
        formdata.append("images_files", selectedImages[i].file);
      }
      const response = await addProducts({
        access,
        body: formdata,
        slug,
      });

      if (response.data) {
        toast.success("Product Added Successfully");
        navigate("/overview/bank-details");
        dispatch(setOverviewPhase(2));
        return;
      }
      toast.error("Error adding products");
      console.log(response.error);
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <>
      <OverLoader isLoading={isCreating} />
      <div className="flex ms:px-8 w-full gap-5">
        <form className="add-product-form flex rounded gap-5 w-full md:w-[550px] border p-8 pb-5 font-normal bg-white flex-col">
          <div>
            <h2 className="text-xl font-semibold">Add your first Product</h2>
            <p className="text-xs">
              Select from various picture options for your e-commerce website
            </p>
          </div>
          <div className="flex w-full gap-3 items-center justify-center">
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onClick={() => document.getElementById("imageInput").click()}
              className={`flex items-center justify-center flex-col gap-2 p-3 border border-dashed min-w-[100px] max-w-[140px] h-[150px] cursor-pointer ${
                isDraggingOver ? "border-[#151319]" : "border-[#D0D5DD]"
              }`}
            >
              <input
                type="file"
                id="imageInput"
                className="hidden"
                name="productImages"
                multiple
                onChange={handleImageChange}
                accept=".jpg, .jpeg, .png, .gif, .webp"
              />
              <FontAwesomeIcon icon={faCloudUpload} size="xs" />
              <span className="text-[#A1A1AA] text-[10px] text-center">
                Browse and choose the image you want to upload from your
                computer
              </span>
              <div>
                <FontAwesomeIcon icon={faPlus} color="#7E57C2" />
              </div>
            </div>
            <div className="flex items-center justify-start gap-3 p-2 w-[400px] overflow-x-auto">
              {selectedImages.map((image, index) => (
                <div
                  className="flex min-w-[150px] h-[140px] flex-col items-start overflow-hidden rounded-lg relative border-[#7e57e2] border"
                  key={index}
                >
                  <ImagePreview {...image} className="w-full" />
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    className="absolute top-0 right-0 bg-transparent rounded-full m-2 h-5 w-5"
                    color="red"
                    cursor="pointer"
                    onClick={() => handleImageDelete(index, image)}
                  />
                  <div
                    className="absolute bottom-0 right-0 bg-purple-400 text-white rounded-md w-fit p-1 text-sm m-1"
                    cursor="pointer"
                  >
                    {image.size && image.size > 1024 * 1024
                      ? `${(image.size / (1024 * 1024)).toFixed(2)} MB`
                      : `${(image.size / 1024).toFixed(2)} KB`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex w-full items-center justify-center gap-3">
            <p className="w-[150px]">
              {totalSize && totalSize > 1024 * 1024
                ? `${(totalSize / (1024 * 1024)).toFixed(2)}MB`
                : `${(totalSize / 1024).toFixed(2)}KB`}
              /8MB
            </p>
            <ProgressBar progress={imageSizeProgress} />
          </div>
          <span>
            <label htmlFor="productName" className=" ">
              Product Name
            </label>
            <input
              value={productName}
              onChange={(e) => setProductName(e.target.value)}
              type="text"
              name="productName"
              placeholder="Enter Product name"
            />
          </span>
          <span>
            <label htmlFor="productDesc" className=" ">
              Product Description
            </label>
            <textarea
              value={productDesc}
              className="p-4"
              onChange={(e) => setProductDesc(e.target.value)}
              name="productDesc"
              placeholder="Enter Product description"
            />
          </span>
          <span className="flex w-full">
            <label htmlFor="price" className=" ">
              Price
            </label>

            <input
              value={price}
              className="pl-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              onChange={(e) => setPrice(ensureNonNegative(e.target.value))}
              type="number"
              placeholder="Enter price e.g 5,000"
              name="price"
            />
          </span>
        </form>
        <div className="rounded-lg w-full hidden md:block bg-white p-8 border border-[#E7D9FF]">
          <ProductPreview
            productName={productName}
            productDesc={productDesc}
            price={price}
            images={selectedImages}
          />
        </div>
      </div>
      <NextBar handleSave={handleAddProduct} phase={phase} />
    </>
  );
};

export default AddProductsOverview;
