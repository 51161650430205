import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { AiFillHome } from "react-icons/ai";
import { IoCartSharp } from "react-icons/io5";
import { BsFillSendFill } from "react-icons/bs";
import { HiMiniUsers } from "react-icons/hi2";
import { HiTemplate } from "react-icons/hi";
import { TbWorldCheck } from "react-icons/tb";
import SettingSideNav from "@/app/settings/component/SettingSideNav";
import { FaX } from "react-icons/fa6";
import { FaBars } from "react-icons/fa";
import { useLogoutMutation } from "../lib/api/generalapi";
import { useAppSelector } from "../lib/hooks";
import { accesstokenSelector, isOnFreeTrial } from "../lib/user/userSlice";
import Cookies from "js-cookie";
import { SUBSCRIPTION_PRICE } from "@/utils/constants";

const navLinks = [
  {
    path: "/overview",
    icon: <TbWorldCheck size={20} />,
    text: "Overview",
  },
  {
    path: "/",
    icon: <AiFillHome size={20} />,
    text: "Home",
  },
  {
    path: "/orders",
    icon: <BsFillSendFill size={18} />,
    text: "Orders",
  },
  {
    path: "/products",
    icon: <IoCartSharp size={22} />,
    text: "Products",
  },
  {
    path: "/customers",
    icon: <HiMiniUsers size={22} />,
    text: "Customers",
  },
  {
    path: "/templates",
    icon: <HiTemplate size={22} />,
    text: "Templates",
  },
];

const GenSideNav = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const pathname = useLocation().pathname;
  const isFreeTrial = useAppSelector(isOnFreeTrial);
  const endDate = new Date(useAppSelector((state) => state.user.subEndAt));

  // const navigate = useNavigate();
  // const dispatch = useAppDispatch();
  // const sites = useAppSelector(sitesSelector);
  // const currentSlugValue = useAppSelector(currentSlug);

  const access = useAppSelector(accesstokenSelector);
  const [logout] = useLogoutMutation();

  const handleLogout = async () => {
    await logout({ access });
    Cookies.remove("jellosite-authToken", {
      path: "",
      domain: ".jellosite.com",
    });
    Cookies.remove("jellosite-user", { path: "", domain: ".jellosite.com" });
    window.location.href = import.meta.env.VITE_APP_LOGIN_URL;
  };

  const settingsCheck = pathname.includes("settings");

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <>
      <div className="md:hidden fixed top-22 right-0 z-40 rounded-l-lg bg-[#cbb1f5f7] px-2 pt-1">
        <button onClick={toggleSidebar}>
          {isSidebarOpen ? <FaX size={16} /> : <FaBars size={16} />}
        </button>
      </div>
      <aside
        className={`fixed left-0 top-[10vh] h-[85vh] md:h-[90vh] overflow-hidden border-r transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen
            ? "translate-x-0 bg-white w-[60%] flex flex-row z-10"
            : "-translate-x-full bg-white w-[80%] flex flex-row z-10"
        } md:translate-x-0 md:flex md:w-[250px]`}
      >
        <nav
          className={`flex pb-3 flex-col border-r justify-between max-h-full gap-8 md:gap-0 duration-300 ease-in-out ${
            settingsCheck
              ? "md:w-[60px] items-center"
              : "md:w-[250px] w-full p-3"
          }`}
        >
          <div className="flex py-2 font-medium flex-col gap-1">
            {navLinks.map(({ path, icon, text }) => (
              <Link
                key={path}
                to={path}
                className={`${
                  pathname === path && "bg-[#ECE1FF] text-[#600AF6]"
                } hover:bg-[#ECE1FF] hover:text-[#600AF6] group relative flex items-center gap-3 p-2 rounded-md font-normal`}
                onClick={closeSidebar}
              >
                {icon}
                <span
                  className={`${
                    settingsCheck &&
                    "absolute opacity-0 pointer-events-none rounded-sm select-none transition duration-200 -bottom-6 left-[100%] z-[9999] text-sm group-hover:opacity-100 group-hover:block group-hover:bg-[#cbb1f5f7] border p-1"
                  }`}
                >
                  {text}
                </span>
              </Link>
            ))}
          </div>

          <div className="flex flex-col gap-4 text-sm leading-relaxed items-start">
            {isFreeTrial && (
              <div
                className={`flex flex-col gap-3  w-[85%] sm:w-[75%] md:w-full ${
                  settingsCheck && "hidden"
                } text-xs  bg-[#26233A] p-2 rounded-lg`}
              >
                <h4 className="font-bold text-[#F1CBFF] mt-2">Free trial</h4>
                <span className="text-[#F1CBFF] text-xs">
                  Your free trial ends{" "}
                  {endDate.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                  })}
                </span>
                <span className="text-xs text-[#D2D2D2]">
                  Select a plan to extend your Jellosite trial for just ₦
                  {SUBSCRIPTION_PRICE}/month.
                </span>
                <Link
                  className="text-white bg-[#7E57C2] py-2 font-medium justify-center p-1 w-full rounded-[5px] flex text-[16px] shadow-md mt-1"
                  to={"/settings/billings"}
                >
                  Subscribe
                </Link>
              </div>
            )}
            <div className="flex w-full mb-10 md:mb-0 flex-col gap-3">
              <Link
                to={"/settings"}
                className={`${
                  pathname === "/settings" && "bg-[#ECE1FF] text-[#600AF6]"
                } hover:bg-[#ECE1FF] group: relative hover:text-[#600AF6] flex items-center gap-3 p-2 rounded-md`}
              >
                <FontAwesomeIcon icon={faGear} />
                <span
                  className={`${
                    settingsCheck &&
                    "absolute hidden -bottom-6 left-[120%] text-sm z-[4000] group-hover:block group-hover:bg-[#cbb1f5f7] border p-1"
                  }`}
                >
                  Settings
                </span>
              </Link>
              <button
                className={`hover:bg-[#ECE1FF] group hover:text-[#600AF6] flex items-center gap-3 p-2 rounded-md`}
                onClick={handleLogout}
              >
                <FontAwesomeIcon icon={faArrowRightFromBracket} />
                <span
                  className={`${
                    settingsCheck &&
                    "absolute hidden -bottom-6 left-[120%] text-sm z-[40] group-hover:block group-hover:bg-[#cbb1f5f7] border p-1"
                  }`}
                >
                  Logout
                </span>
              </button>
            </div>
          </div>
        </nav>
        {settingsCheck && <SettingSideNav close={closeSidebar} />}
      </aside>
    </>
  );
};

export default GenSideNav;
