import { useState, useEffect } from "react";
import {
  useGetGenSettingsQuery,
  usePatchGenSettingsMutation,
} from "@/lib/api/settingsApi";
import { useAppSelector } from "@/lib/hooks";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";

import { BiCloudUpload } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import OverLoader from "@/components/OverLoader";
import HeadText from "../../components/HeadText";

export default function Page() {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);
  const {
    data,
    // isFetching: isLoading,
  } = useGetGenSettingsQuery({
    access,
    slug,
  });
  const [patchGenSettings, { isLoading: saving }] =
    usePatchGenSettingsMutation();
  const [dragOver, setDragOver] = useState(false);
  const [businessName, setBusinessName] = useState(null);
  const [businessEmail, setBusinessEmail] = useState(null);
  const [businessPhone, setBusinessPhone] = useState(null);
  const [businessAddress, setBusinessAddress] = useState(null);
  const [businessImage, setBusinessImage] = useState(null);
  const [image, setImage] = useState(null);
  const [businessDescription, setBusinessDescription] = useState(null);

  const handleSaveChanges = async () => {
    // e.preventDefault();
    const formData = new FormData();
    if (image) formData.append("business_image", image);
    formData.append("business_name", businessName);
    formData.append("business_email", businessEmail);
    formData.append("business_phone", businessPhone);
    formData.append("business_address", businessAddress);
    formData.append("business_description", businessDescription);

    try {
      await patchGenSettings({
        access,
        body: formData,
        slug,
      });
    } catch (error) {
      console.error("Error uploading image", error);
    }
  };

  const handleButtonClick = () => {
    document.getElementById("logoInput").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBusinessImage(file);
    setImage(file);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    setDragOver(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  const handleDragLeave = () => {
    setDragOver(false); // Reset drag over state when leaving the drop area
  };
  const handleDeleteImage = async () => {
    if (!image && !businessImage) {
      return null;
    }

    try {
      await patchGenSettings({
        access,
        body: { business_image: null },
        slug,
      });
      setBusinessImage(null);
      setImage(null);
    } catch (error) {
      console.error("Error deleting image", error);
    }
  };

  useEffect(() => {
    if (data) {
      const {
        business_name,
        business_email,
        business_phone,
        business_address,
        business_image,
        business_description,
      } = data;
      setBusinessName(business_name);
      setBusinessEmail(business_email);
      setBusinessPhone(business_phone);
      setBusinessAddress(business_address);
      setBusinessImage(business_image);
      setBusinessDescription(business_description);
    }
  }, [data]);

  return (
    <div className="py-8 px-4 md:px-8">
      <OverLoader isLoading={saving} />
      <div className="flex items-start justify-center w-full lg:h-[80vh] h-full flex-col lg:gap-[95px] gap-10">
        <HeadText sub="Update your store information here" color="purple">
          Settings
        </HeadText>

        <div className="flex flex-col gap-[42px] justify-between items-start h-full">
          <div className="flex flex-col-reverse items-center md:items-start gap-14 lg:flex-row md:justify-between lg:gap-0 ">
            <div className="flex flex-wrap justify-start items-center gap-6">
              <div className="flex w-full md:w-[340px] flex-col gap-1">
                <label htmlFor="">Store Name</label>
                <input
                  type="text"
                  value={businessName || ""}
                  onChange={(e) => setBusinessName(e.target.value)}
                  placeholder="Enter Store name"
                  className=" rounded-lg border border-[#E4E4E7] h-10 pl-2"
                />
              </div>
              <div className="flex  w-full md:w-[340px] flex-col gap-1">
                <label htmlFor="">Store Phone</label>
                <input
                  type="number"
                  value={businessPhone || ""}
                  onChange={(e) => setBusinessPhone(e.target.value)}
                  placeholder="Enter store Phone number"
                  className=" rounded-lg border border-[#E4E4E7] h-10 pl-2"
                />
              </div>
              <div className="flex w-full md:w-[340px] flex-col gap-1">
                <label htmlFor="">Store e-mail</label>
                <input
                  type="email"
                  value={businessEmail || ""}
                  onChange={(e) => setBusinessEmail(e.target.value)}
                  placeholder="Enter store e-mail"
                  className=" rounded-lg border border-[#E4E4E7] h-10 pl-2"
                />
              </div>
              <div className="flex w-full md:w-[340px] flex-col gap-1">
                <label htmlFor="">Store Address</label>
                <input
                  value={businessAddress || ""}
                  onChange={(e) => setBusinessAddress(e.target.value)}
                  type="text"
                  placeholder="Enter store address"
                  className=" rounded-lg border border-[#E4E4E7] h-10 pl-2"
                />
              </div>
              <div className="w-full md:w-[340px] flex flex-col gap-1">
                <label htmlFor="">Store Description</label>
                <textarea
                  value={businessDescription || ""}
                  onChange={(e) => setBusinessDescription(e.target.value)}
                  type="text"
                  className="p-2 h-32 rounded-md border border-[#E4E4E7]"
                  placeholder="Enter Store description"
                />
              </div>
            </div>
            <div className="lg:w-[70%] w-full flex items-center justify-center flex-col gap-3">
              <div className="w-[250px] flex flex-col rounded gap-2 p-6 bg-white">
                <span className="flex text-sm w-full relative justify-end group cursor-pointer">
                  <span className="flex cursor-pointer">
                    <MdDelete onClick={handleDeleteImage} size={23} />
                  </span>
                </span>
                {businessImage && !image ? (
                  <div>
                    <img
                      className="w-full h-full"
                      src={businessImage}
                      width={0}
                      height={0}
                      alt=""
                    />
                  </div>
                ) : image ? (
                  <div>
                    <img
                      className="w-full h-full"
                      src={URL.createObjectURL(image)}
                      width={0}
                      height={0}
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    onClick={handleButtonClick}
                    onDrop={handleDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    className={`flex flex-col gap-3 cursor-pointer border text-lg p-3 border-dashed  justify-center items-center ${
                      dragOver ? "border-[#7e57c2] " : "border-[#D0D5DD] "
                    }`}
                  >
                    <span>
                      <BiCloudUpload size={25} />
                    </span>

                    <p className="text-xs text-center">
                      Drag or Browse and chose the image you want to upload from
                      your computer
                    </p>

                    <button className="bg-[#7E57C2] text-2xl  p-1 rounded text-white">
                      <IoAdd />
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="business_image"
                  className="hidden"
                  id="logoInput"
                  onChange={handleImageChange}
                />
                <button
                  onClick={handleButtonClick}
                  className="bg-[#7E57C2] text-white pt-2 pl-3 pr-3 pb-2 rounded-lg"
                >
                  {!(image || businessImage) ? "Upload" : "Change Logo"}
                </button>
              </div>
            </div>
          </div>
          <div className="flex gap-3 items-center justify-center flex-col sm:flex-row sm:w-fit w-full">
            <button
              type="submit"
              onClick={handleSaveChanges}
              className="pt-2  pl-3 pr-3 pb-2 rounded-lg bg-[#7E57C2] text-white"
            >
              Save Changes
            </button>
            {/* <button className="pt-2 pl-3 pr-3 pb-2 rounded-lg bg-[#DF3939] text-white">
              Deactivate Store
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
}
