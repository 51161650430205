import { Suspense } from "react";
import EcommerceHome from "./Sitetype/ecommerce/EcommerceHome";
import Loading from "./loading";
import ErrorBoundary from "@/components/ErrorBoundary";
import Error from "./global-error";

// const EcommerceHome = dynamic(() =>
//   import("./Sitetype/ecommerce/EcommerceHome")
// );
function Page() {
  
  return (
    <ErrorBoundary fallback={<Error />}>
      <Suspense fallback={<Loading />}>
        <EcommerceHome />
      </Suspense>
    </ErrorBoundary>
  );
}


export default Page