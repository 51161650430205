import { useEffect } from "react";
import { useLazyGetProductDetailQuery } from "@/lib/api/productApi";
import { useAppSelector } from "@/lib/hooks";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import { ImageCarosel } from "./ImageCarosel";
import Loading from "@/app/loading";
import { addCommasToMonetary } from "@/utils/formatting";
import PropTypes from "prop-types";
import { FaPenAlt, FaTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";

export default function PreviewDetails({ id, deleteAction }) {
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);


  const [getProductDetail, { data, isLoading }] =
    useLazyGetProductDetailQuery();
  useEffect(() => {
    getProductDetail({
      slug,
      access,
      id,
    });
  }, [access, getProductDetail, id, slug]);

  const {
    name,
    price,
    description,
    id: productId,
    discount_price,
    // inventory,
    // is_active,
    images,
    categories,
    // variants,
    // requires_shipping,
  } = data || {};
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="w-full flex justify-center items-center gap-4 flex-col">
      <div className="flex gap-2">
        <Link to={`${productId}`} className=" flex justify-center items-center py-2 gap-2 px-3 rounded bg-[#E6E6E6]/60 w-full font-medium border-[#E6E6E6]">
          <span>
            <FaPenAlt />
          </span>
          Edit
        </Link>
        <button onClick={deleteAction} className=" flex justify-center items-center py-2 gap-2 px-3 rounded bg-red-600 text-white w-full font-medium border-[#E6E6E6]">
          <span>
            <FaTrashAlt />
          </span>
          Delete
        </button>
      </div>
      <div className="w-[95%] md:w-[70%] relative flex items-center justify-center">
        {images && <ImageCarosel images={images} />}
      </div>
      <div className="w-full flex flex-col gap-3 items-start justify-center">
        <div className="w-full flex flex-wrap">
          {Array.isArray(categories) ? (
            categories.map((cat, index) => (
              <span
                key={index}
                className="pt-1 pb-1 pl-4 pr-4 flex items-center gap-1 justify-center mb-3 bg-[#F8F4FF] text-[#7E57C2] rounded-md whitespace-nowrap"
              >
                <div className="flex gap-2 items-center justify-center">
                  {cat.name}
                </div>
              </span>
            ))
          ) : (
            <span>{categories}</span>
          )}
        </div>
        <h4>{name}</h4>
        <div className="flex items-center justify-center gap-2">
          <h2 className=" text-[#FF675E]">N{addCommasToMonetary(price)}</h2>
          <p className="line-through">N{addCommasToMonetary(discount_price)}</p>
        </div>
        <span className="text-[#71717A]">{description}</span>
      </div>
    </div>
  );
}

PreviewDetails.propTypes = {
  id: PropTypes.string.isRequired,
  deleteAction: PropTypes.func.isRequired,
};
