import { useEffect } from "react";

/**
 * Custom hook that triggers a callback when a click is detected outside of the specified elements or class names.
 *
 * @param {React.RefObject[] | React.RefObject} refs - A single ref or an array of refs to the elements to detect outside clicks.
 * @param {string[] | string} classNames - A single class name or an array of class names to detect outside clicks.
 * @param {Function} callback - The function to call when an outside click is detected.
 *
 * @example
 * const ref = useRef();
 * useOutsideClick(ref, 'my-class', () => {
 *   console.log('Clicked outside');
 * });
 *
 * @returns {void}
 */
const useOutsideClick = (refs, classNames, callback) => {
  const handleClick = (event) => {
    const refArray = Array.isArray(refs) ? refs : [refs];
    const classArray = Array.isArray(classNames) ? classNames : [classNames];

    const isOutsideClick = refArray.every(
      (ref) => ref.current && !ref.current.contains(event.target)
    );

    const isOutsideClass = classArray.every(
      (className) => !event.target.classList.contains(className)
    );

    if (isOutsideClick && isOutsideClass) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [refs, classNames, callback]);
};

export default useOutsideClick;
