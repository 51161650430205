import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const userData =
  Cookies.get("jellosite-user", {
    domain: `.${import.meta.env.VITE_APP_DOMAIN_NAME}`,
  }) || null;
const initialState = {
  access:
    Cookies.get("jellosite-authToken") ||
    Cookies.get(import.meta.env.VITE_APP_COOKIE_TOKEN),
  user: JSON.parse(userData),
  sites: [],
  isSiteOnTrial: false,
  isSiteSuscribed: false,
  overviewPhase: 1,
  currentSlug:
    Cookies.get("jellosite-current-slug") ||
    Cookies.get(import.meta.env.VITE_APP_COOKIE_SLUG) ||
    null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSites(state, action) {
      state.sites = action.payload;
    },
    checkCurrentSiteSubscription(state) {
      if (state.sites.length > 0) {
        const currentSite = state.sites.find(
          (site) => site.slug === state.currentSlug
        );
        if (currentSite) {
          state.isSiteOnTrial = currentSite.is_free_trial;
          state.isSiteSuscribed = currentSite.is_subscribed;
          state.subEndAt = currentSite.sub_ends_at;
        }
      }
    },
    setOverviewPhase(state, action) {
      state.overviewPhase = action.payload;
    },
    setCurrentSlug(state, action) {
      state.currentSlug = action.payload;
      Cookies.set("jellosite-current-slug", action.payload, {
        domain: `.${import.meta.env.VITE_APP_DOMAIN_NAME}`,
      });
      // location.reload(true);
    },
  },
});

export const { user, access } = (state) => state.user;
export const userNameSelector = (state) => state.user.user?.first_name;
export const siteType = (state) => state.user.currentSite.siteType;
export const accesstokenSelector = (state) => state.user.access;
export const currentSlug = (state) => state.user.currentSlug;
export const isOnFreeTrial = (state) => state.user.isSiteOnTrial;
export const isSubscribed = (state) => state.user.isSiteSuscribed;
export const overviewPhaseSelector = (state) => state.user.overviewPhase;
export const {
  setCurrentSlug,
  setSites,
  setOverviewPhase,
  checkCurrentSiteSubscription,
} = userSlice.actions;
export default userSlice.reducer;
