import { useState, useEffect } from "react";
import macbookpro from "@/assets/MacBookPro16.svg";
import temp3 from "@/assets/temp3.svg";
import featemp from "@/assets/featemp.svg";
import { SlBulb } from "react-icons/sl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faFireFlameCurved,
  faHand,
  faNoteSticky,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  reset,
  getAllTemplate,
  getCurrentTemplate,
  updateCurrentTemplate,
  editTemplate,
} from "@/lib/templates/templateSlice";
import { currentSlug } from "@/lib/user/userSlice";
import { Link } from "react-router-dom";
import { useAppSelector } from "@/lib/hooks";
import HeadText from "../../components/HeadText";
import Cookies from "js-cookie";
import { STORE_DOMAIN } from "@/utils/constants";
import { accesstokenSelector } from "../../lib/user/userSlice";

export default function Page() {
  const [activeTab, setActiveTab] = useState("All");
  const [activeOptionIndex, setActiveOptionIndex] = useState(null);

  const slug = useAppSelector(currentSlug);

  const handleOptions = (index) => {
    setActiveOptionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentTemplate());
    dispatch(getAllTemplate());
    dispatch(reset());
  }, [dispatch]);

  const data = useSelector((state) => state.template.data);
  const current = useSelector((state) => state.template.currentTheme);
  const access = useAppSelector(accesstokenSelector);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  console.log(current)

  function changeTheme(template_slug) {
    dispatch(updateCurrentTemplate(template_slug));
  }

  function editTheme(template_slug) {
    dispatch(editTemplate(template_slug));
    window.open(
      `https://${slug}.${STORE_DOMAIN}?edit=true&template=${template_slug}&access_token=${access}`,
      "_blank"
    );
  }

  function viewSite() {
    window.open(`https://${slug}.${STORE_DOMAIN}`, "_blank");
  }

  const renderTemplates = (templates) => {
    return templates.map(
      (template, index) =>
        template.slug_name !== current?.template?.slug_name && (
          <div key={template.id} className="rounded-lg relative h-fit w-fit">
            <img
              src={template?.image || temp3}
              alt={template.name}
              className="w-[330px] h-[250px]"
            />
            <p className="mt-2">{template.name}</p>
            <div className="gap-2 flex items-center justify-start">
              <FontAwesomeIcon icon={faNoteSticky} color="#866BB6" />
              <span className="text-[#cea0dc]">{template.description}</span>
            </div>
            <div
              className="pt-1 pl-4 pr-4 pb-1 rounded-md absolute top-3 right-3 z-2 bg-white border border-#B6B6B6 shadow-lg cursor-pointer"
              onClick={() => handleOptions(index)}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
              {activeOptionIndex === index && (
                <div className="absolute left-[-170px] top-[10px] min-w-[160px]">
                  <div className="flex p-3 bg-[#cea0dc] gap-1 flex-col item-start justify-center w-full">
                    <div
                      className="flex items-center justify-start gap-3 text-white w-full"
                      onClick={() => editTheme(template?.slug_name)}
                    >
                      <FontAwesomeIcon icon={faHand} />
                      <p>Edit</p>
                    </div>
                    <div
                      className="flex items-center justify-start gap-3 text-white w-full"
                      onClick={() => changeTheme(template?.slug_name)}
                    >
                      <FontAwesomeIcon icon={faHand} />
                      <p>Make current</p>
                    </div>
                    {/* <div className="flex items-center justify-start gap-3 text-white">
                  <FontAwesomeIcon icon={faCoins} />
                  <p>Buy</p>
                </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
        )
    );
  };
  return (
    <div className="flex gap-9 flex-col items-start max-w-full overflow-auto py-8 px-4 md:px-8">
      <div className=" w-full flex items-center justify-between">
        <HeadText color="purple">Templates</HeadText>
        <button className="border border-[#7E57C233] text-white bg-[#866BB6] p-1 pl-3 pr-3 rounded-lg">
          <Link to="/settings/policies">Edit Policies</Link>
        </button>
      </div>
      <div className="flex flex-col gap-10 w-full">
        <div className="flex items-start md:justify-start justify-center gap-10 w-full flex-col md:flex-row">
          <div className="flex w-full md:w-fit items-center md:items-start justify-center gap-5 flex-col">
            <div className=" pt-4 pb-4 flex items-center justify-center w-full rounded-md border border-[#E5E9EB] bg-white">
              <img src={macbookpro} alt="Alt" />
            </div>
            <div className=" flex item-center justify-start gap-3 p-2 rounded-xl bg-[#0D0F10]">
              <SlBulb color="#495dd7" />{" "}
              <span className="text-white">
                Tip: Your online store is visible to your customers in the{" "}
                <span className=" text-[#ba6863]">
                  {current?.template?.name}
                </span>{" "}
                template.
              </span>
            </div>
          </div>
          <div className="p-4 rounded-xl border border-[#E5E9EB] flex  items-start justify-center gap-6 flex-col bg-white md:w-1/2 selection:h-full w-full">
            <div className=" flex items-center justify-center gap-6">
              <h3 className="text-[#A48FC8]">{current?.template?.name}</h3>
              <div className="p-1 pl-3 pr-3 rounded-md border border-[#E5E9EB]">
                <span>Current</span>{" "}
                <FontAwesomeIcon icon={faFireFlameCurved} color="orange" />
              </div>
            </div>
            <span className="text-[#686B6E]">
              {current?.template?.description}
            </span>
            <div className="flex items-center justify-center gap-3">
              <button
                className="bg-[#866BB6] text-white p-2 rounded-md"
                onClick={viewSite}
              >
                <FontAwesomeIcon icon={faGlobe} /> Preview
              </button>
              <button
                className="bg-[#686B6E] text-white p-2 rounded-md"
                onClick={() => editTheme(current?.template?.slug_name)}
              >
                <FontAwesomeIcon icon={faHand} /> Edit
              </button>
              {/* <button className="bg-[#0D0F10] text-white p-2 rounded-md">
                Change theme
              </button> */}
            </div>
            {/* <div className="w-full flex justify-center">
              <button className=" border border-[#7E57C233] text-[#866BB6] p-1 pl-3 pr-3 rounded-lg">
                View more in theme library
              </button>
            </div> */}
          </div>
        </div>
        {/* <div className="p-3 rounded-xl border border-[#E5E9EB] bg-white flex flex-col gap-3">
          Theme Library
          <div className="rounded-md border border-[#E5E9EB] bg-[#F5F5F599] text-center pt-4 pb-4 ">
            <span className="w-full">
              Try out new themes, work on seasonal versions or test changes to
              your current theme
            </span>
          </div>
        </div> */}
        <div className="p-4 flex justify-center gap-5 items-start bg-white rounded-2xl flex-col sm:flex-row w-full">
          <img src={featemp} alt="" />
          <div className="w-full flex flex-col gap-8">
            <div className=" flex flex-col gap-2 justify-start">
              <div className="flex items-center justify-start gap-5">
                <p>Popular free themes</p>
              </div>
              <p className="text-[#9B9C9E]">
                Here are some popular themes you might like. Take a look!
              </p>
            </div>
            <div className="flex flex-col justify-start items-start gap-10 max-w-full overflow-auto">
              <div className="w-full overflow-auto max-w-full">
                <div className="sm:w-full sm:px-0 px-2 pb-2 w-[520px] overflow-auto flex items-center justify-start gap-8">
                  {["All", "Popular", "Free", "Paid", "My", "Saved"].map(
                    (tabName) => (
                      <header
                        key={tabName}
                        className={
                          activeTab === tabName
                            ? "text-[#AA5BB7] font-normal pb-2 border-b-2 border-b-[#AA5BB7] cursor-pointer"
                            : "text-[#9B9C9E] pb-2 cursor-pointer"
                        }
                        onClick={() => handleTabClick(tabName)}
                      >
                        {tabName}
                      </header>
                    )
                  )}
                </div>
              </div>
              <div className=" w-full">
                {activeTab === "All" && (
                  <div className="flex sm:justify-start justify-center items-center flex-wrap gap-6">
                    {renderTemplates(data)}
                  </div>
                )}
                {activeTab === "Popular" && (
                  <div className="flex justify-start items-center flex-wrap gap-6">
                    {renderTemplates(data)}
                  </div>
                )}
                {activeTab === "Free" && (
                  <div className="flex justify-start items-center flex-wrap gap-6">
                    {renderTemplates(data)}
                  </div>
                )}
                {activeTab === "Paid" && (
                  <div className="flex justify-start items-center flex-wrap gap-6">
                    {renderTemplates(data)}
                  </div>
                )}
                {activeTab === "My" && (
                  <div className="flex justify-start items-center flex-wrap gap-6">
                    {renderTemplates(data)}
                  </div>
                )}
                {activeTab === "Saved" && (
                  <div className="flex justify-start items-center flex-wrap gap-6">
                    {renderTemplates(data)}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
