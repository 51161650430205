import { useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import OverLoader from "../../../components/OverLoader";
import { HexColorPicker } from "react-colorful";
import {
  accesstokenSelector,
  currentSlug,
  setOverviewPhase,
} from "../../../lib/user/userSlice";
import { useAppDispatch, useAppSelector } from "../../../lib/hooks";
import { toast } from "react-toastify";
import NextBar from "./NextBar";
import { BiCloudUpload, BiEdit } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { useSaveSiteDesighMutation } from "../../../lib/api/generalapi";
import { FaTrash } from "react-icons/fa";

const AddStoreDetails = () => {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [businessImage, setBusinessImage] = useState(null);
  const [image, setImage] = useState(null);

  const [colour1, setColour1] = useState("#aaca23");
  const [colour2, setColour2] = useState("#aaca23");
  const [colour3, setColour3] = useState("#aaca23");

  const [dragOver, setDragOver] = useState(false);
  const [phase] = useOutletContext();
  const [saveDesign, { isLoading: saving }] = useSaveSiteDesighMutation();

  const handleButtonClick = () => {
    document.getElementById("logoInput").click();
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setBusinessImage(file);
    setImage(file);
  };
  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    setDragOver(false);
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };
  const handleDragLeave = () => {
    setDragOver(false); // Reset drag over state when leaving the drop area
  };
  const handleDeleteImage = () => {
    if (!image && !businessImage) {
      return null;
    }
    setBusinessImage(null);
    setImage(null);
  };
  const handleSave = async () => {
    // Prepare the body for the API request
    try {
      const formData = new FormData();
      formData.append("business_image", businessImage);
      formData.append("theme_colors.primary_color", colour1);
      formData.append("theme_colors.secondary_color", colour2);
      formData.append("theme_colors.tertiary_color", colour3);

      const response = await saveDesign({
        access,
        body: formData,
        slug,
      });
      if (response.data) {
        dispatch(setOverviewPhase(4));
        navigate("/overview/go-live");
        toast.success("Site Design saved successfully");
      } else {
        toast.error("Error saving store details");
      }
    } catch (error) {
      toast.error(error);
    }
  };
  return (
    <div className="w-full border rounded-2xl  bg-white p-8  flex flex-col items-start justify-center gap-6">
      <OverLoader isLoading={saving} />
      <div>
        {" "}
        <input
          type="file"
          accept="image/*"
          name="business_image"
          className="hidden"
          id="logoInput"
          onChange={handleImageChange}
        />
        <h2 className="text-xl font-semibold">Site Design</h2>
        <p className="text-xs">
          This design will be used as the default design for your store.
        </p>
        <h2 className="text-lg font-medium mt-3">Add Site Logo</h2>
        <div className="flex p-2 flex-col gap-3 w-[200px] h-[200px] rounded-lg">
          {businessImage && !image ? (
            <div>
              <img
                className="w-full h-full"
                src={businessImage}
                width={0}
                height={0}
                alt=""
              />
              <div className="flex font-medium gap-2 mt-2">
                <button className="bg-red-500 flex items-center gap-2 text-white px-2 py-1 rounded">
                  <span>
                    <FaTrash />
                  </span>
                  <span onClick={handleDeleteImage}>Delete</span>
                </button>
                <button className="bg-slate-400 flex items-center gap-2 text-white px-2 py-1 rounded">
                  <span>
                    <BiEdit />
                  </span>
                  <span onClick={handleButtonClick}>Change</span>
                </button>
              </div>
            </div>
          ) : image ? (
            <div>
              <div className="w-[160px] h-[160px] rounded-lg">
                <img
                  className="w-full h-full object-cover"
                  src={URL.createObjectURL(image)}
                  width={0}
                  height={0}
                  alt=""
                />
              </div>
              <div className="flex font-medium gap-2 mt-2">
                <button className="bg-red-500 flex items-center gap-2 text-white px-2 py-1 rounded">
                  <span>
                    <FaTrash />
                  </span>
                  <span onClick={handleDeleteImage}>Delete</span>
                </button>
                <button className="bg-slate-400 flex items-center gap-2 text-white px-2 py-1 rounded">
                  <span>
                    <BiEdit />
                  </span>
                  <span onClick={handleButtonClick}>Change</span>
                </button>
              </div>
            </div>
          ) : (
            <div
              onClick={handleButtonClick}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              className={`flex flex-col gap-3  cursor-pointer border text-lg p-3 border-dashed h-full justify-center items-center ${
                dragOver ? "border-[#7e57c2] " : "border-[#D0D5DD] "
              }`}
            >
              <span>
                <BiCloudUpload size={25} />
              </span>

              <p className="text-xs text-center">
                Drag or Browse and chose the image you want to upload from your
                computer
              </p>

              <button className="bg-[#7E57C2] text-2xl  p-1 rounded text-white">
                <IoAdd />
              </button>
            </div>
          )}
        </div>
        <div className="mt-3">
          <h3 className="text-lg font-medium">Pick Colors</h3>
          <p className="text-xs">This colors represent your brand</p>
          <div className="flex mt-2 gap-4">
            <div className="group relative">
              <span className="font-medium">
                Colour 1:
                <div
                  style={{ backgroundColor: colour1 }}
                  className="w-10 rounded-full h-10"
                ></div>{" "}
              </span>
              <span className="absolute bottom-full hidden group-hover:block">
                <HexColorPicker color={colour1} onChange={setColour1} />
              </span>
            </div>
            <div className="group relative">
              <span className="font-medium">
                Colour 2:
                <div
                  style={{ backgroundColor: colour2 }}
                  className="w-10 rounded-full h-10"
                ></div>{" "}
              </span>
              <span className="absolute bottom-full hidden group-hover:block">
                <HexColorPicker color={colour2} onChange={setColour2} />
              </span>
            </div>
            <div className="group relative">
              <span className="font-medium">
                Colour 3:
                <div
                  style={{ backgroundColor: colour3 }}
                  className="w-10 rounded-full h-10"
                ></div>{" "}
              </span>
              <span className="absolute bottom-full hidden group-hover:block">
                <HexColorPicker color={colour3} onChange={setColour3} />
              </span>
            </div>
          </div>
        </div>
      </div>

      <NextBar handleSave={handleSave} phase={phase} />
    </div>
  );
};

export default AddStoreDetails;
