import Loading from "@/app/loading";
import CheckBox from "@/components/CheckBox";
import { useLazyGetProductListQuery } from "@/lib/api/productApi";
import { useAppSelector } from "@/lib/hooks";
import { currentSlug } from "@/lib/user/userSlice";
import PropTypes, { object } from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { BsInfo, BsPlus } from "react-icons/bs";
import { HiMinus } from "react-icons/hi";
import { IoArrowBack, IoInformationCircleOutline } from "react-icons/io5";
import { MdCancel } from "react-icons/md";
import { useCreateOrderMutation } from "../../../lib/api/ordersApi";
import { accesstokenSelector } from "../../../lib/user/userSlice";
import OverLoader from "../../../components/OverLoader";
import { toast } from "react-toastify";
import { useGetStatesAndLgaQuery } from "../../../lib/api/generalapi";
import {
  isValidEmail,
  isValidPhoneNumber,
} from "../../../utils/inputValidators";
import AutocompleteInput from "../../../components/PlaceAutoCompleteInput";
import { truncateWithElipses } from "../../../utils/formatting";
import { FaMinus, FaPlus, FaTrashAlt } from "react-icons/fa";

export const CreateForm = ({ isOpen, setIsOpen, refetch }) => {
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [adress, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [sendMail, setSendMail] = useState(false);
  const [isStateDropdownVisible, setIsStateDropdownVisible] = useState(false);
  const [isCityDropdownVisible, setIsCityDropdownVisible] = useState(false);
  const [statesAndLga, setStatesAndLga] = useState(null);

  const [inputError, setInputError] = useState(null);

  const formStateSetter = (setter) => (e) => setter(e.target.value);
  const [getProductList, { data: products, isFetching }] =
    useLazyGetProductListQuery();
  const [createOrder, { isLoading: creating }] = useCreateOrderMutation();
  const { data: states_and_lga } = useGetStatesAndLgaQuery();
  useEffect(() => {
    if (states_and_lga) {
      setStatesAndLga(states_and_lga);
    }
  }, [states_and_lga]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleQuantityChange = (index, value) => {
    const updatedProducts = [...selectedProducts];

    if (
      value === "inc" &&
      updatedProducts[index].quantity < updatedProducts[index].product.inventory
    ) {
      updatedProducts[index].quantity += 1;
    } else if (value === "dec" && updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
    } else if (
      !isNaN(value) &&
      value >= 0 &&
      value <= updatedProducts[index].product.inventory
    ) {
      updatedProducts[index].quantity = parseInt(value); // Ensure value is parsed to an integer
    }

    setSelectedProducts(updatedProducts);
  };

  const handleRemoveProducts = (index) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts.splice(index, 1);
    setSelectedProducts(updatedProducts);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (isValidEmail(email) === false) {
      return toast.error("Invalid email address");
    }
    if (isValidPhoneNumber(phone) === false) {
      return toast.error("Invalid phone number");
    }
    console.log(selectedProducts[0]);
    const items = selectedProducts.map((products) => ({
      product: products.product.id,
      quantity: products.quantity,
    }));
    const body = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      items,
      shipping_details: {
        customer_name: customerName,
        address: adress,
      },
      send_mail: sendMail,
    };

    try {
      const response = await createOrder({ access, slug, body });
      if (response.error) {
        throw new Error(response.error);
      } else {
        setIsOpen(false);
        toast.success("Order created successfully");
        setEmail("");
        setFirstName("");
        setLastname("");
        setPhone("");
        setCustomerName("");
        setAddress("");
        setSendMail(false);
        setSelectedProducts([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      refetch();
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getProductList({ slug });
    }
  }, [getProductList, isOpen, slug]);

  return (
    <form
      className="w-full h-[500px] lg:h-full flex relative z-0 flex-col pb-3 gap-5 space-y-0.5"
      onSubmit={handleSave}
    >
      <OverLoader isLoading={creating} />
      <div className="flex w-full justify-between">
        <div className="flex gap-2 items-center">
          <span onClick={() => setIsOpen(false)} className="cursor-pointer">
            <IoArrowBack size={25} />
          </span>
          <h3 className="font-medium">Create Order</h3>
        </div>
      </div>
      <div className="overflow-y-auto scrollbar-hide flex flex-col gap-8 items-start justify-start">
        <div className="flex flex-col  w-full gap-4">
          <div className="flex items-center justify-center gap-2">
            <h4 className="font-medium bg-white relative z-10 min-w-fit w-full p-1 text-jello-purple">
              User Details
            </h4>
            <hr className="w-full relative -z-5 h-1" />
          </div>
          <div className=" flex flex-col items-start w-full gap-3">
            <div className="flex col-span-3 flex-col w-full">
              <label className="text-sm font-medium" htmlFor="">
                Email
              </label>
              <input
                value={email}
                required
                onBlur={() => {
                  if (email && isValidEmail(email) === false) {
                    setInputError((prev) => ({
                      ...prev,
                      email: "Invalid email address",
                    }));
                  } else {
                    setInputError(null);
                  }
                }}
                onChange={(e) => {
                  if (isValidEmail(e.target.value)) {
                    setInputError((prev) => ({ ...prev, email: undefined }));
                  }
                  formStateSetter(setEmail)(e);
                }}
                placeholder="example@gmail.com"
                className="border rounded-md p-2"
                type="text"
              />
              {inputError?.email && (
                <p className="text-xs flex items-center gap-1 py-1 text-red-600">
                  <span>
                    <IoInformationCircleOutline />
                  </span>
                  {inputError.email}
                </p>
              )}
            </div>
            <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3">
              <div className=" w-full flex flex-col">
                <label className="text-sm font-medium" htmlFor="">
                  First Name
                </label>
                <input
                  value={firstName}
                  required
                  onChange={(e) => formStateSetter(setFirstName)(e)}
                  placeholder="*John"
                  className="border rounded-md p-2"
                  type="text"
                />
              </div>
              <div className=" w-full flex flex-col">
                <label className="text-sm font-medium" htmlFor="">
                  Last Name
                </label>
                <input
                  placeholder="Doe"
                  value={lastName}
                  required
                  onChange={(e) => formStateSetter(setLastname)(e)}
                  className="border rounded-md  p-2"
                  type="text"
                />
              </div>
              <div className=" w-full flex flex-col">
                <label className="text-sm font-medium" htmlFor="">
                  Phone Number
                </label>
                <input
                  required
                  value={phone}
                  onBlur={() => {
                    if (phone && isValidPhoneNumber(phone) === false) {
                      setInputError((prev) => ({
                        ...prev,
                        phone: "Invalid phone number",
                      }));
                    } else {
                      setInputError(null);
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value.length > 11) return;
                    if (isValidPhoneNumber(e.target.value)) {
                      setInputError((prev) => ({ ...prev, phone: undefined }));
                    }
                    if (/^\d*$/.test(e.target.value)) {
                      formStateSetter(setPhone)(e);
                    }
                  }}
                  placeholder="*909300222"
                  className="border rounded-md p-2"
                  type="number"
                />
                {inputError?.phone && (
                  <p className="text-xs flex items-center gap-1 py-1 text-red-600">
                    <span>
                      <IoInformationCircleOutline />
                    </span>
                    {inputError.phone}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-4">
          <div className="flex items-center justify-center gap-2">
            <h4 className="font-medium bg-white relative z-10 min-w-fit p-1 text-jello-purple">
              Shipping details
            </h4>
            <hr className="w-full relative -z-5 h-1" />
          </div>
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium" htmlFor="">
              Customer Name
            </label>
            <input
              required
              value={customerName}
              onChange={(e) => formStateSetter(setCustomerName)(e)}
              placeholder="*John"
              className="border rounded-md p-2"
              type="text"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-sm font-medium" htmlFor="">
              Address
            </label>
            {/* <input
                required
                value={adress}
                onChange={(e) => formStateSetter(setAddress)(e)}
                placeholder="5 Duck Town"
                className="border rounded-md p-2"
                type="text"
              /> */}
            <AutocompleteInput
              classNames="border rounded-md p-2"
              onPlaceSelected={(place) => {
                setAddress(place.formatted_address);
              }}
            />
          </div>

        </div>
        <div className="w-full flex flex-col gap-2 my-2">
          {selectedProducts.length > 0 && (
            <>
              <div className="flex gap-2 flex-col w-full">
                <h3>Selected</h3>

                {selectedProducts?.map(
                  ({ product, image, quantity }, index) => (
                    <div
                      className="flex w-full justify-between bg-slate-500/35 px-2 py-1 rounded-sm"
                      key={index}
                    >
                      <div className="flex">
                        {image && (
                          <img
                            src={image}
                            width={30}
                            height={30}
                            alt={product.name}
                          />
                        )}
                        <h3 className="font-medium">{truncateWithElipses(product.name, 20)}</h3>
                      </div>
                      <div className="flex items-center">
                        <span
                          className="cursor-pointer"
                          onClick={() => handleQuantityChange(index, "dec")}
                        >
                          <FaMinus />
                        </span>
                        <input
                          type="number"
                          value={quantity}
                          className="text-center w-[30px] focus:border bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none p-1"
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                        />
                        <span
                          className="cursor-pointer mr-2"
                          onClick={() => handleQuantityChange(index, "inc")}
                        >
                          <FaPlus />
                        </span>
                        <span
                          onClick={() => handleRemoveProducts(index)}
                          className="cursor-pointer ml-2"
                        >
                          <FaTrashAlt />
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}

          <div name="products" id="">
            {isFetching ? (
              <Loading />
            ) : products && products.length > 0 ? (
              <Dropdown
                selectedOptions={selectedProducts}
                setSelectedOptions={setSelectedProducts}
                options={products}
              />
            ) : (
              <div>No products found</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center font-medium">
        <CheckBox checked={sendMail} onChanger={() => setSendMail(!sendMail)} />
        <label htmlFor="send_mail">Send email to customer</label>
      </div>

      <button
        onClick={(e) => handleSave(e)}
        disabled={!isValidEmail(email) || !isValidPhoneNumber(phone)}
        className="py-2 px-3 rounded bg-jello-purple text-white"
      >
        Create Order
      </button>
    </form>
  );
};

CreateForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const Dropdown = ({ options, setSelectedOptions, selectedOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOption = (option) => {
    setIsOpen(false);
    const index = selectedOptions.findIndex(
      (item) => item.product.id === option.id
    );

    if (index !== -1) {
      const updatedOptions = [...selectedOptions];
      updatedOptions[index].quantity += 1;
      setSelectedOptions(updatedOptions);
    } else {
      setSelectedOptions((prev) => [
        ...prev,
        {
          product: option,
          image: option.images.length > 0 ? option.images[0].image : undefined,
          quantity: 1,
        },
      ]);
    }
  };

  const handleOptionClick = (option) => {
    toggleOption(option);
  };

  return (
    <div className="relative z-0">
      <div
        className="border border-gray-300 font-medium rounded-md p-1 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        Select Products
      </div>

      <div
        className={`absolute overflow-y-scroll w-full duration-200 top-full z-30 bg-white border border-t-0 rounded-t-none border-gray-300 rounded-md mt-1 ${isOpen
          ? "translate-y-0"
          : " translate-y-[10px] max-h-0 opacity-0 pointer-events-none"
          }`}
      >
        {options.map((option, index) => (
          <div
            key={index}
            onClick={() => handleOptionClick(option)}
            className="py-2 px-3 gap-3 flex cursor-pointer"
          >
            {option.images.length > 0 && (
              <img
                src={option.images[0].image}
                width={30}
                height={30}
                alt={option.name}
              />
            )}
            <div>
              <h3 className="font-medium"> {option.name}</h3>
              <p className="text-xs text-ellipsis overflow-hidden truncate">
                {option.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
  refetch: PropTypes.func,
};
