import { generalApiSlice } from "./generalapi";

export const productApiSlice = generalApiSlice.injectEndpoints({
  endpoints: (build) => ({
    providesTags: ["Products"],
    getProductList: build.query({
      query: ({ slug }) => ({
        url: `ecommerce/${slug}/product/list`,
        method: "GET",
      }),
    }),
    addProduct: build.mutation({
      invalidatesTags: ["Products"],
      query: ({ body, access, slug }) => ({
        url: `ecommerce/${slug}/product/create/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    patchProduct: build.mutation({
      invalidatesTags: ["Products", "Product"],
      query: ({ body, access, slug, id }) => ({
        url: `ecommerce/${slug}/product/update/${id}/`,
        method: "PATCH",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getProductDetail: build.query({
      providesTags: ["Product"],
      query: ({ id, slug, access }) => ({
        url: `ecommerce/${slug}/product/detail/${id}`,
        method: "GET",
      }),
    }),
    deleteProduct: build.mutation({
      invalidatesTags: ["Product", "Products"],
      query: ({ access, id, slug }) => ({
        url: `ecommerce/${slug}/product/delete/${id}`,
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    deleteProductImage: build.mutation({
      invalidatesTags: ["Product"],
      query: ({ access, slug, body }) => ({
        url: `/ecommerce/${slug}/product/images/delete/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    deleteVariants: build.mutation({
      invalidatesTags: ["Product"],
      query: ({ access, slug, body }) => ({
        url: `/ecommerce/${slug}/product/variants/delete/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
  }),
});

export const {
  useAddProductMutation,
  usePatchProductMutation,
  useGetProductListQuery,
  useLazyGetProductListQuery,
  useDeleteProductMutation,
  useGetProductDetailQuery,
  useLazyGetProductDetailQuery,
  useDeleteProductImageMutation,
  useDeleteVariantsMutation
} = productApiSlice;
