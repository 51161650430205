import PropTypes from "prop-types";

const HeadText = ({ sub, children, color, className }) => {
  return (
    <span className={`flex flex-col gap-2 ${className}`}>
      <h1
        className={`pageheader ${
          color == "purple"
            ? "text-[#7E57C2]"
            : color == "black"
            ? "text-black"
            : ""
        }`}
      >
        {children}
      </h1>
      {sub && <h2>{sub}</h2>}
    </span>
  );
};

HeadText.propTypes = {
  sub: PropTypes.string,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  color: PropTypes.oneOf(["grey", "purple", "black"]),
};
export default HeadText;
