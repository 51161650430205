import { useState, useEffect } from "react";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudUpload,
  faPlus,
  faTrashAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import ProductPreview from "./comp/ProductPreview";
import {
  useAddProductMutation,
  useDeleteProductImageMutation,
  useDeleteProductMutation,
  useGetProductDetailQuery,
  usePatchProductMutation,
  useDeleteVariantsMutation,
} from "@/lib/api/productApi";

import { useAppSelector } from "@/lib/hooks";
import { ensureNonNegative } from "@/utils/formatting";
import OverLoader from "@/components/OverLoader";
import { useNavigate, useParams } from "react-router-dom";
import ImageModal from "./comp/ModalComp";
import ImagePreview from "./comp/imagePreview";
import HeadText from "../../../components/HeadText";
import ProgressBar from "../../../components/progressBar";
// import ProgressBar from "../../../components/progressBar";

export default function Page() {
  const paramsId = useParams().id;
  const navigate = useNavigate();

  const [productName, setProductName] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [price, setPrice] = useState();
  const [discPrice, setDiscPrice] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  const [quantity, setQuantity] = useState("");

  const [selectedImages, setSelectedImages] = useState([]);
  const [imgErrMessage, setImgErrMessage] = useState("");

  const [category, setCategory] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);
  const [patchProduct, { isLoading: saving, isSuccess: patchedSuccess }] =
    usePatchProductMutation();
  const [deleteProduct, { isLoading: deleting }] = useDeleteProductMutation();
  const [imageSizeProgress, setImageSizeProgress] = useState(0);

  const [imgListToDel, setImgListToDel] = useState([]);
  const [variantsToDel, setVariantsToDel] = useState([]);
  const {
    data: productDetails,
    isError,
    error,
    refetch,
  } = useGetProductDetailQuery({
    id: paramsId,
    slug,
  });
  const [deleteProductImage, { isLoading: deletingImages }] =
    useDeleteProductImageMutation();
  const [deleteVariants, { isLoading: deletingVariants }] =
    useDeleteVariantsMutation();
  const [addProducts, { isLoading: isCreating }] = useAddProductMutation();

  const [totalSize, setTotalSize] = useState(0);

  useEffect(() => {
    if (
      paramsId !== "new" &&
      error &&
      error.originalStatus === 404
      // productDetails.every((product) => product.id !== paramsId)
    ) {
      navigate("/unauthorized");
    } else if (paramsId === "new") {
      // Reset inputs if creating new product
      setProductName("");
      setProductDesc("");
      setPrice("");
      setDiscPrice("");
      setQuantity("");
      setCategoriesList([]);
      setSelectedImages([]);
      setVariations([]);
      setNewVariation({ name: "", price: "", quantity: "" });
    }

    if (productDetails) {
      // Populate inputs with product details
      setProductName(productDetails.name);
      setProductDesc(productDetails.description);
      setPrice(productDetails.price);
      setDiscPrice(productDetails.discount_price);
      setQuantity(productDetails.inventory);
      setCategoriesList(productDetails.categories.map((cat) => cat.name));
      setSelectedImages(productDetails.images);
      setVariations(productDetails.variants);
    }
  }, [productDetails, isError, error, paramsId, navigate]);

  const addCategory = (e) => {
    e.preventDefault();
    if (category.trim() !== "" && !categoriesList.includes(category)) {
      setCategoriesList([...categoriesList, category]);
    }
    setCategory(""); // Clear the input field after adding the category
  };

  const removeCategory = (index) => {
    const updatedCategories = [...categoriesList];
    updatedCategories.splice(index, 1);
    setCategoriesList(updatedCategories);
  };

  const [variations, setVariations] = useState([]);
  const [newVariation, setNewVariation] = useState({
    name: "",
    price: "",
    quantity: "",
  });

  const addVariation = (e) => {
    e.preventDefault();
    if (
      newVariation.name.trim() !== "" &&
      newVariation.price.trim() !== "" &&
      newVariation.quantity.trim() !== ""
    ) {
      setVariations([...variations, newVariation]);
      setNewVariation({
        name: "",
        price: "",
        quantity: "",
      }); // Clear the input fields after adding variation
    }
  };

  const deleteVariation = (index) => {
    const newVariations = [...variations];
    newVariations.splice(index, 1);
    if (paramsId !== "new" && variations[index]?.id)
      setVariantsToDel((prev) => [...prev, variations[index].id]);
    setVariations(newVariations);
  };

  // const handleVariationChange = (index, field, value) => {
  //   const newVariations = [...variations];
  //   newVariations[index][field] = value;
  //   setVariations(newVariations);
  // };

  const openModal = (index) => {
    setIsModalOpen(true);
    setCurrentImageIndex(index);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleImageDelete = (index, image) => {
    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);
    const newTotalSize = updatedImages.reduce((acc, img) => acc + img.size, 0);
    setSelectedImages(updatedImages);
    setTotalSize(newTotalSize);
    setImageSizeProgress((newTotalSize / (8 * 1024 * 1024)) * 100);
    if (paramsId !== "new" && image.id) {
      setImgListToDel((prev) => [...prev, image.id]);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDraggingOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.dataTransfer.files;

    if (!files.length) return;

    const selectedFiles = Array.from(files).slice(0, 5);
    if (selectedImages.length + selectedFiles.length > 5) {
      const remainingSpace = 5 - selectedImages.length;
      selectedFiles.splice(remainingSpace);
      toast.error("You can only select up to 5 images.");
      return;
    }

    const newImagePreviews = selectedFiles.map((file) => ({
      src: URL.createObjectURL(file),
      alt: file.name,
      size: file.size,
    }));

    setSelectedImages([...selectedImages, ...newImagePreviews]);
    setImgErrMessage("");
    setIsDraggingOver(false);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;

    if (files.length > 0) {
      const selectedFiles = Array.from(files).slice(0, 5); // Limit to 5 files

      // Check if adding these files exceeds the 5-image limit
      if (selectedImages.length + selectedFiles.length > 5) {
        const remainingSpace = 5 - selectedImages.length;
        selectedFiles.splice(remainingSpace); // Keep only the allowed number of files
        toast.error("You can only select up to 5 images.");
        return;
      }

      // Calculate the total size of existing and new images
      const totalSize =
        selectedImages.reduce((acc, img) => acc + img.size, 0) +
        selectedFiles.reduce((acc, file) => acc + file.size, 0);

      setTotalSize(totalSize);
      setImageSizeProgress((totalSize / (8 * 1024 * 1024)) * 100);
      if (totalSize > 8 * 1024 * 1024) {
        // 8MB in bytes
        toast.error("The total size of the images exceeds 8MB.");
        return;
      }

      // Create image previews and update state
      const newImagePreviews = selectedFiles.map((file) => ({
        src: URL.createObjectURL(file), // Temporary preview URL
        alt: file.name, // File name
        file, // File object
        size: file.size, // File size in bytes
      }));

      setSelectedImages([...selectedImages, ...newImagePreviews]); // Update state
      setImgErrMessage("");
    }
  };

  const handleCreateProduct = async () => {
    const formdata = new FormData();
    formdata.append("name", productName);
    formdata.append("description", productDesc);
    formdata.append("price", price);
    formdata.append("discount_price", discPrice);
    formdata.append("is_active", true);
    formdata.append("inventory", quantity);
    formdata.append("requires_shipping", true);

    for (let i = 0; i < selectedImages.length; i++) {
      formdata.append("images_files", selectedImages[i].file);
    }

    for (let i = 0; i < categoriesList.length; i++) {
      formdata.append("categories_list", categoriesList[i]);
    }

    for (let i = 0; i < variations.length; i++) {
      formdata.append("variants", variations[i]);
    }

    await addProducts({
      access,
      body: formdata,
      slug,
    });
    toast.success("Product Added");
    navigate("/products", { state: { refetch: true } });
  };

  const handleUpdateProduct = async () => {
    const formdata = new FormData();

    // Compare each field and add to formdata only if it has been changed
    if (productDetails.name !== productName) {
      formdata.append("name", productName);
    }
    if (productDetails.description !== productDesc) {
      formdata.append("description", productDesc);
    }
    if (productDetails.price !== price) {
      formdata.append("price", price);
    }
    if (productDetails.discount_price !== discPrice) {
      formdata.append("discount_price", discPrice);
    }
    if (productDetails.inventory !== quantity) {
      formdata.append("inventory", quantity);
    }
    categoriesList.forEach((category) => {
      formdata.append("categories_list", category);
    });
    variations.forEach((variation) => {
      formdata.append("variants", variation);
    });
    selectedImages.forEach((image) => {
      if (!productDetails.images.includes(image)) {
        formdata.append("images_files", image.file);
      }
    });

    try {
      if (imgListToDel.length > 0) {
        const deleteImagesBody = { images: imgListToDel };
        await deleteProductImage({ body: deleteImagesBody, access, slug });
      }
      if (variantsToDel.length > 0) {
        const deleteVariantsBody = { variants: variantsToDel };
        await deleteVariants({ body: deleteVariantsBody, access, slug });
      }

      const result = await patchProduct({
        slug,
        access,
        id: paramsId,
        body: formdata,
      });

      if (result?.data) {
        toast.success("Product Updated");
        navigate("/products", { state: { refetch: true } });
      } else {
        throw new Error("Failed to update product");
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };
  const handleSave = () => {
    if (paramsId === "new") {
      handleCreateProduct();
    } else {
      handleUpdateProduct();
    }
  };
  const handleDelete = async () => {
    if (paramsId === "new") {
      return null;
    } else {
      await deleteProduct({
        slug,
        access,
        id: paramsId,
      });
      toast.success("Product deleled");
      navigate("/products");
      refetch();
    }
  };
  return (
    <>
      <div className="pageheader-wrapper flex flex-col gap-2 md:flex-row py-3 md:py-8 px-4 md:px-8">
        <HeadText color="purple">Add Products</HeadText>
        <OverLoader
          isLoading={
            saving ||
            deleting ||
            deletingImages ||
            isCreating ||
            deletingVariants
          }
        />
        <div className="header-buttons-wrapper">
          {/* <button
            onClick={handleDelete}
            className="bg-[#B92B27] text-white font-normal  py-1 px-3 rounded-md"
          >
            Delete
          </button> */}
          <button
            onClick={() => navigate("/products")}
            className=" py-1 px-3 rounded-md bg-[#E6E6E6]/60  font-medium border-[#E6E6E6]"
          >
            Cancel
          </button>
          {/* <button
            onClick={handleSave}
            className="border border-[#5FAA58] text-[#5FAA58] font-normal  py-1 px-3 rounded-md"
          >
            Save
          </button> */}
        </div>
      </div>
      <div className="flex flex-col px-8 w-full gap-5">
        <div className="flex md:mt-5 w-full gap-5 flex-col md:flex-row">
          <form className="add-product-form flex rounded gap-5 w-full md:w-[600px] p-8 pb-5 font-normal bg-white flex-col">
            <span>
              <label htmlFor="productName" className=" ">
                Product Name
              </label>
              <input
                value={productName}
                onChange={(e) => setProductName(e.target.value)}
                type="text"
                name="productName"
                placeholder="Enter Product name"
              />
            </span>
            <span>
              <label htmlFor="productDesc" className=" ">
                Product Description
              </label>
              <textarea
                value={productDesc}
                className="p-4"
                onChange={(e) => setProductDesc(e.target.value)}
                name="productDesc"
                placeholder="Enter Product description"
              />
            </span>
            <span className="!flex-row w-full gap-2">
              <span className="flex w-full">
                <label htmlFor="price" className=" ">
                  Price
                </label>

                <input
                  value={price}
                  className="pl-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  onChange={(e) => setPrice(ensureNonNegative(e.target.value))}
                  type="number"
                  name="price"
                />
              </span>
              <span className="flex w-full">
                <label htmlFor="discount" className=" ">
                  Discount price
                </label>
                <input
                  value={discPrice}
                  onChange={(e) =>
                    setDiscPrice(ensureNonNegative(e.target.value))
                  }
                  className="pl-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                  type="number"
                  name="discount"
                  placeholder="Leave empty or 0 if no discount is applied"
                />
              </span>
            </span>
            <span>
              <label htmlFor="quantity" className=" ">
                Inventory
              </label>
              <input
                value={quantity}
                className="pl-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                onChange={(e) => setQuantity(ensureNonNegative(e.target.value))}
                type="number"
                name="quantity"
              />
            </span>
            <div className="w-full flex flex-col items-start justify-center">
              <div className="flex items-end justify-center gap-3 w-full">
                <span className="flex flex-col gap-2 items-start justify-center w-full">
                  <label htmlFor="quantity" className=" ">
                    Category
                  </label>
                  <input
                    value={category}
                    className="pl-2"
                    onChange={(e) => setCategory(e.target.value)}
                    type="text"
                    name="quantity"
                  />
                </span>
                <button
                  className="text-white bg-[#7E57C2] p-2 w-fit rounded-lg font-normal"
                  onClick={addCategory}
                >
                  Add
                </button>
              </div>
              <div className="mt-3 max-w-[600px] overflow-auto flex gap-2 ">
                {Array.isArray(categoriesList) ? (
                  categoriesList.map((cat, index) => (
                    <span
                      key={index}
                      className="pt-1 pb-1 pl-4 pr-4 flex items-center justify-center mb-3 bg-[#F8F4FF] text-[#7E57C2] rounded-md whitespace-nowrap"
                    >
                      <div className="flex gap-2 items-center justify-center">
                        {cat}
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => removeCategory(index)}
                          cursor="pointer"
                        />
                      </div>
                    </span>
                  ))
                ) : (
                  <span>{categoriesList}</span>
                )}
              </div>
            </div>

            <span>
              <label htmlFor="quantity" className=" ">
                Images
              </label>
              <span className=" font-normal text-[#71717A] mt-[-5px]">
                Max of 5 images
              </span>
            </span>
            {/* Add products  */}
            <div className="flex w-full gap-3 items-center justify-center">
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                onClick={() => document.getElementById("imageInput").click()}
                className={`flex items-center justify-center flex-col gap-2 p-3 border border-dashed min-w-[100px] max-w-[140px] h-[150px] cursor-pointer ${
                  isDraggingOver ? "border-[#151319]" : "border-[#D0D5DD]"
                }`}
              >
                <input
                  type="file"
                  id="imageInput"
                  className="hidden"
                  name="productImages"
                  multiple
                  onChange={handleImageChange}
                  accept=".jpg, .jpeg, .png, .gif, .webp"
                />
                <FontAwesomeIcon icon={faCloudUpload} size="xs" />
                <span className="text-[#A1A1AA] text-[10px] text-center">
                  Browse and choose the image you want to upload from your
                  computer
                </span>
                <div>
                  <FontAwesomeIcon icon={faPlus} color="#7E57C2" />
                </div>
              </div>
              <div className="flex items-center justify-start gap-3 p-2 w-[400px] overflow-x-auto">
                {selectedImages.map((image, index) => (
                  <div
                    className="flex min-w-[150px] h-[140px] flex-col items-start overflow-hidden rounded-lg relative border-[#7e57e2] border"
                    key={index}
                  >
                    <ImagePreview {...image} className="w-full" />
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="absolute top-0 right-0 bg-transparent rounded-full m-2 h-5 w-5"
                      color="red"
                      cursor="pointer"
                      onClick={() => handleImageDelete(index, image)}
                    />
                    <div
                      className="absolute bottom-0 right-0 bg-purple-400 text-white rounded-md w-fit p-1 text-sm m-1"
                      cursor="pointer"
                    >
                      {image.size && image.size > 1024 * 1024
                        ? `${(image.size / (1024 * 1024)).toFixed(2)} MB`
                        : `${(image.size / 1024).toFixed(2)} KB`}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex w-full items-center justify-center gap-3">
              <p className="w-[150px]">
                {totalSize && totalSize > 1024 * 1024
                  ? `${(totalSize / (1024 * 1024)).toFixed(2)}MB`
                  : `${(totalSize / 1024).toFixed(2)}KB`}
                /8MB
              </p>
              <ProgressBar progress={imageSizeProgress} />
            </div>
            <span className=" overflow-auto w-full">
              <div className="flex item-center justify-center flex-col gap-3">
                {imgErrMessage && (
                  <p className="text-red-500">{imgErrMessage}</p>
                )}

                <button
                  className={`${
                    selectedImages.length > 0
                      ? "bg-[#7E57C2] text-white rounded-md h-9"
                      : "bg-[#F3ECFF] text-[#7E57C2] rounded-md h-9"
                  } `}
                  onClick={(e) => {
                    e.preventDefault();
                    openModal(0); // Set the initial index as needed
                  }}
                >
                  Preview
                </button>
              </div>
            </span>
          </form>
          <div className="rounded-lg w-full hidden md:block bg-white p-8 border border-[#E7D9FF]">
            <ProductPreview
              productName={productName}
              productDesc={productDesc}
              price={price}
              discount={discPrice}
              quantity={quantity}
              category={categoriesList}
              images={selectedImages}
            />
          </div>
        </div>
        {/* <div className="flex items-start gap-5 flex-col md:flex-row">
          <div className="rounded-lg w-full lg:w-[670px] bg-white p-8">
            <form
              action=""
              className="flex rounded gap-3 w-full add-product-form font-normal bg-white flex-col"
            >
              <h4>Product Variations</h4>

              {variations.map((variation, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between w-full mt-1 mb-5"
                >
                  <div className="flex items-center justify-start gap-1">
                    <span>{variation.name}</span>
                    <p>-</p>
                    <span>₦{variation.price}</span>
                    <p>-</p>
                    <span>{variation.quantity} units</span>
                  </div>
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    color="red"
                    onClick={() => deleteVariation(index)}
                    cursor="pointer"
                  />
                </div>
              ))}
              <div className="variation-inputs">
                <span>
                  <label htmlFor="productName"> Name</label>
                  <input
                    type="text"
                    name="productName"
                    value={newVariation.name}
                    onChange={(e) =>
                      setNewVariation({ ...newVariation, name: e.target.value })
                    }
                    placeholder="Enter Product name"
                  />
                </span>
                <div className="flex items-center justify-between w-full gap-4">
                  <span className="w-full">
                    <label htmlFor="productDesc">Price</label>
                    <input
                      type="number"
                      value={newVariation.price}
                      onChange={(e) =>
                        setNewVariation({
                          ...newVariation,
                          price: e.target.value,
                        })
                      }
                      name="price"
                      placeholder="₦"
                    />
                  </span>
                  <span className="w-full">
                    <label htmlFor="price">Inventory</label>
                    <input
                      type="number"
                      value={newVariation.quantity}
                      onChange={(e) =>
                        setNewVariation({
                          ...newVariation,
                          quantity: e.target.value,
                        })
                      }
                      name="quantity"
                      placeholder="Inventory"
                    />
                  </span>
                </div>
              </div>
              <button
                onClick={addVariation}
                className="text-white bg-[#7E57C2] p-2 w-fit rounded-lg font-normal"
              >
                Add Variation
              </button>
            </form>
          </div>
        </div> */}
        <div className="w-full justify-center flex pb-5">
          <button
            onClick={handleSave}
            className="border bg-[#5FAA58] text-white font-normal  py-2 px-3 rounded-md w-[200px]"
          >
            Save
          </button>
        </div>
      </div>
      {selectedImages.length > 0
        ? isModalOpen && (
            <ImageModal
              images={selectedImages}
              onClose={closeModal}
              currentImageIndex={currentImageIndex}
              setCurrentImageIndex={setCurrentImageIndex}
            />
          )
        : ""}
    </>
  );
}
