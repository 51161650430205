import { useEffect, useMemo, useState } from "react";
import {
  accesstokenSelector,
  currentSlug,
  setOverviewPhase,
} from "../../../lib/user/userSlice";
import { useAppSelector } from "../../../lib/hooks";
import {
  useConfirmAccountNumberMutation,
  useGetBanksQuery,
  useUpdatePaymentsDetailsMutation,
} from "../../../lib/api/settingsApi";
import { toast } from "react-toastify";
import OverLoader from "../../../components/OverLoader";
import { useNavigate, useOutletContext } from "react-router-dom";
import NextBar from "./NextBar";
import { useDispatch } from "react-redux";

const AddBankOverview = () => {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [accountName, setAccountName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [banks, setBanks] = useState([]);

  const { data } = useGetBanksQuery({ access });

  const [updatePayment, { isLoading: updating }] =
    useUpdatePaymentsDetailsMutation();
  const [confirmAccount, { data: confirmation, isLoading: confirming }] =
    useConfirmAccountNumberMutation();

  const [phase] = useOutletContext();

  useEffect(() => {
    if (data) {
      setBanks(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    if (confirmation) {
      console.log(confirmation.data.data);
      if (!confirmation.data.status) {
        setAccountName("No Account Found");
        return;
      }
      setAccountName(confirmation.data.data.account_name);
    }
  }, [confirmation]);

  const filteredBanks = useMemo(
    () =>
      banks &&
      banks.filter((bank) =>
        bank.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [banks, searchValue]
  );

  const handleSearchChange = (e) => {
    setDropdownVisible(true);
    setSearchValue(e.target.value);
  };

  const handleBankSelection = (bank) => {
    setSelectedBank(bank);
    setDropdownVisible(false);
    setSearchValue(bank.name);

    if (accountNumber.length == 10) {
      confirmAccount({
        access,
        body: {
          account_number: accountNumber,
          bank_code: bank.code,
        },
      });
    }
  };

  const handleAccountNumberInputChange = async (e) => {
    if (e.target.value.length > 10) return;
    setAccountNumber(e.target.value);
    if (e.target.value.length == 10 && selectedBank) {
      await confirmAccount({
        access,
        body: {
          account_number: e.target.value,
          bank_code: selectedBank.code,
        },
      });
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (!accountNumber) {
      toast.error("Please enter an account number");
      return;
    }
    if (accountName == "No Account Found") {
      toast.error("Enter a Valid Account");
      return;
    }
    if (!selectedBank) {
      toast.error("Please select a bank");
      return;
    }
    try {
      await updatePayment({
        access,
        slug,
        body: {
          settlement_bank: selectedBank.code,
          account_number: accountNumber,
          business_name: accountName,
        },
      });
      toast.success("Bank Details added successfully");
      navigate("/overview/go-live");
      console.log("reach here");
      dispatch(setOverviewPhase(3));
    } catch (error) {
      toast.error(error);
    }
  };

  return (
    <div className="w-full rounded-2xl  bg-white p-8  flex flex-col items-start justify-center gap-6">
      <div>
        <h2 className="text-xl font-semibold">Add Bank Details</h2>
        <p className="text-xs">
          Select from various picture options for your e-commerce website
        </p>
      </div>
      <OverLoader isLoading={updating} />
      <div className="flex w-full gap-7 md:flex-row flex-col">
        {/* <div className=" flex flex-col gap-1 w-full">
      <label htmlFor="">Amount to withdraw</label>
      <input
        type="number"
        className="p-2 h-10 rounded-md border border-[#E4E4E7]"
        placeholder="₦0000000"
      />
    </div> */}
        <div className=" flex flex-col gap-1 w-full">
          <label htmlFor="">Select Bank</label>
          <div className="relative">
            <input
              type="text"
              className="p-2 h-10 w-full rounded-md border border-[#E4E4E7]"
              placeholder="Search for a bank"
              value={searchValue}
              onChange={handleSearchChange}
            />
            {isDropdownVisible && (
              <div className="absolute max-h-[35vh] overflow-auto z-30 top-[110%] left-0 w-full bg-white border border-[#E4E4E7] rounded-md shadow-md">
                {searchValue && banks && filteredBanks.length < 1 ? (
                  <div
                    onClick={() => setDropdownVisible(false)}
                    className="p-2 cursor-pointer hover:bg-gray-200"
                  >
                    No banks found
                  </div>
                ) : (
                  filteredBanks.map((bank) => (
                    <div
                      key={bank.id}
                      className="p-2 cursor-pointer hover:bg-gray-200"
                      onClick={() => handleBankSelection(bank)}
                    >
                      {bank.name}
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className=" flex flex-col gap-1 w-full">
        <label htmlFor="">Account Number</label>
        <input
          type="number"
          value={accountNumber}
          onChange={handleAccountNumberInputChange}
          className="p-2 h-10 rounded-md border border-[#E4E4E7]"
          placeholder="*******"
        />
        <span className="w-full flex items-start justify-end text-[#7E57C2]">
          {confirming
            ? "Resolving..."
            : confirmation && !accountName
            ? "No account Found"
            : accountName}
        </span>
      </div>
      <NextBar handleSave={handleSave} phase={phase} />
    </div>
  );
};

export default AddBankOverview;
