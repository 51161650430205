// src/AutocompleteInput.js
import React, { useRef, useEffect } from "react";

const AutocompleteInput = ({ onPlaceSelected, classNames }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current, {
      componentRestrictions: { country: "ng" },
      strictBounds: false,
      types: ["establishment"]
    }
    );
    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();
      onPlaceSelected(place);
    });
  }, [onPlaceSelected]);

  return (
    <input
      ref={inputRef}
      className={classNames}
      type="text"
      placeholder="Enter a location"
    />
  );
};

export default AutocompleteInput;
