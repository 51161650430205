import { useAppSelector } from "@/lib/hooks";
import {
  userNameSelector,
  accesstokenSelector,
  currentSlug,
} from "@/lib/user/userSlice";
import { useGetDashboardDataQuery } from "@/lib/api/dashboardapi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { IoFilter } from "react-icons/io5";
import { addCommasToMonetary, formattedDate } from "@/utils/formatting";
import ProductsSkeleton from "@/app/products/components/productsSkeleton";
import { useEffect, useState } from "react";
import { useRefreshTokenMutation } from "@/lib/api/generalapi";
import HeadText from "../../../components/HeadText";
import Graph from "./components/Graph";
import MessagePopup from "../../../components/MessagePopup";
import StoreLink from "../../../components/StoreLink";

const EcommerceHome = () => {
  const username = useAppSelector(userNameSelector); // geting username from redux state
  const access = useAppSelector(accesstokenSelector); // geting accesstoken from redux state
  const slug = useAppSelector(currentSlug);
  const [refreshToken] = useRefreshTokenMutation();

  // const [graphdata, setGraphData] = useState([]);/
  const {
    data,
    isError,
    isFetching: isLoading,
    error,
  } = useGetDashboardDataQuery({
    access,
    slug,
  });
  useEffect(() => {
    if (isError && error && error.status === 401) {
      refreshToken(access);
    }
  }, [isError, error, access, refreshToken]);

  // if (isLoading) {
  //   return <Loading message="Dashboard" />;
  // }
  // if (isError) {
  //   if (error.status && error.status === 401) {
  //     return <GlobalError error={"Login Session Expired. Login again"} />;
  //   }
  //   return <GlobalError error={error} />;
  // }

  // Dasboard Data
  const {
    total_sales: totalSales,
    total_orders: totalOrders,
    avg_order_value: avgOrders,
    conversion_rate: conversionRate,
    // total_revenue: totalRevenue,
    most_sold_products: mostSoldProducts,
    latest_orders: lastestOrders,
    graph_data: graph,
  } = data?.data || {}; // destructuring data from backend api

  const RenderMostSoldProducts = () => {
    const [progressWidths, setProgressWidths] = useState(Array(6).fill(0));
    useEffect(() => {
      if (mostSoldProducts) {
        const newProgressWidths = mostSoldProducts.map(
          (product) => product.percentage
        );
        setProgressWidths(newProgressWidths);
      }
    }, []); // Added dependency array to trigger useEffect when mostSoldProducts changes

    return isError ? (
      <>
        <div>Failed to fetch data</div>
      </>
    ) : mostSoldProducts && mostSoldProducts.length === 0 ? (
      <>
        <div>
          <p>No Sales yet.</p>
        </div>
      </>
    ) : (
      <div className="flex flex-col gap-4">
        {[0, 1, 2, 3, 4, 5].map((num, index) => {
          const product = mostSoldProducts && mostSoldProducts[num];
          return (
            <div
              key={index}
              className="progress  flex flex-col text-sm gap-2 justify-start w-full"
            >
              <div className="flex w-full justify-between items-center">
                <span
                  className={`font-medium ${!product && "animate-pulse w-1/3"}`}
                >
                  {product ? (
                    product.name
                  ) : isLoading ? (
                    <div className="h-3 bg-slate-500 rounded-full"></div>
                  ) : null}
                </span>
                <span
                  className={`font-medium ${
                    !product && "animate-pulse w-1/12"
                  }`}
                >
                  {product ? (
                    `${product.percentage}%`
                  ) : isLoading ? (
                    <div className="h-3 bg-slate-500 rounded-full"></div>
                  ) : null}
                </span>
              </div>
              {!product && !isLoading ? null : (
                <div className="w-full h-3 bg-slate-200 rounded-full">
                  <div
                    style={{
                      width: `${progressWidths[num]}%`,
                      transition: "width 1s ease",
                    }}
                    className="bg-[#57C27E] transition duration-400 h-3 rounded-full"
                  ></div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const OrderTableRow = () => {
    const statusColor = (order) => {
      return order.delivery_status === "pending"
        ? "bg-[#FFCC00]"
        : order.delivery_status === "delivered"
        ? "bg-[#2EE410]"
        : order.delivery_status === "dispatched"
        ? "bg-[#7E57C2]"
        : "bg-[#FF3333]";
    };

    return lastestOrders < 1 ? (
      <>
        <tr>
          <td colSpan="7">
            <div className="flex justify-center flex-col p-10 gap-3 items-center">
              <h3 className="font-medium">You don&apos;t have any Orders</h3>
            </div>
          </td>
        </tr>
      </>
    ) : (
      lastestOrders?.map((order, index) => {
        return (
          <>
            <tr className="" key={index}>
              <td className="text-left pl-5 pt-3">
                {"#" + order.order_id.toString().padStart(5, "0")}{" "}
              </td>
              <td className="pt-3">
                <p className=" w-[160px] truncate"> {order.product}</p>
              </td>
              <td className="text-left">{formattedDate(order.created_at)}</td>
              <td className="text-left">{order.customer_name || "Not set"}</td>
              <td className="text-left first-letter:decoration-double first-letter:line-through">
                N{data && addCommasToMonetary(order.amount.toFixed(2))}
              </td>
              <td
                className={`text-left items-center justify-center text-black w-fit py-1 pr-3  mt-2 mb-2 gap-1 `}
              >
                <span
                  className={`flex py-1 px-1 rounded-lg  gap-1 items-center justify-center  ${statusColor(order)} bg-opacity-30`}
                >
                  <span
                    className={`w-2 h-2 rounded-full ${statusColor(order)}`}
                  ></span>
                  <span className="text-xs font-medium">
                    {order.delivery_status.toUpperCase()}
                  </span>
                </span>
              </td>
              {/* <td className="text-left">•••</td> */}
            </tr>
          </>
        );
      })
    );
  };

  return (
    <div className="py-8 px-4 md:px-8">
      <header className="flex flex-col gap-10 pb-2 ">
        <div>
          <HeadText className="justify-between flex-row" color="purple">
            Welcome Back,{" "}
            {username
              ? username.charAt(0).toUpperCase() + username.slice(1)
              : "user"}
          </HeadText>
          <StoreLink className="" />
        </div>
        {/* <div className="flex justify-between">
          <div className="p-1 border  border-#B1B1B1 rounded-md bg-white">
            <select className="outline-none">
              <option value="day">Today</option>
              <option value="week">This week</option>
              <option value="month">Last Month</option>
            </select>
          </div>
          <div className="flex space-x-4">
            <div className="flex items-center gap-1 pl-2 pr-2 border bg-[#e7edf0] text-[#737f87]  border-#B1B1B1 rounded-md">
              <FontAwesomeIcon icon={faCalendar} />
              <span className="ml-2">Date range</span>
            </div>
            <div className="flex items-center gap-2 pl-2 pr-2 border  border-#B1B1B1 rounded-md bg-white">
              <IoFilter />
              <span className="">Filter</span>
            </div>
          </div>
        </div> */}
      </header>

      <div className="summarized-stat grid   mt-5 grid-cols-2 lg:grid-cols-4 gap-3 sm:gap-6">
        <div className="stat">
          <div className="flex justify-between items-center">
            <p>Total Sales</p>
            <span className="group relative">
              <MessagePopup message="total sales made" />
              <FontAwesomeIcon icon={faInfoCircle} color="grey" />
            </span>
          </div>
          <p className="amount first-letter:decoration-double first-letter:line-through">
            N{data ? addCommasToMonetary(totalSales?.toFixed(2)) || 0 : 0}
          </p>
        </div>
        <div className="stat">
          <div className="flex justify-between items-center">
            <p>Total Orders</p>
          </div>
          <p className="amount">{data ? totalOrders : 0}</p>
        </div>
        <div className="stat">
          <div className="flex justify-between items-center">
            <p>Avg Order Value</p>
            <span className="group relative">
              <MessagePopup message="Average amount per order" />
              <FontAwesomeIcon icon={faInfoCircle} color="grey" />
            </span>
          </div>
          <p className="amount first-letter:decoration-double first-letter:line-through">
            N{data ? addCommasToMonetary(avgOrders.toFixed(2)) : 0}
          </p>
        </div>
        <div className="stat">
          <div className="flex justify-between items-center">
            <p>Conversion Rate</p>
            <span className="group relative">
              <MessagePopup message="Total customers on site/Total orders from site" />
              <FontAwesomeIcon icon={faInfoCircle} color="grey" />
            </span>
          </div>
          <p className="amount">{data ? conversionRate.toFixed(1) : 0}%</p>
        </div>
      </div>

      <div className="charts grid gap-6 lg:grid-cols-2 w-full  mt-5 ">
        <Graph graph={graph} />
        <div className="flex flex-col bg-white border rounded-lg  p-4 gap-5">
          <h2 className="text-lg font-medium">Most Sold Items</h2>
          <div className="flex flex-col w-full h-full gap-6">
            <RenderMostSoldProducts />
          </div>
        </div>
      </div>
      <div className="w-full border rounded-[8px] bg-white mt-5 p-5 flex flex-col gap-2 border-collapse box-border">
        <h3>Latest Orders</h3>
        <div className="w-full b overflow-auto">
          <table className=" w-[800px] lg:w-full overflow-auto">
            <thead className=" bg-[#eee]">
              <tr className="">
                <th className="text-left font-normal p-5">Order Id</th>
                <th className="text-left font-normal">Products</th>
                <th className="text-left font-normal">Date</th>
                <th className="text-left font-normal">Customer Name</th>
                <th className="text-left font-normal">Status</th>
                <th className="text-left font-normal">Price</th>
                {/* <th className="text-left font-normal">Action</th> */}
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <>
                  <ProductsSkeleton />
                  <ProductsSkeleton />
                  <ProductsSkeleton />
                  <ProductsSkeleton />
                  <ProductsSkeleton />
                  <ProductsSkeleton />
                </>
              ) : (
                <OrderTableRow />
              )}

              {isError && (
                <>
                  <tr>
                    <td colSpan="7">
                      <div className="flex justify-center flex-col p-10 gap-3 items-start sm:items-center">
                        <h3 className="font-medium">
                          Sorry, Couldnt fetch Orders
                        </h3>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EcommerceHome;
