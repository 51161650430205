import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";
const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_APP_API_BASE_URL,
});

const slug =
  Cookies.get("jellosite-current-slug") ||
  Cookies.get(import.meta.env.VITE_APP_COOKIE_SLUG);

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result.error && result.error.status === 401) {
    console.log("Error 401: Unauthorized. Please check your authentication.");
    const currentURL = encodeURI(window.location.href);
    if (import.meta.env.VITE_ENV !== "local")
      window.location.href = `${
        import.meta.env.VITE_APP_LOGIN_URL
      }?redirect=${currentURL}`;
  }

  if (result.error && result.error.status === 404 && !slug) {
    console.log("Error 404: Slug not found.");
    window.location.href = `www.jellosite.com/sites`;
  }
  return result;
};

export const generalApiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  tagTypes: [
    "User",
    "Customers",
    "Products",
    "Product",
    "Orders",
    "Order",
    "Policies",
    "Settings",
    "Checkout",
    "Shippings",
    "Billings",
    "SettingsPayment",
  ],
  endpoints: (build) => ({
    checkAccess: build.mutation({
      query: (access) => ({
        url: "/auth/token/verify/",
        method: "POST",
        body: { token: access },
      }),
    }),
    getSites: build.query({
      query: (access) => ({
        url: "/sites",
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    saveSiteDesigh: build.mutation({
      query: ({ access, body, slug }) => ({
        url: `/ecommerce/${slug}/set-business-image-colors`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    logout: build.mutation({
      query: ({ access }) => ({
        url: "/auth/logout/",
        method: "POST",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getStatesAndLga: build.query({
      query: () => ({
        url: "/ecommerce/ng/states_and_lga/",
        method: "GET",
      }),
    }),
    refreshToken: build.mutation({
      query: (access) => ({
        url: "/auth/token/refresh/",
        method: "POST",
        body: { refresh: access },
      }),
    }),
  }),
});

export const {
  useCheckAccessMutation,
  useGetStatesAndLgaQuery,
  useLogoutMutation,
  useRefreshTokenMutation,
  useGetSitesQuery,
  useSaveSiteDesighMutation,
} = generalApiSlice;
