import PropTypes from "prop-types";
import { CreateForm } from "./CreateForm";

export const Modal = ({ isOpen, setIsOpen }) => {
  return (
    <div
      className={`absolute ${
        isOpen
          ? "opacity-100 pointer-events-auto"
          : "opacity-0 pointer-events-none"
      } flex justify-center duration-150 items-center bg-black/30 top-0 left-0 w-[100vw] h-[100vh] z-50 p-2`}
    >
      <div className={`bg-white duration-150 p-3 md:p-10 z-[2000]`}>
        <CreateForm isOpen={isOpen} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};
