import "./globals.css";
import ErrorBoundary from "@/components/ErrorBoundary";
import { Suspense } from "react";
import Header from "@/components/Header";
import GenSideNav from "@/components/GenSideNav";
import StoreProvider from "./StoreProvider";
import "./reuseables/font.scss";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Loading from "./loading";
import PopupProvider from "../lib/context/PopupContext";
import GoogleMapsLoader from "../lib/config/GoogleMapsProvider";

export default function RootLayout({ children }) {
  return (
    <>
      <ToastContainer stacked={true} />
      <StoreProvider>
        <PopupProvider />
        <Header />
        <GenSideNav />
        <GoogleMapsLoader>
          <main className="ml-0 overflow-y-auto overflow-x-clip  bg-[#F8F8F8] h-[90vh] mt-[10vh] md:ml-[250px]">
            <ErrorBoundary>
              <Suspense fallback={<Loading />}>{children}</Suspense>
            </ErrorBoundary>
          </main>
        </GoogleMapsLoader>
      </StoreProvider>
    </>
  );
}
