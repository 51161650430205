import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faEllipsisVertical,
  faRefresh,
  faSearch,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { LuArrowDownToLine } from "react-icons/lu";
import { MdOutlineTune } from "react-icons/md";
import { useEffect, useMemo, useState } from "react";
import {
  useConfirmAccountNumberMutation,
  useGetBanksQuery,
  useGetPaymentsQuery,
  useUpdatePaymentsDetailsMutation,
} from "../../../lib/api/settingsApi";
import { useAppSelector } from "../../../lib/hooks";
import { accesstokenSelector, currentSlug } from "../../../lib/user/userSlice";
import { toast } from "react-toastify";
import HeadText from "../../../components/HeadText";
import { BiEdit } from "react-icons/bi";
import { formattedDate } from "@/utils/formatting";
import Loadiing from "../../../components/Loadiing";

export default function Payment() {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);

  const [isChecked, setIsChecked] = useState(false);
  const [banks, setBanks] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedBank, setSelectedBank] = useState("");
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [accountNumber, setAccountNumber] = useState("");
  const [accountName, setAccountName] = useState("");
  const [editing, setEditing] = useState(false);
  const [history, setHistory] = useState([]);

  const [searchInput, setSearchInput] = useState(false);

  function handleSearch() {
    setSearchInput(!searchInput);
  }

  const [confirmAccount, { data: confirmation, isLoading: confirming }] =
    useConfirmAccountNumberMutation();
  const [updatePayment] = useUpdatePaymentsDetailsMutation();
  const { data: jellopay, error: jellopayErr } = useGetPaymentsQuery({
    access,
    slug,
  });
  const { data } = useGetBanksQuery({ access });

  useEffect(() => {
    if (data) {
      setBanks(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    if (jellopayErr) {
      console.log(jellopayErr);
      if (jellopayErr.status == 404) {
        setEditing(true);
      }
    }
  }, [jellopayErr]);
  useEffect(() => {
    if (jellopay) {
      setAccountNumber(jellopay.account_number);
      setAccountName(jellopay.business_name);
      setHistory(jellopay.history);

      const bank = banks.find((bank) => bank.name == jellopay.settlement_bank);
      if (bank) {
        setSelectedBank(bank);
        setSearchValue(bank.name);
      }
    } else {
      return;
    }
  }, [banks, jellopay]);

  useEffect(() => {
    if (accountNumber.length < 10) {
      setAccountName("");
    }
  }, [accountNumber.length]);
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    if (confirmation) {
      console.log(confirmation.data.data);
      if (!confirmation.data.status) {
        setAccountName("No Account Found");
        return;
      }
      setAccountName(confirmation.data.data.account_name);
    }
  }, [confirmation]);
  const filteredBanks = useMemo(
    () =>
      banks &&
      banks.filter((bank) =>
        bank.name.toLowerCase().includes(searchValue.toLowerCase())
      ),
    [banks, searchValue]
  );

  const handleSearchChange = (e) => {
    setDropdownVisible(true);
    setSearchValue(e.target.value);
  };

  const handleBankSelection = (bank) => {
    setSelectedBank(bank);
    setDropdownVisible(false);
    setSearchValue(bank.name);

    if (accountNumber.length == 10) {
      confirmAccount({
        access,
        body: {
          account_number: accountNumber,
          bank_code: bank.code,
        },
      });
    }
  };

  const handleAccountNumberInputChange = (e) => {
    if (e.target.value.length == 10 && selectedBank) {
      confirmAccount({
        access,
        body: {
          account_number: e.target.value,
          bank_code: selectedBank.code,
        },
      });
    }
    if (e.target.value.length > 10) return;
    setAccountNumber(e.target.value);
  };
  const handleSave = async (e) => {
    e.preventDefault();
    if (!accountNumber) {
      toast.error("Please enter an account number");
      return;
    }
    if (!selectedBank) {
      toast.error("Please select a bank");
      return;
    }

    const response = await updatePayment({
      access,
      slug,
      body: {
        settlement_bank: selectedBank.code,
        account_number: accountNumber,
        business_name: accountName,
      },
    });

    if (data?.status == "success") setEditing(false);
    else {
      toast.error("Something went wrong");
    }
  };
  return (
    <div className="py-8 px-4 md:px-8">
      <div className="flex flex-col w-full items-start gap-6">
        <div className="flex flex-col w-full items-start gap-6">
          <div className="">
            <HeadText
              sub="You will get settled in the account details you put here"
              color="purple"
            >
              Payments
            </HeadText>
          </div>

          {editing ? (
            <form
              action=""
              className="w-full md:pr-36 pr-0 flex flex-col items-start justify-center gap-6"
            >
              <div className="flex w-full gap-7 md:flex-row flex-col">
                {/* <div className=" flex flex-col gap-1 w-full">
                <label htmlFor="">Amount to withdraw</label>
                <input
                  type="number"
                  className="p-2 h-10 rounded-md border border-[#E4E4E7]"
                  placeholder="₦0000000"
                />
              </div> */}
                <div className=" flex flex-col gap-1 w-full">
                  <label htmlFor="">Select Bank</label>
                  <div className="relative">
                    <input
                      type="text"
                      className="p-2 h-10 w-full rounded-md border border-[#E4E4E7]"
                      placeholder="Search for a bank"
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                    {isDropdownVisible && (
                      <div className="absolute max-h-[50vh] overflow-auto z-30 top-[110%] left-0 w-full bg-white border border-[#E4E4E7] rounded-md shadow-md">
                        {searchValue && banks && filteredBanks.length < 1 ? (
                          <div
                            onClick={() => setDropdownVisible(false)}
                            className="p-2 cursor-pointer hover:bg-gray-200"
                          >
                            No banks found
                          </div>
                        ) : (
                          filteredBanks.map((bank) => (
                            <div
                              key={bank.id}
                              className="p-2 cursor-pointer hover:bg-gray-200"
                              onClick={() => handleBankSelection(bank)}
                            >
                              {bank.name}
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className=" flex flex-col gap-1 w-full">
                <label htmlFor="">Account Number</label>
                <input
                  type="number"
                  value={accountNumber}
                  onChange={handleAccountNumberInputChange}
                  className="p-2 h-10 rounded-md border border-[#E4E4E7]"
                  placeholder="*******"
                />
                <span className="w-full flex items-start justify-end text-[#7E57C2]">
                  {confirming
                    ? "Resolving..."
                    : confirmation && !accountName
                    ? "No account Found"
                    : accountName}
                </span>
              </div>
              <div className="flex w-full gap-2">
                {editing && jellopay && (
                  <button
                    onClick={() => setEditing(false)}
                    className="text-white bg-red-500 p-2 w-[30%] rounded-lg font-normal"
                  >
                    Cancel
                  </button>
                )}

                <button
                  onClick={handleSave}
                  disabled={!confirmation?.data?.status}
                  className="text-white bg-[#7E57C2] p-2 w-full rounded-lg font-normal disabled:opacity-70"
                >
                  Save
                </button>
              </div>
            </form>
          ) : (
            <div className="border md:max-w-[300px] w-full font-medium relative text-xl rounded-md flex flex-col border-[#7E57C2]/60 p-3">
              <h3 className="text-2xl text-[#7E57C2]">Bank Details</h3>
              <span className="">{accountNumber}</span>
              <span>{selectedBank.name}</span>
              <span>{accountName}</span>
              <span
                onClick={() => setEditing(true)}
                className="flex text-[#7E57C2] absolute top-2 right-3 cursor-pointer"
              >
                <BiEdit size={26} />
              </span>
            </div>
          )}
        </div>
        <div className="w-full rounded-[8px] bg-white mt-5 p-4 lg:p-0 flex flex-col gap-2 border-collapse box-border overflow-x-scroll lg:overflow-clip">
          <div className=" flex justify-between w-[800px] lg:w-full items-center pl-5 pr-5 border-b border-b-[#E5E9EB] gap-16 overflow-auto">
            <div className="flex gap-6  h-12 items-center border-b justify-center border-b-[#E5E9EB">
              <h4 className=" h-full flex items-center w-[200px] justify-center pl-2 pr-2">
                Withdrawal History
              </h4>
            </div>
            <div className=" flex items-center gap-6">
              {searchInput ? (
                <div className="flex items-center gap-2">
                  <input
                    className=" border rounded-md border-gray-300 p-2 w-[250px] md:w-[300px]"
                    type="text"
                    placeholder="Search customers using ID and amount"
                  />
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    onClick={handleSearch}
                    className="cursor-pointer"
                  />
                </div>
              ) : (
                <button
                  className="h-8 w-8 rounded-md border border-gray-300 text-gray-600 flex items-center justify-center"
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              )}
              <hr className=" rotate-90 bg-[#717070] w-6 h-full" />
              {/* <button className=" ">
                <MdOutlineTune className=" rotate-90" />
              </button> */}
              <button className=" ">
                <LuArrowDownToLine />
              </button>
              {/* <button className=" ">
                <FontAwesomeIcon icon={faRefresh} />
              </button>
              <hr className=" rotate-90 bg-[gray]  w-6 h-full" />
              <div className="flex items-center gap-1 pl-2 pr-2 border bg-[#e7edf0] text-[#737f87]  border-#B1B1B1 rounded-md h-8">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="ml-2 w-[80px]">Date range</span>
              </div> */}
            </div>
          </div>

          <div className="w-full">
            <table className=" w-[800px] lg:w-full ">
              <thead className=" border-b">
                <tr className="">
                  <th className="text-left relative p-3 ">
                    <input
                      type="checkbox"
                      id="some_id"
                      className=" relative peer shrink-0 cursor-pointer appearance-none w-5 h-5 border border-[#B0BABF]  rounded-md bg-white mt-1 checked:bg-white checked:border-[#7E57C2]"
                    />

                    <svg
                      className=" absolute top-[14px] left-[14px] pointer-events-none text-[#7E57C2] w-4 h-4 mt-1 hidden peer-checked:block"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <polyline points="20 6 9 17 4 12"></polyline>
                    </svg>
                  </th>
                  <th className="text-left font-normal text-[#6E7C87]">ID</th>
                  <th className="text-left font-normal text-[#6E7C87]">
                    AMOUNT SETTLED
                  </th>
                  <th className="text-left font-normal text-[#6E7C87] ">
                    FEES
                  </th>
                  <th className="text-left font-normal text-[#6E7C87]">DATE</th>
                </tr>
              </thead>
              <tbody>
                {jellopay?.settlements?.map((payment) => (
                  <tr
                    className={`border-b ${isChecked ? "bg-[#F9F5FFCC]" : ""}`}
                  >
                    <td className="text-left relative p-3 font-medium">
                      <input
                        type="checkbox"
                        id="some_id"
                        className=" relative peer shrink-0 cursor-pointer appearance-none w-5 h-5 border border-[#B0BABF]  rounded-md bg-white mt-1 checked:bg-white checked:border-[#7E57C2]"
                        onChange={handleCheckboxChange}
                      />

                      <svg
                        className=" absolute top-[14px] left-[14px] pointer-events-none text-[#7E57C2] w-4 h-4 mt-1 hidden peer-checked:block"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="4"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <polyline points="20 6 9 17 4 12"></polyline>
                      </svg>
                    </td>
                    <td className="text-left">{payment.id}</td>

                    <td className="text-left ">
                      <p>₦{(payment.total_amount / 100).toLocaleString()}</p>
                    </td>
                    <td className="text-left">
                      ₦
                      {(
                        (payment.total_processed - payment.total_amount) /
                        100
                      ).toLocaleString()}
                    </td>
                    <td className="text-left">
                      {formattedDate(payment.settlement_date)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
