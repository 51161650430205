import { STORE_DOMAIN } from "@/utils/constants";
import { currentSlug } from "@/lib/user/userSlice";
import { useAppSelector } from "@/lib/hooks";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faCopy } from "@fortawesome/free-solid-svg-icons";

export default function StoreLink() {
    const slug = useAppSelector(currentSlug);

    const handleCopy = () => {
        navigator.clipboard.writeText(`https://${slug}.${STORE_DOMAIN}`);
        toast.success("Link copied to clipboard");
    }
    return (
        <div className="flex gap-1 items-center">
            <a
                className="text-center p-1"
                href={`https://${slug}.${STORE_DOMAIN}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {slug}.{STORE_DOMAIN}
            </a>
            <FontAwesomeIcon icon={faCopy} className="mr-2" onClick={handleCopy} />
        </div>
    )
}
