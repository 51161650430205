import { faSearch, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import { useAppSelector } from "@/lib/hooks";
import { useGetCustomerListQuery } from "@/lib/api/customersApi";
import ProductsSkeleton from "../products/components/productsSkeleton";
import CustomerRow from "./Components/CustomerRow";
import { Modal } from "./Components/Modal";
import HeadText from "../../components/HeadText";

export default function Page() {
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);

  const {
    data: customersList,
    isLoading,
    isError,
  } = useGetCustomerListQuery({ slug, access });
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage] = useState(0);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const itemsPerPage = 10;

  const [searchInput, setSearchInput] = useState(false);

  function handleSearch() {
    setSearchInput(!searchInput);
  }

  function handleSelectCustomer(customerId) {
    setSelectedCustomers((prevCustomers) => {
      if (prevCustomers.includes(customerId)) {
        return prevCustomers.filter((id) => id !== customerId);
      } else {
        return [...prevCustomers, customerId];
      }
    });
  }

  // function handleSelectAllCustomers(isChecked) {
  //   if (isChecked) {
  //     setSelectedCustomers(customersList?.map((customer) => customer.id) || []);
  //   } else {
  //     setSelectedCustomers([]);
  //   }
  // }
  return (
    <div className="py-8 px-4 md:px-8">
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} />
      <div className="flex flex-col gap-8 items-start ">
        <div className="pageheader-wrapper flex-col items-start gap-4 sm:flex-row">
          <HeadText color="purple">Customers</HeadText>
          {/* <div className="flex gap-3 w-full sm:w-fit justify-between sm:justify-center items-center">
            <input
              type="date"
              className="p-1 bcustomer bcustomer-[#B1B1B1] rounded-lg"
            />
            <span>to</span>
            <input
              type="date"
              className="p-1 bcustomer bcustomer-[#B1B1B1] rounded-lg"
            />
          </div> */}
        </div>
        <div className="flex w-full flex-col gap-1">
          <div className="flex gap-5 items-center sm:justify-start justify-between w-full">
            {/* <div className=" flex flex-wrap justify-end gap-2">
              <button
                className="bg-white pt-1 pb-1 bcustomer-[#b1b1b1] pl-2 pr-2 bcustomer rounded-md gap-2 flex items-center"
                onClick={() => setIsOpen(true)}
              >
                <IoAdd />
                Create
              </button>
              <button className="bg-white pt-1 pb-1 bcustomer-[#b1b1b1] pl-2 pr-2 bcustomer rounded-md">
                Dismiss
              </button>
              <button className="bg-white pt-1 pb-1 bcustomer-[#b1b1b1] pl-2 pr-2 bcustomer rounded-md">
                Send Invoice
              </button>
              <button className="bg-white pt-1 pb-1 bcustomer-[#b1b1b1] pl-2 pr-2 bcustomer rounded-md gap-2 flex items-center">
                <FontAwesomeIcon icon={faPen} />
                Edit
              </button>
            </div> */}
          </div>
          <div className="max-w-full rounded-[8px] bg-white mt-5 p-4 lg:p-0 flex flex-col gap-2 bcustomer-collapse box-bcustomer">
            <div className=" flex justify-between max-w-[1000px] pt-3  md:w-full items-center pl-5 gap-20 pr-5 bcustomer-b bcustomer-b-[#E5E9EB] overflow-auto">
              <div className=" flex items-center gap-4">
                {/* {searchInput ? (
                  <div className="flex items-center gap-2">
                    <input
                      className=" border rounded-md border-gray-300 p-2 w-[250px]"
                      type="text"
                      placeholder="Search customers using name, email or number"
                    />
                    <FontAwesomeIcon
                      icon={faXmarkCircle}
                      onClick={handleSearch}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <button
                    className="h-8 w-8 rounded-md border border-gray-300 text-gray-600 flex items-center justify-center"
                    onClick={handleSearch}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                )} */}
                {/* <button className=" h-8 w-8 rounded-md border border-gray-300 text-gray-600 flex items-center justify-center">
                  <HiMiniArrowsUpDown size={20} />
                </button> */}
                {/* <button
                  onClick={() => setIsOpen(true)}
                  className=" h-8 w-8 rounded-md bcustomer bcustomer-gray-[#B1B1B1] text-[#5B6871] flex items-center justify-center"
                >
                  <IoAdd />
                </button> */}
              </div>
            </div>

            <div className="w-full overflow-x-scroll lg:overflow-clip">
              <table className=" w-[500px] lg:w-full md:w-full">
                <thead className="">
                  <tr className="">
                    {/* <th className="text-left relative p-3 ">
                      <CheckBox
                        checked={
                          customersList &&
                          customersList.length === selectedCustomers.length
                        }
                        onChanger={(e) =>
                          handleSelectAllCustomers(e.target.checked)
                        }
                      />
                    </th> */}
                    <th className="text-left relative p-3 pl-5 font-normal text-[#6E7C87]">
                      Email
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      first Name
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      last Name
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      Phone
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isError ? (
                    <>
                      <tr>
                        <td colSpan="7">
                          <div className="flex justify-center flex-col p-10 gap-3 items-start sm:items-center">
                            <h3 className="font-medium">
                              Failed to Fetch Customers!
                            </h3>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : isLoading ? (
                    <>
                      <ProductsSkeleton />
                      <ProductsSkeleton />
                      <ProductsSkeleton />
                      <ProductsSkeleton />
                      <ProductsSkeleton />
                    </>
                  ) : customersList && customersList < 1 ? (
                    <>
                      <tr>
                        <td colSpan="7">
                          <div className="flex justify-center flex-col p-10 gap-3 items-center">
                            <h3 className="font-medium">
                              You don&apos;t have any Customers yet.
                            </h3>

                            {/* <button
                              onClick={() => setIsOpen(true)}
                              className="text-white font-medium bg-[#7E57C2] p-2 rounded-lg shadow-md inset-1"
                            >
                              Create a Customer
                            </button> */}
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    customersList &&
                    customersList
                      ?.slice(
                        currentPage * itemsPerPage,
                        (currentPage + 1) * itemsPerPage
                      )
                      .map((customer, index) => (
                        <>
                          <CustomerRow
                            isChecked={selectedCustomers.includes(customer.id)}
                            key={"#" + index}
                            customer={customer}
                            onClicker={() => handleSelectCustomer(customer.id)}
                          />
                        </>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* <div className="flex sm:flex-row flex-col sm:justify-between items-center gap-4 sm:gap-10 w-full">
          <span>Showing 1 to 7 of 21 entries</span>
          <ReactPaginate
            previousLabel={
              <button className="pt-1 pb-1 pl-3 pr-3 bcustomer bcustomer-[#b1b1b1] bcustomer-h rounded-md">
                Previous
              </button>
            }
            nextLabel={
              <button className="pt-1 pb-1 pl-3 pr-3 bcustomer bcustomer-[#b1b1b1] rounded-md">
                Next
              </button>
            }
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            breakLabel={<div className="ellipses">...</div>}
            pageCount={8}
            // onPageChange={handlePageChange}
            containerClassName="flex items-center gap-2"
            activeClassName="bg-[#E8DAFF] text-black rounded-md pt-1 pb-1"
            pageLinkClassName="pt-2 pb-2 pl-3 pr-3 rounded-md"
          />
        </div> */}
      </div>
    </div>
  );
}
