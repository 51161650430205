import PropTypes from "prop-types";
const CheckBox = ({ onChanger, checked }) => {
  return (
    <div onClick={onChanger} className="relative">
      <input
        checked={checked}
        type="checkbox"
        onChange={onChanger}
        className="relative peer shrink-0 cursor-pointer appearance-none w-5 h-5 border border-[#B0BABF]  rounded-md bg-white mt-1 checked:bg-white checked:border-[#7E57C2]"
      />

      <svg
        className={`absolute top-[2px] left-[3px] pointer-events-none text-[#7E57C2] w-4 h-4 mt-1 hidden peer-checked:block ${
          checked ? "block" : "hidden"
        }`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    </div>
  );
};

CheckBox.propTypes = {
  onChanger: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};
export default CheckBox;
