import { addCommasToMonetary } from "@/utils/formatting";
import { ImageCarosel } from "../../components/ImageCarosel";
import PropTypes from "prop-types";

const ProductPreview = ({
  productName,
  productDesc,
  price,
  discount,
  category,
  images,
}) => {
  return (
    <div className="w-full">
      <ImageCarosel images={images} />
      <div className="flex flex-col items-start gap-2 mt-5 ">
        <div className=" max-w-[400px] overflow-auto flex gap-3 ">
          {category &&
            category.map((cat, index) => (
              <span
                key={index}
                className="pt-1 pb-1 pl-4 pr-4 bg-[#F8F4FF] text-[#7E57C2] rounded-md mb-3"
              >
                {cat}
              </span>
            ))}
        </div>
        <h3 className={`font-medium ${!productName && "text-slate-600"}`}>
          {productName || "Add Product Name"}
        </h3>
        <div className="flex justify-center items-center gap-3">
          <h2
            className={`text-[#FF675E] font-medium ${!price && "text-red-300"}`}
          >
            {price ? `₦${addCommasToMonetary(price)}` : "Add Price"}
          </h2>
          <p className=" line-through">
            {discount ? `₦${addCommasToMonetary(discount)}` : null}
          </p>
        </div>
        <span className="text-[#71717A]">
          {productDesc || "Add Product Description....."}
        </span>
      </div>
      {/* <p>{quantity}</p> */}
    </div>
  );
};

ProductPreview.propTypes = {
  productName: PropTypes.string,
  productDesc: PropTypes.string,
  price: PropTypes.number,
  discount: PropTypes.number,
  quantity: PropTypes.number,
  category: PropTypes.array,
  images: PropTypes.array,
};

export default ProductPreview;
