import {
  useGetCheckoutQuery,
  usePatchCheckoutMutation,
} from "@/lib/api/settingsApi";
import { useState, useEffect, useMemo } from "react";
import { useAppSelector } from "@/lib/hooks";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import OverLoader from "@/components/OverLoader";
import HeadText from "../../../components/HeadText";

export default function Page() {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);
  const { data } = useGetCheckoutQuery({ access, slug });
  const [patchCheckout, { isLoading: saving }] = usePatchCheckoutMutation();
  const [contactMethod, setContactMethod] = useState("");

  const [customerInfo, setCustomerInfo] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");

  const [changes, setChanges] = useState({});
  useEffect(() => {
    if (data && data.contact_method) {
      setContactMethod(data.contact_method);
      setCustomerInfo(data.customer_info_name); // initialiazing the setting on mount after Data has arrived
      setCustomerAddress(data.customer_info_address);
    }
  }, [data, access]);

  const canSave = Boolean(Object.keys(changes).length !== 0);
  const handleContactMethod = useMemo(
    () => (value) => {
      if (contactMethod !== value) {
        setChanges((prevChanges) => ({
          ...prevChanges,
          contact_method: value,
        }));
      }
      setContactMethod(value);
    },
    [contactMethod]
  );

  const handleCustomerInfo = useMemo(
    () => (value) => {
      if (customerInfo !== value) {
        setChanges((prevChanges) => ({
          ...prevChanges,
          customer_info_name: value,
        }));
      }
      setCustomerInfo(value);
    },
    [customerInfo]
  );

  const handleCustomerAddress = useMemo(
    () => (value) => {
      if (customerAddress !== value) {
        setChanges((prevChanges) => ({
          ...prevChanges,
          customer_info_address: value,
        }));
      }
      setCustomerAddress(value);
    },
    [customerAddress]
  );

  const handleSave = async () => {
    if (!canSave) {
      return null;
    }
    await patchCheckout({
      access,
      slug,
      body: changes,
    });
    setChanges({});
  };

  return (
    <div className="py-8 px-4 md:px-8">
      <OverLoader isLoading={saving} />
      <div className="flex flex-col gap-7 items-start w-full">
        <div className="flex w-full justify-between">
          <HeadText color="purple">Checkouts</HeadText>
          <button
            onClick={handleSave}
            disabled={!canSave}
            className="shadow-md disabled:bg-white disabled:text-black text-white bg-[#7E57C2] rounded-md pl-3 pr-3 pt-1 pb-1"
          >
            Save
          </button>
        </div>
        <div className="border border-[#D0D5DD] rounded-lg pb-4 w-full bg-white">
          <p className=" border-[#D0D5DD] border-b p-2">
            Customer contact method
          </p>
          <div>
            <form>
              <p className="pl-2 pt-2 pb-2">
                Choose contact methods customers use to checkout
              </p>
              <ul className="pl-3 flex items-start justify-center gap-2 flex-col">
                <li className="flex gap-1">
                  <input
                    type="radio"
                    name="contact-method"
                    id=""
                    value="email"
                    checked={contactMethod === "email"}
                    onChange={() => handleContactMethod("email")}
                    className=" accent-[#7E57C2]"
                  />
                  Email
                </li>
                <li className="flex gap-1">
                  <input
                    type="radio"
                    name="contact-method"
                    id=""
                    checked={contactMethod === "phone_and_email"}
                    value="phone_and_email"
                    onChange={() => handleContactMethod("phone_and_email")}
                    className=" accent-[#7E57C2]"
                  />
                  Phone number or Email
                </li>
                {/* <li className="flex gap-1">
                  <input type="checkbox" className=" accent-black" />
                  Send promotional emails
                </li> */}
              </ul>
            </form>
          </div>
        </div>
        <div className="border border-[#D0D5DD] rounded-lg pb-4 w-full bg-white">
          <p className=" border-[#D0D5DD] border-b p-2">Customer Information</p>
          <div className="flex flex-col items-start gap-3">
            <form>
              <p className="pl-3 pt-2 pb-2">Full name</p>
              <ul className="pl-2 flex items-start justify-center gap-2 flex-col">
                <li className="flex gap-1">
                  <input
                    type="radio"
                    name="customerInfo"
                    checked={customerInfo === "only_lastname"}
                    onChange={() => handleCustomerInfo("only_lastname")}
                    id=""
                    className=" accent-[#7E57C2]"
                  />
                  Only require Last name
                </li>
                <li className="flex gap-1">
                  <input
                    type="radio"
                    name="customerInfo"
                    checked={customerInfo === "first_and_lastname"}
                    onChange={() => handleCustomerInfo("first_and_lastname")}
                    className=" accent-[#7E57C2]"
                  />
                  Require first and last name
                </li>
              </ul>
            </form>
            <form>
              <p className="pl-2 pt-1 pb-2">Address</p>
              <ul className="pl-2 flex items-start justify-center gap-2 flex-col">
                <li className="flex gap-1">
                  <input
                    type="radio"
                    name="customerAddress"
                    checked={customerAddress === "exclude"}
                    onChange={() => handleCustomerAddress("exclude")}
                    className=" accent-[#7E57C2]"
                  />
                  Exclude
                </li>
                <li className="flex gap-1">
                  <input
                    type="radio"
                    name="customerAddress"
                    checked={customerAddress === "optional"}
                    onChange={() => handleCustomerAddress("optional")}
                    className=" accent-[#7E57C2]"
                  />
                  Optional
                </li>
                <li className="flex gap-1">
                  <input
                    type="radio"
                    checked={customerAddress === "include"}
                    onChange={() => handleCustomerAddress("include")}
                    name="customerAddress"
                    className=" accent-[#7E57C2]"
                  />
                  Required
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
