import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentSite: {},
  sites: [],
};

const storeSetupSlice = createSlice({
  name: "storesetup",
  initialState,
  reducers: {},
});

export const { setSites } = storeSetupSlice.actions;
export const sitesSelector = (state) => state.storeSetup.sites;
export default storeSetupSlice.reducer;
