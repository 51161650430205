import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { useAppSelector } from "../../lib/hooks";
import {
  overviewPhaseSelector,
  setOverviewPhase,
} from "../../lib/user/userSlice";

const OverView = () => {
  const navigate = useNavigate();
  const phase = useAppSelector(overviewPhaseSelector);

  useEffect(() => {
    if (phase == 1) {
      navigate("/overview");
    } else if (phase == 2) {
      navigate("/overview/bank-details");
    } else if (phase == 3) {
      navigate("/overview/site-design");
    } else {
      navigate("/overview/go-live");
    }
  }, [navigate, phase]);

  return (
    <div className="flex flex-col gap-8 items-start py-8 px-4 md:px-8">
      <div className="text-[#5E5B62]">
        <p>Welcome, First things first...</p>
        <h2 className="text-2xl font-medium">Finish setting up your store!</h2>
      </div>
      <div className={`flex w-full justify-evenly items-center`}>
        <div className={`flex flex-col items-center gap-1`}>
          <div
            className={` border-2 duration-500 w-10 h-10 justify-center items-center flex rounded-full ${
              phase == 1
                ? "border-[#7E57C2] text-[#7e57c2]"
                : phase > 1
                ? "bg-[#7E57C2] rotate-[360deg] drop-shadow-[0_3px_6px_#7E57C2] border-none text-white"
                : "border-[#A1AEBE]"
            }`}
          >
            {phase > 1 ? <TiTick size={25} /> : "01"}
          </div>
          <span className="text-xs font-medium hidden md:block text-center">
            Add Product
          </span>
        </div>

        <span
          className={`min-w-1 mx-2 text-nowrap overflow-hidden ${
            phase > 0 && "text-[#7e57c2]"
          }`}
        >
          . . . . . . . . .
        </span>
        <div className={`flex relative flex-col items-center gap-1`}>
          <div
            className={` border-2 w-10 h-10 duration-500 justify-center items-center flex rounded-full ${
              phase == 2
                ? "border-[#7E57C2] text-[#7e57c2]"
                : phase > 2
                ? "bg-[#7E57C2] -rotate-[360deg] drop-shadow-[0_3px_6px_#7E57C2] border-none text-white"
                : "border-[#A1AEBE]"
            }`}
          >
            {phase > 2 ? <TiTick size={25} /> : "02"}
          </div>
          <span className="text-xs hidden md:block font-medium text-center">
            Add Bank account
          </span>
        </div>

        <span
          className={`min-w-1 mx-2 text-nowrap overflow-hidden ${
            phase > 1 && "text-[#7e57c2]"
          }`}
        >
          . . . . . . . . .
        </span>

        <div className={`flex flex-col items-center gap-1`}>
          <div
            className={` border-2 w-10 h-10 duration-500 justify-center items-center flex rounded-full ${
              phase == 3
                ? "border-[#7E57C2] text-[#7e57c2]"
                : phase > 3
                ? "bg-[#7E57C2] -rotate-[360deg] drop-shadow-[0_3px_6px_#7E57C2] border-none text-white"
                : "border-[#A1AEBE]"
            }`}
          >
            {phase > 3 ? <TiTick size={25} /> : "03"}
          </div>
          <span className="text-xs hidden md:block font-medium text-center">
            Add Store Details
          </span>
        </div>

        <span
          className={`min-w-1 mx-2 text-nowrap overflow-hidden ${
            phase > 1 && "text-[#7e57c2]"
          }`}
        >
          . . . . . . . . .
        </span>

        <div className={`flex flex-col items-center gap-1`}>
          <div
            className={` border-2 w-10 h-10 justify-center items-center flex rounded-full ${
              phase >= 4
                ? "bg-[#7E57C2] drop-shadow-[0_3px_6px_#7E57C2] border-none text-white"
                : "border-[#A1AEBE]"
            }`}
          >
            04
          </div>
          <span className="text-xs hidden md:block font-medium text-center">
            Go live
          </span>
        </div>
      </div>
      <Outlet context={[phase, setOverviewPhase]} />
    </div>
  );
};

export default OverView;
