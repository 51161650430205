import { generalApiSlice } from "./generalapi";

export const orderApiSlice = generalApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrderList: build.query({
      providesTags: ["Orders"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/orders/list`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getSingleOrder: build.query({
      providesTags: ["Order"],
      query: ({ access, slug, id }) => ({
        url: `ecommerce/${slug}/orders/${id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    editOrder: build.mutation({
      invalidatesTags: ["Orders", "Order"],
      query: ({ access, slug, id, body }) => ({
        url: `ecommerce/${slug}/orders/edit/${id}/`,
        method: "PATCH",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    createOrder: build.mutation({
      invalidatesTags: ["Orders"],
      query: ({ access, slug, body }) => ({
        url: `ecommerce/${slug}/orders/create/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
  }),
});

export const {
  useGetOrderListQuery,
  useCreateOrderMutation,
  useLazyGetSingleOrderQuery,
  useEditOrderMutation,
} = orderApiSlice;
