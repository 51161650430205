import PropTypes from "prop-types";

const ImagePreview = ({ src, image }) => {
  return (
    <img
      src={src || image}
      alt={""}
      width={0}
      height={0}
      className="h-full w-full object-cover"
    />
  );
};

ImagePreview.propTypes = {
  src: PropTypes.string,
  image: PropTypes.string,
};
export default ImagePreview;
