import CheckBox from "@/components/CheckBox";
import PropTypes from "prop-types";
import { addCommasToMonetary, formattedDate } from "@/utils/formatting";

const OrderRow = ({ order, isChecked, key, onClicker }) => {
  let statusColor;

  if (order.delivery_status === "pending") {
    statusColor = "bg-[#FFCC00]";
  } else if (order.delivery_status === "delivered") {
    statusColor = "bg-[#2EE410]";
  } else if (order.delivery_status === "dispatched") {
    statusColor = "bg-[#7E57C2]";
  } else if (order.delivery_status === "unpaid") {
    statusColor = "bg-[#a1a1a1]";
  } else {
    statusColor = "bg-[#FF3333]";
  }

  function truncateAddress(address) {
    if (!address) return null;
    const result = address.split(",").join(",").trim();
    return result.length > 20 ? result.slice(0, 20) + "..." : result
  }

  return (
    <>
      <tr
        className={` text-left border-b py-6 cursor-pointer ${isChecked ? "bg-[#F9F5FFCC]" : ""
          }`}
        onClick={onClicker}
        key={key}
      >
        <td className="relative text-left p-3">
          <CheckBox checked={isChecked} onChanger={onClicker} />
        </td>
        <td className="text-left">
          {"#" + order.order_id.toString().padStart(5, "0")}
        </td>
        <td className="text-left px-5">{order.customer || "Not set"}</td>
        <td className="text-left">{order.items_count}</td>
        <td className="text-left truncate  px-4 w-20">
          {truncateAddress(order.address) || "Not set"}
        </td>
        <td className="text-left">{formattedDate(order.created_at)}</td>
        <td className="text-left">
          ₦{addCommasToMonetary(order.total_price.toFixed(2))}
        </td>
        <td
          className={`text-left items-center justify-center text-black w-fit py-1 pr-3  mt-2 mb-2 gap-1 `}
        >
          <span
            className={`flex py-1 px-1 rounded-lg  gap-1 items-center justify-center  ${statusColor} bg-opacity-30`}
          >
            <span className={`w-2 h-2 rounded-full ${statusColor}`}></span>
            <span className="text-xs font-medium">
              {order.delivery_status.toUpperCase()}
            </span>
          </span>
        </td>
      </tr>
    </>
  );
};

OrderRow.propTypes = {
  order: PropTypes.object,
  isChecked: PropTypes.bool,
  key: PropTypes.number,
  onClicker: PropTypes.func,
};

export default OrderRow;
