import Loading from "@/app/loading";
import CheckBox from "@/components/CheckBox";
import { useLazyGetProductListQuery } from "@/lib/api/productApi";
import { useAppSelector } from "@/lib/hooks";
import { currentSlug } from "@/lib/user/userSlice";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { HiMinus } from "react-icons/hi";
import { IoArrowBack } from "react-icons/io5";
import { MdCancel } from "react-icons/md";

export const CreateForm = ({ isOpen, setIsOpen }) => {
  const slug = useAppSelector(currentSlug);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [adress, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [sendMail, setSendMail] = useState(false);

  const formStateSetter = (setter) => (e) => setter(e.target.value);
  const [getProductList, { data: products, isFetching }] =
    useLazyGetProductListQuery();
  const [selectedProducts, setSelectedProducts] = useState([]);
  const handleQuantityChange = (index, value) => {
    const updatedProducts = [...selectedProducts];

    if (
      value === "inc" &&
      updatedProducts[index].quantity < updatedProducts[index].product.inventory
    ) {
      updatedProducts[index].quantity += 1;
    } else if (value === "dec" && updatedProducts[index].quantity > 1) {
      updatedProducts[index].quantity -= 1;
    } else if (
      !isNaN(value) &&
      value >= 0 &&
      value <= updatedProducts[index].product.inventory
    ) {
      updatedProducts[index].quantity = parseInt(value); // Ensure value is parsed to an integer
    }

    setSelectedProducts(updatedProducts);
  };

  const handleRemoveProducts = (index) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts.splice(index, 1);
    setSelectedProducts(updatedProducts);
  };

  const handleSave = () => {
    const items = selectedProducts.map((products) => ({
      product: products.products.id,
      quantity: products.product.quantity,
    }));
    const body = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
      items,
      shipping_details: {
        customer_name: customerName,
        address: adress,
        city,
        state,
        zip_code: zipcode,
        country,
      },
      send_mail: sendMail,
    };
  };
  useEffect(() => {
    if (isOpen) {
      getProductList({ slug });
    }
  }, [getProductList, isOpen, slug]);

  return (
    <form
      className="w-full flex relative z-0 flex-col gap-5 space-y-0.5"
      onSubmit={handleSave}
    >
      <div className="flex w-full justify-between">
        <div className="flex gap-2 items-center">
          <span onClick={() => setIsOpen(false)} className="cursor-pointer">
            <IoArrowBack size={25} />
          </span>
          <h3 className="font-medium">Create Order</h3>
        </div>
      </div>
      <div className="overflow-y-scroll scrollbar-hide flex flex-col gap-8 items-start justify-start">
        <div className="flex flex-col gap-4">
          <div className="flex items-center justify-center gap-2">
            <h4 className="font-medium bg-white relative z-10 min-w-fit p-1 text-jello-purple">
              User Details
            </h4>
            <hr className="w-full relative -z-5 h-1" />
          </div>
          <div className=" w-full gap-3">
            <div className="flex col-span-3 flex-col">
              <label className="text-sm font-medium" htmlFor="">
                Email
              </label>
              <input
                value={email}
                required
                onChange={(e) => formStateSetter(setEmail)(e)}
                placeholder="example@mail.oc"
                className="border rounded-md p-2"
                type="text"
              />
            </div>
            <div className="w-full grid grid-cols-2 md:grid-cols-3 gap-3">
              <div className=" w-full flex flex-col">
                <label className="text-sm font-medium" htmlFor="">
                  First Name
                </label>
                <input
                  value={firstName}
                  required
                  onChange={(e) => formStateSetter(setFirstName)(e)}
                  placeholder="*John"
                  className="border rounded-md p-2"
                  type="text"
                />
              </div>
              <div className=" w-full flex flex-col">
                <label className="text-sm font-medium" htmlFor="">
                  Last Name
                </label>
                <input
                  placeholder="Doe"
                  value={lastName}
                  required
                  onChange={(e) => formStateSetter(setLastname)(e)}
                  className="border rounded-md  p-2"
                  type="text"
                />
              </div>
              <div className=" w-full flex flex-col">
                <label className="text-sm font-medium" htmlFor="">
                  Phone Number
                </label>
                <input
                  required
                  value={phone}
                  onChange={(e) => formStateSetter(setPhone)(e)}
                  placeholder="*909300222"
                  className="border rounded-md p-2"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full flex flex-col gap-4">
          <div className="flex items-center justify-center gap-2">
            <h4 className="font-medium bg-white relative z-10 min-w-fit p-1 text-jello-purple">
              Shipping details
            </h4>
            <hr className="w-full relative -z-5 h-1" />
          </div>

          <div className=" w-full grid grid-cols-2 md:grid-cols-3 gap-3">
            <div className=" w-full flex flex-col">
              <label className="text-sm font-medium" htmlFor="">
                Customer Name
              </label>
              <input
                required
                value={customerName}
                onChange={(e) => formStateSetter(setCustomerName)(e)}
                placeholder="*John"
                className="border rounded-md p-2"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium" htmlFor="">
                Address
              </label>
              <input
                required
                value={adress}
                onChange={(e) => formStateSetter(setAddress)(e)}
                placeholder="5 Duck Town"
                className="border rounded-md  p-2"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium" htmlFor="">
                City
              </label>
              <input
                required
                value={city}
                onChange={(e) => formStateSetter(setCity)(e)}
                placeholder="New york"
                className="border rounded-md p-2"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium" htmlFor="">
                State
              </label>
              <input
                required
                value={state}
                onChange={(e) => formStateSetter(setState)(e)}
                placeholder="Lagos"
                className="border rounded-md p-2"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium" htmlFor="">
                Country
              </label>
              <input
                required
                value={country}
                onChange={(e) => formStateSetter(setCountry)(e)}
                placeholder="*Nigeria"
                className="border rounded-md p-2"
                type="text"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm font-medium" htmlFor="">
                Zip code
              </label>
              <input
                value={zipcode}
                onChange={(e) => formStateSetter(setZipcode)(e)}
                required
                placeholder="*909300222"
                className="border rounded-md p-2"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col gap-2 my-2">
          {selectedProducts.length > 0 && (
            <>
              <div className="flex gap-2 flex-col w-full">
                <h3>Selected</h3>

                {selectedProducts?.map(
                  ({ product, image, quantity }, index) => (
                    <div
                      className="flex w-full justify-between items-center bg-slate-500/35 px-2 py-1 rounded-sm"
                      key={index}
                    >
                      <div className="flex">
                        {image && (
                          <img
                            src={image}
                            width={30}
                            height={30}
                            alt={product.name}
                          />
                        )}
                        <h3 className="font-medium">{product.name}</h3>
                      </div>
                      <div className="flex ju w-1/4 items-center">
                        <span
                          className="cursor-pointer"
                          onClick={() => handleQuantityChange(index, "dec")}
                        >
                          <HiMinus size={25} />
                        </span>
                        <input
                          type="number"
                          value={quantity}
                          className="w-[40px] focus:border bg-transparent [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none p-1"
                          onChange={(e) =>
                            handleQuantityChange(index, e.target.value)
                          }
                        />
                        <span
                          className="cursor-pointer"
                          onClick={() => handleQuantityChange(index, "inc")}
                        >
                          <BsPlus size={25} />
                        </span>
                        <span
                          onClick={() => handleRemoveProducts(index)}
                          className="cursor-pointer"
                        >
                          <MdCancel size={25} />
                        </span>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}

          <div name="products" id="">
            {isFetching ? (
              <Loading />
            ) : products && products.length > 0 ? (
              <Dropdown
                selectedOptions={selectedProducts}
                setSelectedOptions={setSelectedProducts}
                options={products}
              />
            ) : (
              <div>No products found</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center font-medium">
        <CheckBox checked={sendMail} onChanger={() => setSendMail(!sendMail)} />
        <label htmlFor="send_mail">Send email to customer</label>
      </div>

      <button
        type="submit"
        className="py-1 px-3 rounded-sm  bg-jello-purple text-white"
      >
        Save
      </button>
    </form>
  );
};

CreateForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const Dropdown = ({ options, setSelectedOptions, selectedOptions }) => {
  const [isOpen, setIsOpen] = useState(false);
  //   const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOption = (option) => {
    const index = selectedOptions.findIndex(
      (item) => item.product.id === option.id
    );

    if (index !== -1) {
      const updatedOptions = [...selectedOptions];
      updatedOptions[index].quantity += 1;
      setSelectedOptions(updatedOptions);
    } else {
      setSelectedOptions((prev) => [
        ...prev,
        {
          product: option,
          image: option.images.length > 0 ? undefined : option.images[0].image,
          quantity: 1,
        },
      ]);
    }
  };

  const handleOptionClick = (option) => {
    toggleOption(option);
  };

  return (
    <div className="relative z-0">
      <div
        className="border border-gray-300 font-medium rounded-md p-1 cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        Select Products
      </div>

      <div
        className={`absolute overflow-y-scroll w-full duration-200 top-full z-30 bg-white border border-t-0 rounded-t-none border-gray-300 rounded-md mt-1 ${
          isOpen
            ? "translate-y-0"
            : " translate-y-[10px] max-h-0 opacity-0 pointer-events-none"
        }`}
      >
        {options.map((option, index) => (
          <div
            key={index}
            onClick={() => handleOptionClick(option)}
            className="py-2 px-3 gap-3 flex cursor-pointer"
          >
            {option.images.length > 0 && (
              <img
                src={option.images[0].image}
                width={30}
                height={30}
                alt={option.name}
              />
            )}
            <div>
              <h3 className="font-medium"> {option.name}</h3>
              <p className="text-xs">{option.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  setSelectedOptions: PropTypes.func.isRequired,
};
