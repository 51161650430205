import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./user/userSlice";
import { composeWithDevTools } from "@redux-devtools/extension";
import { generalApiSlice } from "./api/generalapi";
import storeSetupReducer from "./storeSetup/storeSetupSlice";
import templateReducer from "./templates/templateSlice";
import disableStoreReducer from "./storeSetup/disableStore/disableStoreSlice";
export const makeStore = () => {
  return configureStore(
    {
      reducer: {
        user: userReducer,
        template: templateReducer,
        storeSetup: storeSetupReducer,
        storeStatus: disableStoreReducer,
        [generalApiSlice.reducerPath]: generalApiSlice.reducer,
      },
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(generalApiSlice.middleware),
    },
    composeWithDevTools({})
  );
};
