import { useEffect, useState } from "react";
import { useAppSelector } from "@/lib/hooks";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import {
  useGetPoliciesQuery,
  usePatchPoliciesMutation,
} from "@/lib/api/settingsApi";
import Policy from "./comp/Policies";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../loading";
import { STORE_DOMAIN } from "@/utils/constants";

export default function Page() {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);
  const { data, isLoading, isError } = useGetPoliciesQuery({ access, slug });
  const [patchPolicies] = usePatchPoliciesMutation();
  const [editedPolicyContent, setEditedPolicyContent] = useState({
    privacy_policy: "",
    shipping_policy: "",
    terms_and_conditions: "",
    refund_policy: "",
  });

  useEffect(() => {
    if (data) {
      setEditedPolicyContent({
        privacy_policy: data.privacy_policy || "",
        shipping_policy: data.shipping_policy || "",
        terms_and_conditions: data.terms_and_conditions || "",
        refund_policy: data.refund_policy || "",
      });
    }
  }, [data]);

  const handleInputChange = (value, policyKey) => {
    setEditedPolicyContent((prevContent) => ({
      ...prevContent,
      [policyKey]: value,
    }));
  };

  const handleSave = (policyKey) => {
    // Prepare the body for the API request
    const body = { [policyKey]: editedPolicyContent[policyKey] };

    patchPolicies({ access, body, slug });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="flex flex-col gap-10 py-8 px-4 md:px-8">
      <div className="flex flex-col gap-6">
        <h2 className="font-medium">Privacy Policies</h2>
        <p className="text-sm">
          <FontAwesomeIcon icon={faGlobe} />{" "}
          <a href={`https://${slug}.${STORE_DOMAIN}`}>
            {slug}.{STORE_DOMAIN}/policies
          </a>
        </p>
        <Policy
          title="Privacy Policy"
          content={editedPolicyContent.privacy_policy}
          onSave={() => handleSave("privacy_policy")}
          onChange={(text) => handleInputChange(text, "privacy_policy")}
        />
        <hr className="h-2 md:w-[80%] w-full" />
        <Policy
          title="Shipping Policy"
          content={editedPolicyContent.shipping_policy}
          onSave={() => handleSave("shipping_policy")}
          onChange={(text) => handleInputChange(text, "shipping_policy")}
        />
        <hr className="h-2 md:w-[80%] w-full" />
        <Policy
          title="Terms and Conditions"
          content={editedPolicyContent.terms_and_conditions}
          onSave={() => handleSave("terms_and_conditions")}
          onChange={(text) => handleInputChange(text, "terms_and_conditions")}
        />
      </div>
      <hr className="h-2 md:w-[80%] w-full" />
      <div className="flex flex-col gap-6">
        <Policy
          title="Refund Policy"
          content={editedPolicyContent.refund_policy}
          onSave={() => handleSave("refund_policy")}
          onChange={(text) => handleInputChange(text, "refund_policy")}
        />
      </div>
    </div>
  );
}
