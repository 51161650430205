import {
  faAngleLeft,
  faAngleRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

const ImageModal = ({
  images,
  onClose,
  currentImageIndex,
  setCurrentImageIndex,
}) => {
  const handlePrevClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : images.length - 1
    );
  };

  const handleNextClick = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex < images.length - 1 ? prevIndex + 1 : 0
    );
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 gap-7 z-25 md:z-50">
      <div className="max-w-3xl w-full mx-auto p-4 relative">
        <button
          className="absolute top-2 right-2 text-white  bg-slate-300 rounded-[50%] h-9 w-9 z-20"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faXmark} />
        </button>
        <div className=" w-full flex items-center justify-between relative">
          <img
            src={images[currentImageIndex]?.src || images[currentImageIndex]?.image}
            width={0}
            height={0}
            alt={images[currentImageIndex]?.alt}
            className="w-full h-[250px] sm:h-[450px]"
          />
          <div className="flex items-center justify-between w-full absolute">
            <button
              className="text-white bg-slate-300 rounded-[50%] h-10 w-10"
              onClick={handlePrevClick}
            >
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
            <button
              className="text-white bg-slate-300 rounded-[50%] h-9 w-9"
              onClick={handleNextClick}
            >
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

ImageModal.propTypes = {
  images: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  currentImageIndex: PropTypes.number.isRequired,
  setCurrentImageIndex: PropTypes.func.isRequired,
};

export default ImageModal;
