import { generalApiSlice } from "./generalapi";

export const settingsApiSlice = generalApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getGenSettings: build.query({
      // query current General Settings
      providesTags: ["Settings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    patchGenSettings: build.mutation({
      invalidateTags:["Settings"],
      query: ({ access, body, slug }) => ({
        url: `ecommerce/${slug}/settings/`,
        method: "PATCH",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    patchCheckout: build.mutation({
      invalidateTags:["Settings", "Checkout"],
      query: ({ access, body, slug }) => ({
        url: `ecommerce/${slug}/settings/checkout/update/`,
        method: "PATCH",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getCheckout: build.query({
      providesTags:["Settings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/checkout`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getPolicies: build.query({
      providesTags: ["Policies"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/policy`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    patchPolicies: build.mutation({
      invalidatesTags: ["Policies"],
      query: ({ access, body, slug }) => ({
        url: `ecommerce/${slug}/settings/policy/update/`,
        method: "PATCH",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getShippings: build.query({
      providesTags: ["Shippings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/shipping`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    patchShippings: build.mutation({
      invalidatesTags: ["Shippings"],
      query: ({ access, body, slug }) => ({
        url: `ecommerce/${slug}/settings/shipping/`,
        method: "PATCH",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getBillings: build.query({
      providesTags: ["Billings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/billings`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    checkBillings: build.query({
      providesTags: ["Billings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/billings/check`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    disableBillings: build.query({
      invalidatesTags: ["Billings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/billings/disable`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    enableSubscription: build.query({
      invalidatesTags:["Billings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/billings/enable`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    updateCard: build.query({
      invalidatesTags:["Billings"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/billings/update-card`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getPayments: build.query({
      providesTags: ["SettingsPayment"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/settings/jellopay`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    updatePaymentsDetails: build.mutation({
      invalidatesTags: ["SettingsPayment"],
      query: ({ access, body, slug }) => ({
        url: `ecommerce/${slug}/settings/jellopay/update/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    getBanks: build.query({
      query: ({ access }) => ({
        url: `/jellopay/banks`,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    confirmAccountNumber: build.mutation({
      query: ({ access, body }) => ({
        url: `/jellopay/confirm-account/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
  }),
});

export const {
  useGetGenSettingsQuery,
  usePatchCheckoutMutation,
  useGetCheckoutQuery,
  usePatchGenSettingsMutation,
  useGetPoliciesQuery,
  usePatchPoliciesMutation,
  useGetShippingsQuery,
  usePatchShippingsMutation,
  useLazyGetBillingsQuery,
  useCheckBillingsQuery,
  useLazyDisableBillingsQuery,
  useGetPaymentsQuery,
  useUpdatePaymentsDetailsMutation,
  useGetBanksQuery,
  useConfirmAccountNumberMutation,
  useLazyEnableSubscriptionQuery,
  useLazyUpdateCardQuery,
} = settingsApiSlice;
