import { createContext, memo, useCallback, useEffect, useState } from "react";
import { BiX } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { overviewPhaseSelector } from "../user/userSlice";

const PopupContext = createContext();

// export const usePopup = () => useContext(PopupContext);

// this popup is not extensible. so mind the use
const UnMemoizedPopupProvider = () => {
  const [content, setContent] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const [acceptText, setAcceptText] = useState("Yes");
  const [canceled, setcanceled] = useState(false);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;

  const overviewPhase = useAppSelector(overviewPhaseSelector);

  const showPopup = useCallback(
    ({ content, buttonText }) => {
      console.log("i got here", pathname);
      if (pathname.includes("overview")) {
        console.log(pathname, "here");
        setIsVisible(false);
        return;
      }
      if (overviewPhase > 0 && overviewPhase < 3 && !canceled) {
        setIsVisible(true);
        setContent(content);
      } else {
        setIsVisible(false);
      }
      if (buttonText) {
        setAcceptText(buttonText);
      }
    },
    [canceled, overviewPhase]
  );

  useEffect(() => {
    if (pathname.includes("overview")) {
      setIsVisible(false);
    }
  }, [pathname]);

  useEffect(() => {
    console.log(overviewPhase, "effec");
    if (pathname.includes("overview")) {
      setIsVisible(false);
      return;
    }
    showPopup({
      content:
        "We noticed you haven't completed the overiew. Would like to continue?",
      buttonText: "Continue",
    });
  }, [navigate, overviewPhase, pathname, showPopup]);

  const acceptFunctionForOverviewPopup = useCallback(() => {
    if (overviewPhase == 1) {
      navigate("/overview");
    } else if (overviewPhase == 2) {
      navigate("/overview/bank-details");
    } else if (overviewPhase == 3) {
      navigate("/overview/go-live");
    }
  }, [navigate, overviewPhase]);

  const onAccept = () => {
    acceptFunctionForOverviewPopup();
    setIsVisible(false);
  };
  const onCancel = () => {
    setIsVisible(false);
    setcanceled(true);
  };

  return (
    <PopupContext.Provider>
      <div
        className={`absolute border z-50 shadow-xl p-4 duration-right-0 md:right-10 bg-white w-full md:w-[340px]  md:rounded-md ${
          isVisible
            ? "bottom-0 md:bottom-10 "
            : "bottom-[20%] opacity-0 pointer-events-none"
        }`}
      >
        <span
          onClick={onCancel}
          className="absolute cursor-pointer right-1 top-1"
        >
          <BiX size={25} />
        </span>
        <p className="flex">{content || "Are you sure want to continue"}</p>
        <div className="flex w-full gap-3 text-base mt-2 justify-end">
          <button
            className="bg-slate-400 text-white px-4 py-1 rounded"
            onClick={onCancel}
          >
            No
          </button>
          <button
            className="bg-[#7E57C2] text-white px-4 py-1 rounded "
            onClick={onAccept}
          >
            {acceptText}
          </button>
        </div>
      </div>
    </PopupContext.Provider>
  );
};

const PopupProvider = memo(UnMemoizedPopupProvider);
export default PopupProvider;
