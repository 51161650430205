import axios from "axios";
import Cookies from "js-cookie";

const base_url = import.meta.env.VITE_APP_API_BASE_URL;

const checkifActive = async (slug) => {
  try {
    const response = await axios.get(`${base_url}/ecommerce/${slug}/active/`);
    return response.data;
  } catch (error) {
    console.error("Error checking status:", error);
    throw error;
  }
};

const disableStoreService = {
  checkifActive,
};

export default disableStoreService;
