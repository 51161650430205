function ProductsSkeleton() {
  return (
    <tr
      className={`border-b animate-pulse prodrow opacity-30 pointer-events-none transition-all duration-200 `}
    >
      <td className="text-left relative p-3 font-medium"></td>
      <td className="text-left">
        <div className="w-2/3 h-4 rounded-full bg-slate-950/20"></div>
      </td>
      <td className="">
        <div className="w-2/3 h-4 rounded-full bg-slate-950/20"></div>
      </td>
      <td className="text-left">
        <div className="w-2/3 h-4 rounded-full bg-slate-950/20"></div>
      </td>
      <td className="text-left">
        <div className="w-2/3 h-4 rounded-full bg-slate-950/20"></div>
      </td>
      <td className="text-left bg-[#2EE41033] flex items-center text-black w-fit py-1 px-2 rounded-lg mt-2 mb-2 gap-1">
        <div className="w-2/3 h-4 rounded-full bg-slate-950/20"></div>
      </td>
      <td className="text-left ">
        <span className="flex gap-3">
          <div className="w-2/3 h-4 rounded-full bg-slate-950/20"></div>
          {/* <FontAwesomeIcon icon={faTrashAlt} />
          <FontAwesomeIcon icon={faPencilAlt} /> */}
        </span>
      </td>
    </tr>
  );
}
export default ProductsSkeleton;
