import Logo from "@/assets/JellositeLogoandWordmark05.png";
import { Link } from "react-router-dom";
import { useAppSelector } from "@/lib/hooks";
import { currentSlug } from "@/lib/user/userSlice";
import { useState, useEffect } from "react";
import { useGetSitesQuery } from "../lib/api/generalapi";
import {
  accesstokenSelector,
  checkCurrentSiteSubscription,
  setCurrentSlug,
} from "../lib/user/userSlice";
import { useAppDispatch } from "../lib/hooks";
import { setSites } from "../lib/user/userSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faGlobe, faWarning } from "@fortawesome/free-solid-svg-icons";
import { setOverviewPhase } from "@/lib/user/userSlice";
import {
  reset,
  storeState,
} from "../lib/storeSetup/disableStore/disableStoreSlice";
import { useSelector } from "react-redux";

const Header = () => {
  // const username = useSelector(userNameSelector);
  // const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const access = useAppSelector(accesstokenSelector);
  const currentSlugValue = useAppSelector(currentSlug);
  const { data } = useGetSitesQuery(access);
  const [currentSiteData, setCurrentSiteData] = useState(null);

  // const [slug, setSlug] = useState("");
  // const _slug = useAppSelector(currentSlug);

  // useEffect(() => {
  //   setSlug(_slug);
  // }, [_slug]);

  useEffect(() => {
    if (data) {
      dispatch(setSites(data));
      const currentSite = data.find((site) => site.slug === currentSlugValue);
      setCurrentSiteData(currentSite);
      if (currentSite) {
        dispatch(setOverviewPhase(currentSite.overview_phase));
      }
    }
  }, [currentSlugValue, data, dispatch]);

  // useEffect(() => {
  //   if (data) {
  //     dispatch(setSites(data));
  //     dispatch(checkCurrentSiteSubscription());
  //     const findCurrentSite = data.find((x) => x.slug == _slug);
  //     console.log(_slug, findCurrentSite);
  //     setCurrentSiteData(findCurrentSite);
  //     dispatch(setOverviewPhase(findCurrentSite.overview_phase));
  //   }
  // }, [_slug, data, dispatch]);

  useEffect(() => {
    dispatch(storeState(currentSlugValue));
    dispatch(reset());
    dispatch(checkCurrentSiteSubscription());
  }, [currentSlugValue, dispatch]);

  const statusData = useSelector((state) => state.storeStatus.status.active);

  return (
    <div className="flex h-[10vh] flex-col justify-center items-center absolute top-0 w-full  bg-white border-b border-b-[#C5C5C5] ">
      <header className=" w-full flex flex-col gap-1 items-center z-10">
        {statusData == false && (
          <div className="px-2 sm:px-5 flex items-center justify-start gap-2 bg-yellow-200 w-full">
            <FontAwesomeIcon icon={faWarning} />
            <p>
              Your subscription has expired.{" "}
              <Link to="settings/billings" className=" underline">
                Renew it
              </Link>{" "}
            </p>
          </div>
        )}
        <div className="flex w-full justify-between items-center px-5 pb-3">
          <div className="flex gap-2 items-center">
            {/* <button
            className={`menu-icon lg:hidden flex items-center relative ${
              open
                ? "duration-300 rotate-[360deg]"
                : " duration-300 -rotate-[360deg]"
            }`}
            onClick={() => setOpen(!open)}
          >
            {open ? <RiMenu2Line size={28} /> : <IoMdClose size={28} />}
          </button> */}
            <Link to="/">
              <img src={Logo} width={120} height={0} className="" />
            </Link>
          </div>
          {/* <div className=" hidden gap-2 items-center w-[35%] p-2 rounded-[8px] bg-[#F6F6F6] h-[35px] border border-[#C5C5C5] md:flex">
          <FontAwesomeIcon width={20} icon={faSearch} />
          <input
            className="w-[100%] bg-[#F6F6F6] outline-none"
            type="text"
            placeholder="Search transactions, orders and customers"
          />
        </div> */}
          <div className="flex relative group w-auto items-center gap-3 ">
            <p className="hidden md:flex "> </p>
            <div className="avatar cursor-pointer">
              {currentSiteData && currentSiteData.business_image ? (
                <img
                  className="w-full object-cover rounded-full object-center h-full"
                  src={currentSiteData.business_image}
                  alt=""
                />
              ) : (
                currentSlugValue?.charAt(0).toUpperCase()
              )}
            </div>
            <div className="absolute group-hover:flex hidden top-[100%] border shadow right-0 z-[999]">
              <ul className="flex flex-col  bg-white gap-3 z-[999] w-[200px]">
                {data &&
                  data.map((sites) => (
                    <li
                      onClick={() => {
                        dispatch(checkCurrentSiteSubscription());
                        dispatch(setCurrentSlug(sites.slug));
                        dispatch(setOverviewPhase(sites.overview_phase));
                      }}
                      className="py-1 px-3 cursor-pointer hover:bg-slate-100"
                      key={sites.slug}
                    >
                      <FontAwesomeIcon icon={faGlobe} /> {sites.name}
                    </li>
                  ))}
                <hr />
                <Link to="/settings">
                  <li className="py-1 mb-2 px-3 cursor-pointer hover:bg-slate-100">
                    <FontAwesomeIcon icon={faGear} /> Settings
                  </li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
