import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import RootLayout from "./app/layout";
import Home from "./app/Home";
import Products from "./app/products/page";
import Orders from "./app/orders/page";
import Customers from "./app/customers/page";
import Settings from "./app/settings/page";
import Billings from "./app/settings/billings/page";
import Checkouts from "./app/settings/checkouts/page";
import Policies from "./app/settings/policies/page";
import Payments from "./app/settings/payments/page";
import Templates from "./app/templates/page";
import ProductForm from "./app/products/ProductForm/page";
import OverView from "./app/overview/page";
import AddProductsOverview from "./app/overview/Components/AddProducts";
import AddBankOverview from "./app/overview/Components/AddBankOverview";
import GOLive from "./app/overview/Components/GoLive";
import AddStoreDetails from "./app/overview/Components/AddStoreDetails";
// import AddPolicesOverView from "./app/overview/Components/AddPoliciesOverview";

function App() {
  return (
    <Router>
      <RootLayout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/overview" element={<OverView />}>
            <Route index element={<AddProductsOverview />} />
            <Route path="bank-details" element={<AddBankOverview />} />
            <Route path="site-design" element={<AddStoreDetails />} />
            <Route path="go-live" element={<GOLive />} />
          </Route>
          <Route path="/products" element={<Products />} />
          <Route path="/products/:id" element={<ProductForm />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/customers" element={<Customers />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/payments" element={<Payments />} />
          <Route path="/settings/checkouts" element={<Checkouts />} />
          <Route path="/settings/policies" element={<Policies />} />
          <Route path="/settings/billings" element={<Billings />} />
          <Route path="/templates" element={<Templates />} />
          <Route path="/customers" element={<Home />} />
        </Routes>
      </RootLayout>
    </Router>
  );
}

export default App;
