// Function to add commas to monetary digits
export function addCommasToMonetary(amount) {
  // Check if amount is null or undefined
  if (amount == null) return "";

  // Convert amount to a number
  const numAmount = parseFloat(amount);

  // Check for million and billion
  if (numAmount >= 1e9) {
    return (numAmount / 1e9).toFixed(2) + "B";
  } else if (numAmount >= 1e6) {
    return (numAmount / 1e6).toFixed(2) + "M";
  }

  // Convert amount to string and split integer and decimal parts
  const parts = numAmount.toString().split(".");
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? "." + parts[1] : "";

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Return the formatted amount
  return integerPart + decimalPart;
}
export function ensureNonNegative(input) {
  // Parse the input value to a float
  let value = parseFloat(input);

  // If the value is NaN, set it to zero
  if (isNaN(value)) value = 0;
  else if (value < 0) value = -value;

  return value.toString();
}

export function formattedDate(date) {
  const dateObject = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  // Extracting the parts we need
  const dateString = dateObject.toLocaleDateString("en-US", options);
  const timeString = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });

  // Combining date and time into desired format
  return `${timeString} ${dateString}`;
}

export function titleCase(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function truncateWithElipses(text, maxLength) {
  if (!text) return "";
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength - 3) + "...";
}