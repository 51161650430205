import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

export default function NextBar({ phase, handleSave }) {
  return (
    <div className="flex justify-between items-center w-full">
      <span>Page {phase} of 4</span>
      <div className="flex gap-2">
        <button onClick={handleSave} className="border flex items-center  py-1 px-3 rounded-md">
          <IoIosArrowBack /> Previous
        </button>
        <button onClick={handleSave} className="border flex items-center text-white bg-[#7E57C2] py-1 px-3 rounded-md">
          Next
          <IoIosArrowForward />
        </button>
      </div>
    </div>
  );
}
