import PropTypes from "prop-types";

const CustomerRow = ({ customer, isChecked, key, onClicker }) => {
  return (
    <>
      <tr
        className={` text-left border-b cursor-pointer ${
          isChecked ? "bg-[#F9F5FFCC]" : ""
        }`}
        onClick={onClicker}
        key={key}
      >
        {/* <td className="relative text-left p-3">
          <CheckBox checked={isChecked} onChanger={onClicker} />
        </td> */}
        {/* <td className="text-left">{"#" + order.order_id.toString().padStart(5, '0')}</td> */}
        <td className="text-left p-3 pl-5">{customer.email || "Not set"}</td>
        <td className="text-left">{customer.first_name || "Not set"}</td>
        <td className="text-left">{customer.last_name || "Not set"}</td>
        <td className="text-left">{customer.phone || "Not set"}</td>
      </tr>
    </>
  );
};

CustomerRow.propTypes = {
  customer: PropTypes.object,
  isChecked: PropTypes.bool,
  key: PropTypes.number,
  onClicker: PropTypes.func,
};

export default CustomerRow;
