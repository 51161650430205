import { useEffect } from "react";
import { useOutletContext, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt, faCopy } from "@fortawesome/free-solid-svg-icons";
import StoreLink from "@/components/StoreLink";
import { currentSlug } from "@/lib/user/userSlice";
import { useAppSelector } from "@/lib/hooks";
import { STORE_DOMAIN } from "@/utils/constants";


const GOLive = () => {
  const [, setPhase] = useOutletContext();
  const slug = useAppSelector(currentSlug);

  useEffect(() => {
    setPhase(4);
  }, [setPhase]);
  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check out my store website!",
          text: "You are just a button away from your dream website!",
          url: `https://${slug}.${STORE_DOMAIN}`,
        });
        console.log("Content shared successfully");
      } catch (error) {
        console.error("Error sharing content:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <div className="w-full border rounded-2xl  bg-white p-8  flex flex-col items-center justify-center gap-3">
      <h3 className="text-xl font-medium">You store is Live!</h3>
      <StoreLink />
      <button
        className="border font-medium flex items-center text-white bg-[#7E57C2] py-2 px-4  rounded-md"
        onClick={handleShare}
      >
        <FontAwesomeIcon icon={faShareAlt} className="mr-2" />
        Share
      </button>
      <Link to="/" className="text-sm mt-5">
        Continue to dashboard
      </Link>
    </div>
  );
};

export default GOLive;
