import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineStorefront } from "react-icons/md";
import { PiHandCoinsBold } from "react-icons/pi";
import { LuShoppingCart } from "react-icons/lu";
import { IoIosArrowBack } from "react-icons/io";
import { TiDocumentText } from "react-icons/ti";
import { MdPayment } from "react-icons/md";
import PropTypes from "prop-types";

const SettingSideNav = ({ close }) => {
  // Receive router from props
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const eccomerceSettingsLinks = [
    {
      path: "/settings",
      icon: <MdOutlineStorefront />,
      label: "Store Details",
    },
    {
      path: "/settings/payments",
      icon: <PiHandCoinsBold />,
      label: "JelloPay",
    },
    {
      path: "/settings/checkouts",
      icon: <LuShoppingCart />,
      label: "Checkouts",
    },
    // { path: "/settings/shipping", icon: <FiTruck />, label: "Shipping" },
    {
      path: "/settings/policies",
      icon: <TiDocumentText />,
      label: "Policies",
    },
    {
      path: "/settings/billings",
      icon: <MdPayment />,
      label: "Billings",
    },
  ];

  return (
    <div className="text-sm md:p-3 pt-3  absolute gap-3 z-[999] bg-white left-[60px] flex-col pad w-[190px] flex">
      <span
        onClick={() => {
          console.log("Cons");
          navigate(-1);
        }}
      >
        <h5 className="text-xl flex cursor-pointer items-center font-medium">
          <IoIosArrowBack /> Settings
        </h5>
      </span>

      <div className="flex gap-3 font-medium flex-col">
        {eccomerceSettingsLinks.map((item) => (
          <Link
            key={item.path}
            to={item.path}
            className={`${
              pathname === item.path && "bg-[#7e57c21a] text-[#7e57c2]"
            } hover:bg-[#7e57c21a] hover:text-[#7e57c2] flex items-center gap-3 p-2 rounded-md `}
            onClick={close}
          >
            {item.icon}
            <span>{item.label}</span>
          </Link>
        ))}
      </div>
    </div>
  );
};

SettingSideNav.propTypes = {
  close: PropTypes.func.isRequired,
};
export default SettingSideNav;
