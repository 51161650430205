import { generalApiSlice } from "./generalapi";

export const dashboardApiSlice = generalApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getDashboardData: build.query({
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/dashboard`,
        method: "GET",
        headers: {
          // "Referrer-Policy": "no-referrer",
          Authorization: `Bearer ${access}`, // Include the access token in the Authorization header
          "Content-Type": "application/json", // Use the appropriate content type
        },
      }),
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApiSlice;
