import axios from "axios";
import Cookies from "js-cookie";

const base_url = import.meta.env.VITE_APP_API_BASE_URL;

const access = Cookies.get("jellosite-authToken") || Cookies.get(import.meta.env.VITE_APP_COOKIE_TOKEN);
const slug = Cookies.get("jellosite-current-slug") || Cookies.get(import.meta.env.VITE_APP_COOKIE_SLUG);

const getAllTemplates = async () => {
  try {
    const response = await axios.get(
      `${base_url}/themes/${slug}/get-templates/`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching templates:", error);
    throw error;
  }
};

const getCurrentTheme = async () => {
  try {
    const response = await axios.get(
      `${base_url}/themes/${slug}/get-current-theme/`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching current theme: ", error);
    throw error;
  }
};

const updateCurrentTheme = async (template_slug) => {
  try {
    const response = await axios.patch(
      `${base_url}/themes/${slug}/theme/${template_slug}`,
      { current: true },
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching current theme: ", error);
    throw error;
  }
};

const editTheme = async (template_slug) => {
  try {
    const response = await axios.get(
      `${base_url}/themes/${slug}/use-template/${template_slug}`,
      {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching current theme: ", error);
    throw error;
  }
};

const templateService = {
  getAllTemplates,
  getCurrentTheme,
  updateCurrentTheme,
  editTheme,
};

export default templateService;
