import ReactPaginate from "react-paginate";
import { faSearch, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo, useState } from "react";
import { HiMiniArrowsUpDown } from "react-icons/hi2";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import { useAppSelector } from "@/lib/hooks";
import { useGetOrderListQuery } from "@/lib/api/ordersApi";
import CheckBox from "@/components/CheckBox";
import ProductsSkeleton from "../products/components/productsSkeleton";
import OrderRow from "./Components/OrderRow";
import { Modal } from "./Components/Modal";
import { IoAdd } from "react-icons/io5";
import HeadText from "../../components/HeadText";
import { CreateForm } from "./Components/CreateForm";
import OrderInfo from "./Components/OrderInfo";

export default function Page() {
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);
  const [modalContent, setModalContent] = useState("info");
  const [selectedOrder, setSelectedOrder] = useState(null);
  const {
    data: ordersList,
    isLoading,
    isError,
    refetch,
  } = useGetOrderListQuery({ slug, access });
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [searchInput, setSearchInput] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("All");
  const itemsPerPage = 10;

  const filteredByTab = useMemo(() => {
    if (!ordersList) return [];
    if (selectedTab === "All") return ordersList;

    return ordersList.filter(
      (order) => order.delivery_status === selectedTab.toLowerCase()
    );
  }, [ordersList, selectedTab]);

  const filteredOrders = useMemo(() => {
    if (!filteredByTab || !searchQuery.trim()) return filteredByTab;
    return filteredByTab.filter((order) => {
      const orderIdMatch = order.order_id
        .toString()
        .includes(searchQuery.trim());
      const customerMatch = order.customer
        ?.toLowerCase()
        .includes(searchQuery.trim().toLowerCase());
      return orderIdMatch || customerMatch;
    });
  }, [filteredByTab, searchQuery]);

  function handleSearch() {
    setSearchInput(!searchInput);
  }

  // function handleSelectOrder(orderId) {
  //   setSelectedOrders((prevOrders) => {
  //     if (prevOrders.includes(orderId)) {
  //       return prevOrders.filter((id) => id !== orderId);
  //     } else {
  //       return [...prevOrders, orderId];
  //     }
  //   });
  // }

  function handleSelectAllOrders(isChecked) {
    if (isChecked) {
      setSelectedOrders(ordersList?.map((order) => order.id) || []);
    } else {
      setSelectedOrders([]);
    }
  }
  return (
    <div className="py-8 px-4 md:px-8">
      <Modal
        content={
          modalContent == "create" ? (
            <CreateForm
              refetch={refetch}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          ) : (
            modalContent == "info" && (
              <OrderInfo
                id={selectedOrder}
                isOpen={isOpen}
                refetch={refetch}
                setIsOpen={setIsOpen}
              />
            )
          )
        }
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
      <div className="flex flex-col gap-8 items-start ">
        <div className="pageheader-wrapper flex-col items-start gap-4 sm:flex-row">
          <HeadText color="purple">Orders</HeadText>
          {/* <div className="flex gap-3 w-full sm:w-fit justify-between sm:justify-center items-center">
            <input
              type="date"
              className="p-1 border border-[#B1B1B1] rounded-lg"
            />
            <span>to</span>
            <input
              type="date"
              className="p-1 border border-[#B1B1B1] rounded-lg"
            />
          </div> */}
        </div>
        <div className="flex w-full flex-col gap-1">
          <div className="flex gap-5 items-center sm:justify-start justify-between w-full">
            <span>1 item selected</span>
            <div className=" flex flex-wrap justify-end gap-2">
              <button
                className="bg-white pt-1 pb-1 border-[#b1b1b1] pl-2 pr-2 border rounded-md gap-2 flex items-center"
                onClick={() => {
                  setModalContent("create");
                  setIsOpen(true);
                }}
              >
                <IoAdd />
                Create
              </button>
              {/* <button className="bg-white pt-1 pb-1 border-[#b1b1b1] pl-2 pr-2 border rounded-md">
                Dismiss
              </button>
              <button className="bg-white pt-1 pb-1 border-[#b1b1b1] pl-2 pr-2 border rounded-md">
                Send Invoice
              </button> */}
              {/* <button className="bg-white pt-1 pb-1 border-[#b1b1b1] pl-2 pr-2 border rounded-md gap-2 flex items-center">
                <FontAwesomeIcon icon={faPen} />
                Edit
              </button> */}
            </div>
          </div>
          <div className="max-w-full rounded-[8px] bg-white mt-5 p-4 lg:p-0 flex flex-col gap-2 border-collapse box-border">
            <div className=" flex justify-between max-w-full  md:w-full items-center pl-5 gap-20 pr-5 border-b border-b-[#E5E9EB] overflow-auto">
              <div className="flex gap-6 items-center">
                {["All", "Unpaid", "Delivered", "Pending", "Cancelled"].map(
                  (tab) => (
                    <span
                      key={tab}
                      className={`cursor-pointer ${
                        selectedTab === tab
                          ? "border-b-2 border-black"
                          : "text-gray-500"
                      }`}
                      onClick={() => setSelectedTab(tab)}
                    >
                      {tab}
                    </span>
                  )
                )}
              </div>
              <div className=" flex items-center gap-4">
                {searchInput ? (
                  <div className="flex items-center gap-2">
                    <input
                      className="border rounded-md border-gray-300 p-2"
                      type="text"
                      placeholder={`Search ${selectedTab.toLowerCase()} orders`}
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={faXmarkCircle}
                      onClick={() => {
                        setSearchQuery("");
                        handleSearch();
                      }}
                      className="cursor-pointer"
                    />
                  </div>
                ) : (
                  <button
                    className="h-8 w-8 rounded-md border border-gray-300 text-gray-600 flex items-center justify-center"
                    onClick={handleSearch}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                )}
                {/* <button className=" h-8 w-8 rounded-md border border-gray-[#B1B1B1] text-[#5B6871] flex items-center justify-center">
                  <HiMiniArrowsUpDown size={20} />
                </button> */}
                {/* <button
                  onClick={() => setIsOpen(true)}
                  className=" h-8 w-8 rounded-md border border-gray-[#B1B1B1] text-[#5B6871] flex items-center justify-center"
                >
                  <IoAdd />
                </button> */}
              </div>
            </div>

            <div className="w-full overflow-x-scroll lg:overflow-clip">
              <table className=" w-[1115px] lg:w-full ">
                <thead className=" border-b">
                  <tr className="">
                    <th className="text-left relative p-3 ">
                      <CheckBox
                        checked={
                          filteredOrders &&
                          filteredOrders.length > 1 &&
                          filteredOrders.length === selectedOrders.length
                        }
                        onChanger={(e) =>
                          handleSelectAllOrders(e.target.checked)
                        }
                      />
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]  ">
                      Order Id
                    </th>
                    <th className="text-left px-5 font-normal text-[#6E7C87]">
                      Customer
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      Items
                    </th>
                    <th className="text-left px-5 font-normal text-[#6E7C87]">
                      Address
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      Date
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      Total
                    </th>
                    <th className="text-left font-normal text-[#6E7C87]">
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isError ? (
                    <>
                      <tr>
                        <td colSpan="7">
                          <div className="flex justify-center flex-col p-10 gap-3 items-start sm:items-center">
                            <h3 className="font-medium">
                              Failed to Fetch Orders!
                            </h3>
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : isLoading ? (
                    <>
                      <ProductsSkeleton />
                    </>
                  ) : filteredOrders && filteredOrders.length < 1 ? (
                    <>
                      <tr>
                        <td colSpan="7">
                          <div className="flex justify-center flex-col p-10 gap-3 items-center">
                            {selectedTab == "All" ? (
                              <>
                                <h3 className="font-medium">
                                  You don&apos;t have any Orders yet.
                                </h3>

                                <button
                                  onClick={() => {
                                    setModalContent("create");
                                    setIsOpen(true);
                                  }}
                                  className="text-white font-medium bg-[#7E57C2] p-2 rounded-lg shadow-md inset-1"
                                >
                                  Create An Order
                                </button>
                              </>
                            ) : (
                              <h3 className="font-medium">
                                You Have no {selectedTab} Orders
                              </h3>
                            )}
                          </div>
                        </td>
                      </tr>
                    </>
                  ) : (
                    filteredOrders
                      ?.slice(
                        currentPage * itemsPerPage,
                        (currentPage + 1) * itemsPerPage
                      )
                      .map((order) => (
                        <>
                          <OrderRow
                            isChecked={selectedOrders.includes(order.id)}
                            key={
                              "#" + order.order_id.toString().padStart(5, "0")
                            }
                            order={order}
                            onClicker={() => {
                              setModalContent("info");
                              setSelectedOrder(order.id);
                              setIsOpen(true);
                            }}
                          />
                        </>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="flex sm:flex-row flex-col sm:justify-between items-center gap-4 sm:gap-10 w-full">
          <span>
            Showing {currentPage * itemsPerPage + 1} to{" "}
            {Math.min(
              (currentPage + 1) * itemsPerPage,
              filteredOrders?.length || 0
            )}{" "}
            of {filteredOrders?.length || 0} entries
          </span>
          <ReactPaginate
            previousLabel={
              <button className="pt-1 pb-1 pl-3 pr-3 border border-[#b1b1b1] rounded-md">
                Previous
              </button>
            }
            nextLabel={
              <button className="pt-1 pb-1 pl-3 pr-3 border border-[#b1b1b1] rounded-md">
                Next
              </button>
            }
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            breakLabel={<span className="ellipses">...</span>}
            pageCount={Math.ceil((filteredOrders?.length || 0) / itemsPerPage)}
            onPageChange={(page) => {
              setCurrentPage(page.selected);
            }}
            containerClassName="flex items-center gap-2"
            activeClassName="bg-[#E8DAFF] text-black rounded-md pt-1 pb-1"
            pageLinkClassName="pt-2 pb-2 pl-3 pr-3 rounded-md"
          />
        </div>
      </div>
    </div>
  );
}
