import { useState, useRef, Suspense, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPencilAlt,
  faSearch,
  faTrashAlt,
  faXmark,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  useGetProductListQuery,
  useDeleteProductMutation,
} from "@/lib/api/productApi";
import { useAppSelector } from "@/lib/hooks";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import ReactPaginate from "react-paginate";
import { addCommasToMonetary } from "@/utils/formatting";
import gsap from "gsap";
import { Flip } from "gsap/all";
import { useGSAP } from "@gsap/react";
import ProductsSkeleton from "./components/productsSkeleton";
import PreviewDetails from "./components/PreviewDetails";
import CheckBox from "@/components/CheckBox";
import { ConfirmModal } from "./components/ConfirmModal";
import HeadText from "../../components/HeadText";
import OverLoader from "../../components/OverLoader";
import noImage from "../../assets/no-image.jpg";

gsap.registerPlugin(useGSAP);
gsap.registerPlugin(Flip);

export default function Page() {
  const [productId, setProductId] = useState("");
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);
  const [tobeDeleted, setTobeDeleted] = useState("");
  const [searchInput, setSearchInput] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  function handleSearch() {
    setSearchInput(!searchInput);
  }

  function handleProductDets(id) {
    setProductId(id);
  }

  const {
    data: products,
    isFetching,
    refetch,
    isError,
  } = useGetProductListQuery({ slug });
  const [deleteProduct, { isLoading: deleting }] = useDeleteProductMutation();
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [confirmDel, setConfrimDel] = useState(false);
  const itemsPerPage = 10;

  async function handleDeleteModal() {
    try {
      console.log(tobeDeleted);
      await deleteProduct({ access, id: tobeDeleted, slug });
      // Refetch the product list data after deletion
      await refetch();
    } catch (error) {
      console.log(error);
    } finally {
      setConfrimDel(false);
    }
  }

  const handlePageChange = ({ selected }) => setCurrentPage(selected);

  const handleSelectProduct = (productId) => {
    const updateSelected = [...selectedProducts];
    if (updateSelected.includes(productId)) {
      const filteredSelected = updateSelected.filter((id) => id !== productId);
      setSelectedProducts(filteredSelected);
    } else {
      setSelectedProducts([...updateSelected, productId]);
    }
  };

  const handleSelectAllProducts = (isChecked) => {
    if (isChecked) {
      setSelectedProducts(products?.map((product) => product.id) || []);
    } else {
      setSelectedProducts([]);
    }
  };

  const handleDeleteMultiple = async () => {
    if (selectedProducts.length <= 0) return null;

    try {
      for (let id of selectedProducts) {
        await deleteProduct({ access, id, slug });
      }
      // Refetch the product list data after deletion
      await refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const tableRef = useRef(null);
  useGSAP(() => {
    if (!products) return;
    const state = Flip.getState(".prodrow");
    Flip.from(state, {
      duration: 4,
    });
  }, [products]);

  useEffect(() => {
    if (location.state?.refetch && !isFetching && products) {
      refetch();
      navigate("/products", { replace: true, state: {} });
    }
  }, [location.state, isFetching, products, refetch, navigate]);

  const ProductRows = () =>
    products && products.length < 1 ? (
      <>
        <tr>
          <td colSpan="7">
            <div className="flex justify-center flex-col p-10 gap-3 items-center">
              <h3 className="font-medium">You don&apos;t have any products </h3>
              <Link to="/products/new">
                <button className="text-white font-medium bg-[#7E57C2] p-2 rounded-lg shadow-md inset-1">
                  Add Product +
                </button>
              </Link>
            </div>
          </td>
        </tr>
      </>
    ) : (
      products &&
      products
        ?.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
        .map((product) => (
          <tr
            key={product.id}
            className={`border-b transition-all duration-200 cursor-pointer ${
              selectedProducts.includes(product.id) ? "bg-[#F9F5FFCC]" : ""
            }`}
          >
            <td className="text-left relative p-3 font-medium">
              <CheckBox
                onChanger={() => handleSelectProduct(product.id)}
                checked={selectedProducts.includes(product.id)}
              />
            </td>
            <td>
              {product.images[0] ? (
                <img
                  width={40}
                  height={40}
                  src={product.images[0].image}
                  alt={product.name}
                />
              ) : (
                <img src={noImage} width={40} height={40} />
              )}
            </td>
            <td
              onClick={() => handleProductDets(product.id)}
              className="text-left hover:underline"
            >
              <p>{product.name}</p>
            </td>
            <td className="text-left text-[#FF675E]">
              ₦{addCommasToMonetary(product.price)}
            </td>
            <td className="text-left">{product.inventory}</td>
            <td className="text-left bg-[#2EE41033] flex items-center text-black w-fit py-1 px-2 rounded-lg mt-2 mb-2 gap-1">
              {product.is_active ? "Active" : "Inactive"}
            </td>
            <td className="text-left ">
              <span className="flex gap-3">
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  onClick={() => {
                    setConfrimDel(true);
                    setTobeDeleted(product.id);
                  }}
                />
                <Link to={`${product.id}`}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Link>
              </span>
            </td>
          </tr>
        ))
    );

  return (
    <div className="flex flex-col gap-8 items-start relative z-0 py-8 px-4 md:px-8">
      <OverLoader isLoading={deleting} />
      <div className="pageheader-wrapper">
        <HeadText color="purple">Products</HeadText>
        <Link to="/products/new">
          <button className="text-white font-medium bg-[#7E57C2] p-2 rounded-lg shadow-md inset-1">
            Add Product +
          </button>
        </Link>
      </div>
      <div className="flex w-full flex-col gap-1">
        {/* <div className="flex gap-3 items-center">
          <span>{selectedProducts.length} item(s) selected</span>
          <button className="bg-white pt-1 pb-1 border-[#b1b1b1] pl-2 pr-2 border rounded-md">
            Dismiss
          </button>
          <button className="bg-white pt-1 pb-1 border-[#b1b1b1] pl-2 pr-2 border rounded-md">
            Edit
          </button>
        </div> */}
        <div className="w-full rounded-[8px] bg-white mt-5 p-5 md:p-0 flex flex-col gap-2 border-collapse box-border">
          <div className=" flex justify-between max-w-full overflow-auto items-center pl-5 pr-5 border-b gap-20 border-b-[#E5E9EB]">
            <div className="flex gap-6 h-12 items-center border-b justify-center border-b-[#E5E9EB]">
              <span className=" h-full border-b border-b-black flex items-center justify-center pl-2 min-w-[85px]">
                All Products
              </span>
              {/* <span>Draft</span>
              <span>Published</span> */}
            </div>
            <div className=" flex items-center gap-4">
              {searchInput ? (
                <div className="flex items-center gap-2">
                  <input
                    className="rounded-sm border border-gray-300 p-2"
                    type="text"
                    placeholder="Search orders using ID and customer"
                  />
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    onClick={handleSearch}
                    className="cursor-pointer"
                  />
                </div>
              ) : (
                <button
                  className="h-8 w-8 rounded-md border border-gray-300 text-gray-600 flex items-center justify-center"
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              )}

              {/* <button className=" h-8 w-8 rounded-md border border-gray-[#B1B1B1] text-[#5B6871] flex items-center justify-center">
                <HiMiniArrowsUpDown size={20} />
              </button> */}
              <button>
                <FontAwesomeIcon
                  onClick={handleDeleteMultiple}
                  icon={faTrashAlt}
                />
              </button>
            </div>
          </div>
          <div
            className="w-full overflow-x-scroll lg:overflow-clip"
            ref={tableRef}
          >
            <table className="w-[1115px] lg:w-full">
              <thead className="border-b">
                <tr>
                  <th className="text-left relative p-3">
                    <CheckBox
                      checked={selectedProducts.length === products?.length}
                      onChanger={(e) =>
                        handleSelectAllProducts(e.target.checked)
                      }
                    />
                  </th>
                  <th></th>
                  <th className="text-left font-normal text-[#84919A]">
                    Product name
                  </th>
                  <th className="text-left font-normal text-[#84919A]">
                    Price
                  </th>
                  <th className="text-left font-normal text-[#84919A]">
                    Stock
                  </th>
                  <th className="text-left font-normal text-[#84919A]">
                    Status
                  </th>
                  <th className="text-left font-normal text-[#84919A]">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="">
                {isFetching ? (
                  <>
                    <ProductsSkeleton />
                    <ProductsSkeleton />
                    <ProductsSkeleton />
                    <ProductsSkeleton />
                    <ProductsSkeleton />
                    <ProductsSkeleton />
                    <ProductsSkeleton />
                  </>
                ) : (
                  <ProductRows />
                )}

                {isError && (
                  <div className="w-full font-medium text-[16px]">
                    Could&apos;nt fetch products
                  </div>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {products && products.length > itemsPerPage && (
          <div className="flex mt-5 justify-between items-center w-full flex-col md:flex-row gap-5">
            <span>
              Showing {currentPage * itemsPerPage + 1} to{" "}
              {(currentPage + 1) * itemsPerPage} of {products?.length} Products
            </span>
            <ReactPaginate
              previousLabel={
                <button className="pt-1 pb-1 pl-3 pr-3 border border-[#b1b1b1] border-h rounded-md">
                  Previous
                </button>
              }
              nextLabel={
                <button className="pt-1 pb-1 pl-3 pr-3 border border-[#b1b1b1] rounded-md">
                  Next
                </button>
              }
              breakLabel={<div className="ellipses">...</div>}
              pageCount={Math.ceil((products?.length || 0) / itemsPerPage)}
              onPageChange={handlePageChange}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              containerClassName="flex items-center gap-2"
              activeClassName="bg-[#7E57C2] text-white rounded-md pt-1 pb-1"
              pageLinkClassName="pt-2 pb-2 pl-3 pr-3 rounded-md"
            />
          </div>
        )}
      </div>
      {productId && (
        <div className="absolute inset-0 flex items-center justify-center h-screen  bg-gray-700 bg-opacity-50 z-50 h- overflow-hidden">
          <div className="absolute p-2 justify-center items-center z-50  ">
            <div className=" relative bg-white flex flex-col gap-6 p-6 rounded-xl w-[320px] z-50  sm:w-[500px] max-h-[500px] sm:max-h-[800px] format-scroll overflow-y-auto scrollbar-thin scrollbar-webkit">
              <div className="w-full flex justify-end absolute top-5 right-5">
                <FontAwesomeIcon
                  icon={faXmark}
                  size="lg"
                  cursor="pointer"
                  onClick={() => handleProductDets()}
                />
              </div>
              <Suspense fallback={<div>Loading</div>}>
                <PreviewDetails
                  deleteAction={() => {
                    setConfrimDel(true);
                    setTobeDeleted(productId);
                    setProductId(null);
                  }}
                  id={productId}
                />
              </Suspense>
            </div>
          </div>
        </div>
      )}
      {confirmDel && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-700 bg-opacity-50 !z-50">
          <div className="w-fit absolute p-3">
            <ConfirmModal
              onAccept={handleDeleteModal}
              message="Are you sure you want to delete this Product?"
              close={() => setConfrimDel(false)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
