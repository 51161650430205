import {
  faCalendar,
  faRefresh,
  faSearch,
  faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import visa from "@/assets/visa.svg";
import master from "@/assets/MasterCard.svg";
import verve from "@/assets/verve.svg";
import { LuArrowDownToLine } from "react-icons/lu";
import { MdOutlineTune } from "react-icons/md";
import { IoLockClosedOutline } from "react-icons/io5";
import { PaystackButton } from "react-paystack";
import { useAppSelector } from "@/lib/hooks";
import { accesstokenSelector, currentSlug } from "@/lib/user/userSlice";
import {
  useLazyGetBillingsQuery,
  useLazyDisableBillingsQuery,
  useCheckBillingsQuery,
  useLazyEnableSubscriptionQuery,
  useLazyUpdateCardQuery,
} from "@/lib/api/settingsApi";
import Loading from "@/app/loading";
import { formattedDate } from "@/utils/formatting";
import { SUBSCRIPTION_PRICE } from "@/utils/constants";
import HeadText from "../../../components/HeadText";
import {
  PAYSTACK_PLAN_CODE,
  PAYSTACK_PUBLIC_KEY,
} from "../../../utils/constants";
import { addCommasToMonetary } from "../../../utils/formatting";

function subText(cancelled_at, next_payment_date) {
  if (!cancelled_at) {
    return `Your subscription ends ${formattedDate(next_payment_date)}`;
  }
  if (cancelled_at) {
    return `Your subscription will not renew when it ends on ${formattedDate(
      next_payment_date
    )}`;
  }
}

export default function Page() {
  const access = useAppSelector(accesstokenSelector);
  const slug = useAppSelector(currentSlug);
  const { data, isFetching, refetch } = useCheckBillingsQuery({ access, slug });
  const [isActive, setisActive] = useState(false);
  const [getBillings, { data: billingData }] = useLazyGetBillingsQuery();
  const [disableBillings] = useLazyDisableBillingsQuery();
  const [enableBillings] = useLazyEnableSubscriptionQuery();
  const [updateCard] = useLazyUpdateCardQuery();
  const [isChecked, setIsChecked] = useState(false);
  const [history, setHistory] = useState([]);
  // const [email, setEmail] = useState("");
  const [paystackProps, setPaystackProps] = useState(null);

  const [searchInput, setSearchInput] = useState(false);

  function handleSearch() {
    setSearchInput(!searchInput);
  }

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    getBillings({ access, slug });
    setisActive(billingData?.data?.is_active || false);
    if (billingData && billingData.data?.pay_email) {
      setHistory(billingData.data.history);
      setPaystackProps({
        email: billingData.data.pay_email,
        publicKey: PAYSTACK_PUBLIC_KEY,
        plan: PAYSTACK_PLAN_CODE,
        className: "bg-[#7E57C2] py-4 font-medium w-full text-white rounded-lg",
        text: "Pay Now",
        metadata: { reason: "jello_subscription", site_slug: slug },
        // on success recheck billings
        onSuccess: () => {
          setisActive(true);
          refetch();
        },
        onClose: () => alert("Cancelled"),
      });
    }
  }, [getBillings, access, slug, billingData, data, refetch]);

  if (isFetching) {
    return <Loading />;
  }

  async function disable() {
    try {
      await disableBillings({ access, slug });
      refetch();
    } catch (error) {
      console.error(error);
    }
  }
  async function enableSubscription() {
    try {
      await enableBillings({ access, slug });
      refetch();
    } catch (error) {
      console.error(error);
    }
  }
  async function updateCardHandler() {
    try {
      const res = await updateCard({ access, slug });
      if (res.data?.update_link) {
        window.open(res.data.update_link, "_blank");
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="w-full py-8 px-4 md:px-8">
      <div className="flex flex-col items-start justify-center gap-1 w-full">
        <HeadText sub="" color="purple">
          Billings
        </HeadText>
        <div className="w-full flex flex-col items-start justify-center gap-8">
          <hr className="w-full h-[1px] bg-[#d5d7db]" />
          <div className=" w-full flex lg:flex-row flex-col justify-between items-center gap-4">
            <div className=" flex flex-col w-full rounded-md border border-[#d5d7db] p-3 pb-8 gap-8">
              <div className=" flex flex-start justify-center w-full border-b border-b-[#d5d7db] pb-1">
                <header className="flex gap-10 justify-center items-center">
                  <h4>Choose Card</h4>
                </header>
              </div>
              <ul className=" w-full flex flex-col gap-8">
                <li className="flex items-center justify-center gap-4 w-full">
                  <input
                    type="radio"
                    checked={isActive}
                    name="payment-method"
                    className=" accent-[#7E57C2] h-4 w-4"
                  />
                  <div className="w-full flex flex-col items-start gap-2">
                    <div className="flex items-center justify-center gap-3 w-full">
                      <div>
                        <p className="flex items-center justify-center gap-3">
                          {billingData?.data?.card
                            ? `Card ${billingData?.data.card?.bin}****${billingData?.data.card?.last4}`
                            : "Pay with card"}{" "}
                          <IoLockClosedOutline />
                        </p>
                        <div className="flex gap-2 items-center justify-center">
                          <div className=" h-8 w-12 flex items-center justify-center rounded-lg border border-[#d5d7db]">
                            <img src={master} alt={""} width={35} height={35} />
                          </div>
                          {/* <div className=" h-8 w-12 flex items-center justify-center rounded-lg border border-[#d5d7db]">
                          <img src={maestro} alt={""} width={35} height={35} />
                        </div>
                        <div className=" h-8 w-12 flex items-center justify-center rounded-lg border border-[#d5d7db]">
                          <FaApplePay size={35} />
                        </div>
                        <div className=" h-8 w-12 flex items-center justify-center rounded-lg border border-[#d5d7db]">
                          <img src={stripe} alt={""} width={35} height={35} />
                        </div> */}
                          <div className=" h-8 w-12 flex items-center justify-center rounded-lg border border-[#d5d7db]">
                            <img src={visa} alt={""} width={35} height={35} />
                          </div>
                          <div className=" h-8 w-12 flex items-center justify-center rounded-lg border border-[#d5d7db]">
                            <img src={verve} alt={""} width={35} height={35} />
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto pr-4">
                        {billingData?.data?.card &&
                          (isActive && !billingData?.data?.cancelled_at ? (
                            <button
                              onClick={updateCardHandler}
                              className="bg-[#7E57C2] p-2 font-medium w-full text-white rounded-lg"
                            >
                              Update card
                            </button>
                          ) : (
                            <PaystackButton
                              {...paystackProps}
                              text="Update card"
                              className="bg-[#7E57C2] p-2 font-medium w-full text-white rounded-lg"
                            />
                          ))}
                      </div>
                    </div>
                  </div>
                </li>
                {/* <hr className="w-full h-[1px] bg-[#d5d7db]" />
                <li className="flex items-center justify-center gap-4 w-full">
                  <input
                    type="radio"
                    name="payment-method"
                    className="accent-[#7E57C2] h-4 w-4"
                  />
                  <div className="w-full flex flex-col items-start gap-2">
                    <p className="flex items-center justify-center gap-3">
                      Pay using Jellosite Balance <IoLockClosedOutline />
                    </p>
                    <span>Balance - $25,040</span>
                  </div>
                </li> */}
              </ul>
            </div>
            <div className=" flex flex-col w-full rounded-md border border-[#d5d7db] p-3 pb-8">
              <div className="flex flex-col gap-2 justify-between h-full">
                <div className="flex flex-col gap-4 justify-center items-start">
                  <h4 className="font-medium">Subscription details</h4>
                  <div className="flex flex-col justify-center items-start">
                    <h3 className="text-[#7E57C2] font-medium pb-3">
                      PREMIUM - ₦{SUBSCRIPTION_PRICE}
                    </h3>
                    <p className="mt-[-10px]">monthly</p>
                  </div>
                </div>
                <div className="flex flex-col gap-3 justify-center items-start ">
                  <span>
                    {isActive
                      ? subText(
                          billingData?.data?.cancelled_at,
                          billingData?.data?.next_payment_date
                        )
                      : `Pay ₦${SUBSCRIPTION_PRICE} for premium`}
                  </span>
                  {isActive && !billingData?.data?.cancelled_at && (
                    <button
                      onClick={disable}
                      className="border hover:text-white duration-200 border-red-500 rounded-lg hover:bg-red-500 text-red-500 w-full h-12"
                    >
                      Cancel Subscription
                    </button>
                  )}
                  {!isActive && !billingData?.data?.card && (
                    <PaystackButton {...paystackProps} />
                  )}
                  {billingData?.data?.card &&
                    billingData?.data?.cancelled_at && (
                      <button
                        onClick={enableSubscription}
                        className="border border-[#7E57C2] text-[#7E57C2] py-4 font-medium w-full rounded-lg"
                      >
                        Reactivate
                      </button>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full rounded-[8px] bg-white mt-5 p-4 lg:p-0 flex flex-col gap-2 border-collapse box-border overflow-x-scroll lg:overflow-clip">
          <div className=" flex justify-between w-[800px] lg:w-full items-center pl-5 pr-5 border-b border-b-[#E5E9EB] gap-16">
            <div className="flex gap-6  h-12 items-center border-b justify-center border-b-[#E5E9EB">
              <h4 className=" h-full flex items-center w-fit justify-center pl-2 pr-2">
                Billing and Payment History
              </h4>
            </div>
            <div className=" flex items-center gap-6">
              {searchInput ? (
                <div className="flex items-center gap-2">
                  <input
                    className=" border rounded-md border-gray-300 p-2 w-[250px] md:w-[300px]"
                    type="text"
                    placeholder="Search customers using ID and amount"
                  />
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    onClick={handleSearch}
                    className="cursor-pointer"
                  />
                </div>
              ) : (
                <button
                  className="h-8 w-8 rounded-md border border-gray-300 text-gray-600 flex items-center justify-center"
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              )}
              <hr className=" rotate-90 bg-[#717070] w-6 h-full" />
              {/* <button className=" ">
                <MdOutlineTune className=" rotate-90" />
              </button> */}
              <button className=" ">
                <LuArrowDownToLine />
              </button>
              {/* <button className=" ">
                <FontAwesomeIcon icon={faRefresh} />
              </button>
              <hr className=" rotate-90 bg-[gray]  w-6 h-full" />
              <div className="flex items-center gap-1 pl-2 pr-2 border bg-[#e7edf0] text-[#737f87]  border-#B1B1B1 rounded-md h-8">
                <FontAwesomeIcon icon={faCalendar} />
                <span className="ml-2 w-[80px]">Date range</span>
              </div> */}
            </div>
          </div>

          <div className="w-full">
            <table className=" w-[800px] lg:w-full ">
              <thead className=" border-b px-5">
                <tr className="">
                  <th className="text-center p-4 font-normal text-[#6E7C87]  ">
                    ID
                  </th>
                  <th className="text-center font-normal text-[#6E7C87] ">
                    AMOUNT
                  </th>
                  <th className="text-center font-normal text-[#6E7C87]">
                    CARD
                  </th>
                  <th className="text-center font-normal text-[#6E7C87]">
                    DATE
                  </th>
                </tr>
              </thead>
              <tbody>
                {history &&
                  history.map((payment) => (
                    <tr
                      key={payment.id}
                      className={`border-b  ${
                        isChecked ? "bg-[#F9F5FFCC]" : ""
                      }`}
                    >
                      <td className="text-center p-3">{payment.id}</td>

                      <td className="text-center ">
                        <p>₦{addCommasToMonetary(payment.amount / 100)}</p>
                      </td>
                      <td className="text-center">
                        {payment.authorization.bin}****
                        {payment.authorization.last4}
                      </td>
                      <td className="text-center">
                        {formattedDate(payment.createdAt)}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
