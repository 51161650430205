import React, { useEffect } from "react";
import Chart from "chart.js/auto";
import PropTypes from "prop-types";

const UnMemoizedChartComp = ({
  data,
  xAxes,
  yAxes,
  max,
  min = 0,
  borderColor = ["#3CCAD7"],
  backgroundColor = ["#163A4F", "#4F628E"],
  labels = [],
  yAxesLabel,
  yAxesDisplay = true,
  xAxesLabel,
  xAxesDisplay = true,
  className = "",
  datasetLabels,
  heading = false,
  barPercentage = 0.6,
  categoryPercentage = 0.8,
}) => {
  const chartRef = React.createRef();

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const datasets = data.map((dataSet, index) => ({
      label: datasetLabels[index] || `Dataset ${index + 1}`,
      data: dataSet.map((element) => element[yAxes]),
      backgroundColor: backgroundColor[index] || "#163A4F",
      borderColor: borderColor[index] || "#3CCAD7",
      barPercentage,
      categoryPercentage,
      tension: 0.5,
    }));

    // Check if chartRef.current has a chart instance
    if (chartRef.current.chart) {
      // Destroy the existing chart instance
      chartRef.current.chart.destroy();
    }

    // Create a new chart instance
    chartRef.current.chart = new Chart(ctx, {
      type: "line",
      data: {
        labels:
          labels.length > 0 ? labels : data[0].map((dataSet) => dataSet[xAxes]),
        datasets: datasets,
      },
      options: {
        plugins: {
          legend: {
            display: heading,
            position: "top",
            labels: {
              usePointStyle: true,
              font: {
                size: 14,
                weight: "medium",
                family: "poppins",
              },
              boxWidth: 10,
              borderRadius: "100",
            },
          },
        },
        scales: {
          y: {
            display: yAxesDisplay,
            title: {
              text: yAxesLabel || "",
              display: yAxesLabel ? true : false,
              color: "#3CCAD7",
              padding: 0,
            },
            max,
            min,
            ticks: {
              color: "#6B6B6B",
            },
          },
          x: {
            display: xAxesDisplay,
            title: {
              text: xAxesLabel || "mee",
              display: xAxesLabel ? true : false,
              color: "#3CCAD7",
              padding: 0,
            },
            max,
            min,
            ticks: {
              color: "#6B6B6B",
            },
          },
        },
      },
    });
  }, [
    data,
    xAxes,
    yAxes,
    max,
    min,
    borderColor,
    backgroundColor,
    labels,
    yAxesLabel,
    yAxesDisplay,
    xAxesLabel,
    chartRef,
    datasetLabels,
    xAxesDisplay,
    className,
    heading,
    barPercentage,
    categoryPercentage,
  ]);

  return (
    <div className={className}>
      <canvas ref={chartRef} />
    </div>
  );
};

UnMemoizedChartComp.propTypes = {
  data: PropTypes.array.isRequired,
  xAxes: PropTypes.string.isRequired,
  yAxes: PropTypes.string.isRequired,
  max: PropTypes.number.isRequired,
  min: PropTypes.number,
  borderColor: PropTypes.array,
  backgroundColor: PropTypes.array,
  labels: PropTypes.array,
  yAxesLabel: PropTypes.string,
  yAxesDisplay: PropTypes.bool,
  xAxesLabel: PropTypes.string,
  xAxesDisplay: PropTypes.bool,
  className: PropTypes.string,
  datasetLabels: PropTypes.array,
  heading: PropTypes.bool,
  barPercentage: PropTypes.number,
  categoryPercentage: PropTypes.number,
};

const ChartComp = React.memo(UnMemoizedChartComp);
export default ChartComp;
