import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import templateService from "./templateService";

const initialState = {
  data: [],
  currentTheme: [],
  isError: false,
  isLoading: false,
  isSuccess: false,
  message: "",
};

export const getAllTemplate = createAsyncThunk(
  "template/all",
  async (_, thunkApi) => {
    try {
      return await templateService.getAllTemplates();
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const getCurrentTemplate = createAsyncThunk(
  "template/current",
  async (_, thunkApi) => {
    try {
      return await templateService.getCurrentTheme();
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);
export const updateCurrentTemplate = createAsyncThunk(
  "template/update-current",
  async (template_slug, thunkApi) => {
    try {
      return await templateService.updateCurrentTheme(template_slug);
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const editTemplate = createAsyncThunk(
  "template/edit",
  async (template_slug, thunkApi) => {
    try {
      return await templateService.editTheme(template_slug);
    } catch (error) {
      const defaultMessage = "An error occurred. Please try again.";
      let message = defaultMessage;

      if (error.response && error.response.data && error.response.data.msg) {
        message = error.response.data.msg;
      }
      return thunkApi.rejectWithValue(message);
    }
  }
);

export const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.data = action.payload;
      })
      .addCase(getAllTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getCurrentTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCurrentTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentTheme = action.payload;
      })
      .addCase(getCurrentTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateCurrentTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCurrentTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentTheme = action.payload;
      })
      .addCase(updateCurrentTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentTheme = action.payload.theme;
      })
      .addCase(editTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = templateSlice.actions;
export default templateSlice.reducer;
