import PropTypes from "prop-types";
export function ConfirmModal({ close, message, onAccept }) {
  return (
    <div className="p-10 rounded-md bg-white flex flex-col items-center justify-center gap-6">
      <p className=" font-semibold text-center">{message}</p>
      <div className="w-full flex items-center justify-center gap-6">
        <button
          onClick={onAccept}
          className="bg-white border border-red-500 hover:bg-red-500 hover:text-white px-2 py-1 rounded-md"
        >
          Yes
        </button>
        <button
          className=" bg-[#E6E6E6]/60 px-2 py-1 rounded-md border hover:border-gray-400"
          onClick={close}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

ConfirmModal.propTypes = {
  close: PropTypes.func.isRequired,
};
