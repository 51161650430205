import { generalApiSlice } from "./generalapi";

export const customerApiSlice = generalApiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCustomerList: build.query({
      providesTags: ["Customers"],
      query: ({ access, slug }) => ({
        url: `ecommerce/${slug}/customers/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
    createCustomer: build.mutation({
      invalidatesTags: ["Customers"],
      query: ({ access, slug, body }) => ({
        url: `ecommerce/${slug}/customers/`,
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${access}`,
        },
      }),
    }),
  }),
});

export const { useGetCustomerListQuery, useCreateCustomerMutation } = customerApiSlice;
