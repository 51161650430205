import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import { useState } from "react";

export const ProductImage = ({ image }) => {
  if (!image || image.length === 0) {
    return null;
  }
  return (
    <img
      src={image?.src || image?.image}
      alt={image?.alt}
      width={400}
      height={450}
      className=" select-none w-full h-[200px] rounded-lg object-contain"
    />
  );
};

export const ImageCarosel = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const showArrows = images.length > 1;

  const canGoPrevious = currentImageIndex !== 0;

  const canGoNext = currentImageIndex !== images.length;
  return (
    <div className="relative w-full flex items-center justify-center">
      <div className="flex items-center justify-between w-full absolute ">
        {showArrows && (
          <div
            className={`w-10 h-10 rounded-full flex items-center justify-center bg-[#7E57C2] cursor-pointer absolute left-0 ${
              canGoPrevious ? "" : "opacity-50 pointer-events-none"
            }`}
            onClick={goToPreviousImage}
          >
            <FontAwesomeIcon icon={faAngleLeft} color="white" />
          </div>
        )}
        {showArrows && (
          <div
            className={`w-10 h-10 rounded-full flex items-center justify-center bg-[#7E57C2] cursor-pointer absolute right-0 ${
              canGoNext ? "" : "opacity-50 pointer-events-none"
            }`}
            onClick={goToNextImage}
          >
            <FontAwesomeIcon icon={faAngleRight} color="white" />
          </div>
        )}
      </div>
      <ProductImage image={images[currentImageIndex]} />
    </div>
  );
};

ProductImage.propTypes = {
  image: PropTypes.object,
};

ImageCarosel.propTypes = {
  images: PropTypes.array.isRequired,
};
