import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useEditOrderMutation,
  useLazyGetSingleOrderQuery,
} from "../../../lib/api/ordersApi";
import { useAppSelector } from "../../../lib/hooks";
import { accesstokenSelector, currentSlug } from "../../../lib/user/userSlice";
import Loading from "../../loading";
import { formattedDate } from "../../../utils/formatting";
import { toast } from "react-toastify";
import OverLoader from "../../../components/OverLoader";
import { IoArrowBack } from "react-icons/io5";
import noImage from "../../../assets/no-image.jpg";

export default function OrderInfo({ id, setIsOpen, refetch }) {
  const slug = useAppSelector(currentSlug);
  const access = useAppSelector(accesstokenSelector);

  const [status, setStatus] = useState("pending");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [getOrder, { data, isLoading, error:errorGettingOrder }] = useLazyGetSingleOrderQuery();
  const [editOrder, { isLoading: isEditing }] = useEditOrderMutation();

  useEffect(() => {
    if (id) {
      getOrder({ access, slug, id });
    }
  }, [access, getOrder, id, slug]);

  useEffect(() => {
    if (data) {
      setStatus(data.delivery_status);
    }
  }, [data]);

  const handleStatusChange = (newStatus) => {
    setStatus(newStatus);
    setDropdownOpen(false);
  };

  async function handleSave() {
    try {
      console.log(editOrder);
      const response = await editOrder({
        access,
        slug,
        id: data.id,
        body: { delivery_status: status },
      });

      if (response.data) {
        toast.success("Order updated successfully");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsOpen(false);
      refetch();
    }
  }

  return (
    <div  className="p-4 w-full rounded">
      {isLoading ? (
        <Loading />
      ) : errorGettingOrder ? (
        <div className="flex w-full justify-center font-medium text-xl">
          <p className="font-medium text-lg">Error fetching Order.</p>
        </div>
      ) : (
        data && (
          <div className="flex flex-col gap-2">
            <OverLoader isLoading={isEditing} />
            <div className="flex items-center justify-between">
              <h2 className="text-xl flex items-center font-bold mb-4">
                <span
                  onClick={() => setIsOpen(false)}
                  className="cursor-pointer"
                >
                  <IoArrowBack size={25} />
                </span>{" "}
                Order number #{data?.order_id}
              </h2>

              <h2 className=" text-jello-purple font-medium mb-4">
                Total: &#8358;{data?.total_price?.toLocaleString()}
              </h2>
            </div>

            <div className="mb-4 flex w-full justify-between items-center">
              <label className="font-semibold mr-2">Order Status:</label>
              <div className="relative inline-block">
                <button
                  className="px-4 py-1 w-48 text-left border border-black rounded"
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                >
                  {status
                    .toLocaleLowerCase()
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </button>
                {dropdownOpen && (
                  <div className="absolute mt-2 w-48 bg-white border rounded shadow-lg">
                    <button
                      className={`block w-full text-left px-4 py-1 ${
                        status === "pending"
                          ? "bg-blue-500 text-white"
                          : "hover:bg-gray-200"
                      }`}
                      onClick={() => handleStatusChange("pending")}
                    >
                      Pending
                    </button>
                    <button
                      className={`block w-full text-left px-4 py-1 ${
                        status === "dispatched"
                          ? "bg-blue-500 text-white"
                          : "hover:bg-gray-200"
                      }`}
                      onClick={() => handleStatusChange("dispatched")}
                    >
                      Dispatched
                    </button>
                    <button
                      className={`block w-full text-left px-4 py-1 ${
                        status === "delivered"
                          ? "bg-blue-500 text-white"
                          : "hover:bg-gray-200"
                      }`}
                      onClick={() => handleStatusChange("delivered")}
                    >
                      Delivered
                    </button>
                    <button
                      className={`block w-full text-left px-4 py-1 ${
                        status === "cancelled"
                          ? "bg-blue-500 text-white"
                          : "hover:bg-gray-200"
                      }`}
                      onClick={() => handleStatusChange("cancelled")}
                    >
                      Cancelled
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col border-t gap-3">
              {data?.items.map((item) => (
                <div
                  key={item.id}
                  className="flex py-3 justify-between border-b items-center"
                >
                  <img
                    className="w-12"
                    src={item.product.images[0]?.image ||  noImage}
                    alt=""
                  />
                  <div className="overflow-clip">
                    <p>{item.product.name}</p>
                    {item.variant && (
                      <p className="text-sm truncate">{item.variant.name}</p>
                    )}
                  </div>

                  <p>x{item.quantity}</p>
                  <p>
                    &#8358;
                    {item.variant
                      ? item.variant.price * item.quantity
                      : (item.product.price * item.quantity).toLocaleString()}
                  </p>
                </div>
              ))}
            </div>
            <article className="">
              <h2 className="text-lg font:medium">Shipping Details</h2>
              <div className="flex flex-col">
                <span>Name: {data?.shipping_details.customer_name}</span>
                <span>Address: {data?.shipping_details.address}</span>
                {/* <span>City: {data?.shipping_details.city}</span>
                <span>Country: {data?.shipping_details.country}</span>
                <span>Zip Code: {data?.shipping_details.zip_code}</span> */}
              </div>
            </article>
            <article>
              <h2 className="text-lg font:medium">Customer Info</h2>
              <div className="flex flex-col">
                <span>Email: {data?.customer.email}</span>
                <span>Names: {data?.customer.first_name}</span>
                <span>Phone: {data?.customer.phone}</span>
              </div>
              <span>Created: {formattedDate(data?.created_at)}</span>
            </article>
            <button
              onClick={handleSave}
              disabled={data?.delivery_status == status}
              className="bg-jello-purple disabled:bg-slate-500 flex self-end justify-self-end text-white py-1 rounded px-4"
            >
              Save
            </button>
          </div>
        )
      )}
    </div>
  );
}

OrderInfo.propTypes = {
  id: PropTypes.string.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  refetch: PropTypes.func.isRequired,
};
