import { useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { GrSave } from "react-icons/gr";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function Policy({
  title,
  content,
  onSave,
  // onEdit,
  onChange,
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    onSave();
    handleEdit();
  };

  console.log(content);

  return (
    <div className="policies  flex flex-col gap-2 items-start">
      <span className="font-medium">{title}</span>
      <div className="flex flex-col gap-2 w-full">
        {isEditing ? (
          <>
            <ReactQuill
              theme="snow"
              className="md:w-[80%] w-full border border-[#D0D5DD] rounded-lg p-2"
              onChange={onChange}
              placeholder={`Enter ${title.toLowerCase()} description.....`}
              value={content}
            />
          </>
        ) : (
          <div
            dangerouslySetInnerHTML={{
              __html:
                content.length > 100 ? content.slice(0, 100) + "..." : content,
            }}
          />
        )}

        {isEditing ? (
          <button
            className="bg-[#7e57c2] text-white rounded-md w-fit p-1  flex justify-center items-center gap-2"
            onClick={handleSave}
          >
            <GrSave /> Save
          </button>
        ) : (
          <button
            onClick={handleEdit}
            className="text-[#7E57C2] md:w-[80%] w-full flex justify-end items-center gap-2"
          >
            <FontAwesomeIcon icon={faPencil} /> Edit
          </button>
        )}
      </div>
    </div>
  );
}

Policy.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  policyKey: PropTypes.string,
  onSave: PropTypes.func,
  onEdit: PropTypes.func,
  onChange: PropTypes.func,
};
