import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { addCommasToMonetary } from "../../../../utils/formatting";
import ChartComp from "../../../../components/ChartComp";

const Graph = ({ graph }) => {
  const [maxGraphValue, setMaxGraphValue] = useState(1);
  const [timeRevenue, setTimeRevenue] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [graphKey, setGraphKey] = useState("week");

  useEffect(() => {
    if (graph) {
      if (graphKey === "day") {
        setGraphData([...graph.today].reverse());
      } else if (graphKey === "week") {
        setGraphData([...graph.last_week].reverse());
      } else {
        setGraphData([...graph.last_month].reverse());
      }
    }
  }, [graph, graphKey]);
  useEffect(() => {
    let max = 5000;
    let total = 0;
    graphData.forEach(({ total_sales }) => {
      if (total_sales > max) {
        max = total_sales;
      }
      total += total_sales;
    });

    // Determine the order of magnitude
    const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(max)));
    // Ceil to the nearest significant figure
    const roundedMax = Math.ceil(max / orderOfMagnitude) * orderOfMagnitude;
    setTimeRevenue(total);
    setMaxGraphValue(roundedMax);
  }, [graphData]);

  // Parameters for the ChartComp component
  const chartParams = {
    data: [
      graphData.map(({ key, total_sales }) => ({ x: key, y: total_sales })),
    ],
    xAxes: "month",
    yAxes: "y",
    max: maxGraphValue, // You can adjust the max value based on your data
    labels: graphData.map(({ key }) => key),
    yAxesLabel: "",
    xAxesLabel: "",
    heading: false,
    backgroundColor: ["#165BAA", "#FA1523"],
    borderColor: ["#F765A3"],
    datasetLabels: [],
    // barPercentage: 0.8, // Adjust the width of individual bars (0.8 means 80% of the available space)
    // categoryPercentage: 0.7, // Adjust the gap between bars (0.7 means 70% of the available space)
  };

  return (
    <div className="bg-white relative  p-4 flex gap-3 flex-col h-full border border-[#]  rounded-[8px]">
      <h3 className="text-lg font-medium">Sales Chart</h3>
      <div className="flex items-center gap-[20%] text-[#2EE410]">
        <p className=" text-2xl font-bold text-[#7E57C2] first-letter:decoration-double first-letter:line-through">
          N{graph ? addCommasToMonetary(timeRevenue?.toFixed(2)) : 0}
        </p>
        <div className="p-1 border absolute top-6 right-5 border-#B1B1B1 rounded-md bg-white">
          <select
            onChange={(e) => setGraphKey(e.target.value)}
            className="outline-none text-black"
          >
            <option value="day">Today</option>
            <option selected value="week">
              This week
            </option>
            <option value="month">Last Month</option>
          </select>
        </div>
        {/* <span className="flex items-center gap-1">
            <FontAwesomeIcon icon={faArrowUp} color="#2EE410" /> 5% than last
            month
          </span> */}
      </div>
      <ChartComp {...chartParams} />
    </div>
  );
};

Graph.propTypes = {
  graph: PropTypes.object,
};

export default Graph;
