import React from "react";
import { LoadScript } from "@react-google-maps/api";

const libraries = ["places"];

const GoogleMapsLoader = ({ children }) => {
  return (
    <LoadScript
      googleMapsApiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}
      libraries={libraries}
      fields={["formatted_address"]}
    >
      {children}
    </LoadScript>
  );
};

export default GoogleMapsLoader;
